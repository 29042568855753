<template>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div>
            <h4 class="card-title">Price Updates</h4>
            <h6 class="card-subtitle text-muted">Latest updates that you have participated in</h6>
          </div>
          <div>
            <!-- Button trigger modal -->
            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#updatePrices">
              <i class="fal fa-plus-circle"></i>
              Update Prices
            </button>
            <!-- Modal -->
            <div class="modal fade" id="updatePrices" tabindex="-1" role="dialog"
                 aria-labelledby="updatePricesId"
                 aria-hidden="true">
              <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Update Prices</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-12">
                          <!--Label: Product Category, Attributes:productcategory -->
                          <div class="form-group">
                            <label for="productcategory">Product Category</label>
                            <div>
                              <select type="text" class="form-control"
                                      id="productcategory" v-model="productcategory"
                                      style="width: 100%"
                                      @change="loadProducts"
                                      aria-describedby="productcategory-help"
                                      :class="[errors.productcategory ? 'is-invalid': '',!errors.productcategory && Object.keys(errors).length > 1 ? 'is-valid': '']"
                              >
                                <option value="" disabled selected>Choose a Category
                                </option>
                                <option :value="category.id"
                                        v-for="category in categories">{{
                                    category.name
                                  }}
                                </option>
                              </select>
                            </div>
                            <div class="invalid-feedback" v-if="errors.productcategory">
                              {{ errors.productcategory.toString() }}
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <!--Label: Product Type, Attributes:productype -->
                          <div class="form-group">
                            <label for="productype">Product Type</label>
                            <select type="text" class="form-control" id="productype"
                                    v-model="productype"
                                    @change="loadProducts_"
                                    aria-describedby="productype-help"
                                    :class="[errors.productype ? 'is-invalid': '',!errors.productype && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                    required>
                              <option value="" disabled>Select Product Type</option>
                              <option :value="type.id" v-for="type in productTypes">
                                {{ type.name }}
                              </option>
                            </select>
                            <div class="invalid-feedback" v-if="errors.productype">
                              {{ errors.productype.toString() }}
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <!--Label: Product, Attributes:product -->
                          <div class="form-group">
                            <label for="product">Product</label>
                            <select type="text" class="form-control" id="product"
                                    v-model="product"
                                    @change="loadGrades"
                                    aria-describedby="product-help"
                                    :class="[errors.product ? 'is-invalid': '',!errors.product && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                    required>
                              <option value="" disabled>Select Product</option>
                              <option :value="product.id" v-for="product in products">
                                {{ product.name }}
                              </option>

                            </select>
                            <div class="invalid-feedback" v-if="errors.product">
                              {{ errors.product.toString() }}
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <!--Label: Grade, Attributes:grade -->
                          <div class="form-group">
                            <label for="grade">Grade</label>
                            <select type="text" class="form-control" id="grade"
                                    v-model="grade"
                                    aria-describedby="grade-help"
                                    :class="[errors.grade ? 'is-invalid': '',!errors.grade && Object.keys(errors).length > 1 ? 'is-valid': '']"
                            >
                              <option value="" disabled>Select Grade</option>
                              <option :value="grade.id" v-for="grade in grades">
                                {{ grade.name }}
                              </option>
                            </select>
                            <div class="invalid-feedback" v-if="errors.grade">
                              {{ errors.grade.toString() }}
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="row" v-for="(price,index) in prices">
                            <div class="col-md-4">
                              <!--Label: Unit, Attributes:measurementunit -->
                              <div class="form-group">
                                <label for="measurementunit">Unit</label>
                                <select type="text" class="form-control"
                                        id="measurementunit"
                                        v-model="prices[index].unit"
                                        aria-describedby="measurementunit-help"
                                        :class="[errors.measurementunit ? 'is-invalid': '',!errors.measurementunit && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                >
                                  <option :value="unit.id" v-for="unit in units">
                                    {{ unit.name }}
                                  </option>
                                </select>
                                <div class="invalid-feedback"
                                     v-if="errors.measurementunit">
                                  {{ errors.measurementunit.toString() }}
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <!--Label: Price, Attributes:price -->
                              <div class="form-group">
                                <label for="price">Price</label>
                                <input type="number" class="form-control" id="price"
                                       v-model.number="prices[index].price"
                                       aria-describedby="price-help"
                                       :class="[errors.price ? 'is-invalid': '',!errors.price && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                       placeholder="Price" required>
                                <div class="invalid-feedback" v-if="errors.price">
                                  {{ errors.price.toString() }}
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mt-3">
                                <button class="btn btn-primary btn-sm mt-5"
                                        @click="addNewUnits">
                                  <i class="fa fa-plus"></i>
                                </button>
                                <button class="btn btn-danger btn-sm mt-5"
                                        @click="removeUnit(index)">
                                  <i class="fa fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close
                    </button>
                    <button type="button" class="btn btn-primary" @click="addPrices">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="row">-->
<!--          <div class="col-md-4">-->
<!--            <div class="card">-->
<!--              <div class="card-header pb-0 border-bottom-0"><h3 class="card-title">Avg Price</h3>-->
<!--                <div class="card-options"><a class="btn btn-sm btn-primary" href="javascript:void(0);"><i-->
<!--                    class="fa fa-bar-chart mb-0"></i></a></div>-->
<!--              </div>-->
<!--              <div class="card-body pt-0"><h3 class="d-inline-block mb-2">-->
<!--                {{currency}} {{ avg_price }}-->
<!--              </h3>-->
<!--                <div class="progress h-2 mt-2 mb-2">-->
<!--                  <div class="progress-bar bg-primary w-50" role="progressbar"></div>-->
<!--                </div>-->
<!--                <div class="float-start">-->
<!--                  <div class="mt-2"><i class="fa fa-caret-up text-success"></i> <span>12% increase</span></div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="row row-sm">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table border text-nowrap text-md-nowrap table-hover mg-b-0">
                <thead>
                <tr>
                  <th colspan="2">
                    <select name="market_search" class="form-control"
                            v-model="filter.market"
                    >
                      <option v-for="(marketPrice,index) in markets"
                              :value="marketPrice.id">
                        {{ marketPrice.name }} -
                        <span>
                                                  <small>  {{ marketPrice.designation_id }}</small>
                                                </span>

                      </option>
                    </select>
                  </th>
                  <th colspan="2">
                    <select name="market_search" class="form-control"
                            v-model="filter.product"
                    >
                      <option v-for="(product,index) in uniqueproducts"
                              :value="product.id">
                        {{ product.name }}

                      </option>
                    </select>
                  </th>
                  <th colspan="1" >
                    <select name="market_search" class="form-control"
                            v-model="filter.grade"
                    >
                      <option v-for="(grade,index) in uniquegrades"
                              :value="grade.id">
                        {{ grade.name }}
                      </option>
                    </select>
                  </th>
                  <th>
                    <input v-model="filter.date_from" name="date_from" type="date"
                           class="form-control">
                  </th>
                  <th>
                    <input v-model="filter.date_to" name="date_to" type="date"
                           class="form-control" :max="new Date()">
                  </th>
                  <th>
                    <button name="filter" type="submit"
                            class="btn btn-primary-gradient filter_search"
                            @click="filterSearch">
                      <i class="fal fa-filter fa-fade"></i>
                      Filter
                    </button>
                  </th>
                </tr>
                <tr>
                  <th>#</th>
                  <th>Market</th>
                  <th>Product Name</th>
                  <th>Product Grade</th>
                  <th>Product Measurement</th>
                  <th>Amount</th>
                  <th>Avg. Price</th>
                  <th>Min. Price</th>
                  <th>Max. Price</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="marketpricesAll.length > 0">
                  <tr v-for="(marketPrice,index) in marketpricesAll">
                    <td>{{ index + 1 }}</td>
                    <td>{{ marketPrice.market.name }}</td>
                    <td>{{ marketPrice.product.name }}</td>
                    <td>{{ marketPrice.grade.name }}</td>
                    <td>{{ marketPrice.measurement.name }}</td>
                    <td>{{ currency }} {{ marketPrice.price }}</td>
                    <td>{{ currency }} {{ marketPrice.avg_price }}</td>
                    <td>{{ currency }} {{ marketPrice.min_price }}</td>
                    <td>{{ currency }} {{ marketPrice.max_price }}</td>
                  </tr>

                </template>
                <template v-else>
                  <tr>
                    <td colspan="6">
                      <div class="d-flex flex-column align-items-center justify-content-center border"
                           style="border-radius: 0.5rem !important;
                   border-color: rgb(209 213 219 / 1) !important;
                   border-width: 2px !important;
                   border-style: dashed !important;">
                        <div class="row">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                               stroke-width="1.5"
                               stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"/>
                          </svg>
                        </div>
                        <div class="row">
                          <p class="text-muted">You currently have no updated prices to view.</p>
                          <p class="text-muted">Add them to be able to view a history of price updates</p>
                        </div>
                        <div class="row">
                          <button type="button" class="btn btn-outline-dark btn-sm" data-toggle="modal"
                                  data-target="#updatePrices">
                            <i class="fal fa-plus-square-o"></i>
                            Update Prices
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndexPriceUpdate",
  props: {
    marketprices: {
      type: Array|Object,
      default: {}
    },
    uniqueproducts: {
      type: Array|Object,
      default: {}
    },
    uniquegrades: {
      type: Array|Object,
      default: {}
    },
    markets: {
      type: Array|Object,
      default: {}
    },
    currency: {
      type: Array|Object,
      default: ''
    },
    links: {
      type: Array|Object,
      default: ''
    },
  },
  created() {
    this.productCategories();
    // this.filterSearch();
  },
  mounted() {
    let vm = this;
    $(document).ready(function () {
      $('#productcategory').select2({
        dropdownParent: $("#updatePrices")
      })
          .trigger("change")
          .on("change", function () {
            vm.productcategory = this.value
            vm.loadProducts();
          });
    });
  },
  data() {
    return {
      marketpricesAll: this.marketprices,
      errors: {},
      category: '',
      categories: '',
      productcategory: '',
      productype: '',
      productTypes: [],
      product: '',
      products: '',
      grade: '',
      grades: '',
      units: '',
      pricingInformation: '',
      measurementunit: '',
      price: '',
      prices: [
        {
          unit: '',
          price: 0
        }
      ],

      filter: {
        market: '',
        product: '',
        grade: '',
        date_from: '',
        date_to: ''
      },
      'avg_price': 0,
      'min_price': 0,
      'max_price': 0,
    }
  },
  computed: {},
  methods: {
    addPrices() {
      axios.post('/markets/prices', {
        product: this.product,
        grade: this.grade,
        prices: this.prices
      }).then(resp => {
        this.marketpricesAll = resp.data.marketPrices;
        $('#updatePrices').modal('hide')
      }).catch(err => {

      })
    },
    addNewUnits() {
      this.prices.push({
        unit: '',
        price: 0
      });
    },
    removeUnit(index) {
      this.prices = this.prices.splice(index, 1);
    },

    filterSearch() {
      var market = this.filter.market;
      var date_to = this.filter.date_to;
      var date_from = this.filter.date_from;
      if (market === '') {
        return;
      }
      if (date_from === '') {
        date_from = null;
      }
      if (date_to === '') {
        date_to = null;
      }

      axios.get(`markets/prices?market_id=${this.filter.market}&date_from=${this.filter.date_from}&date_to=${this.filter.date_to}&product=${this.filter.product}&grade=${this.filter.grade}`).then(resp => {
        this.marketpricesAll = resp.data.marketPrices
      }).catch(err => {
        console.error(err);
      });


    },
    productCategories() {
      axios.get('ajx/get_products_by_category/1').then(resp => {
        this.categories = resp.data;
      })
    },
    loadProducts() {
      axios.get(`ajx/get_subproducts/${this.productcategory}`).then(resp => {

        for (var i = 0; i < resp.data.length; i++) {
          console.log(resp.data[i].name);
        }
        this.productTypes = resp.data;
      })
    },
    loadProducts_() {
      axios.get(`ajx/get_subproducts/${this.productype}`).then(resp => {
        this.products = resp.data;
      })
    },
    loadGrades() {
      axios.get(`ajx/get_product_grades/${this.product}`).then(resp => {
        this.grades = resp.data;
        this.loadGradeUnits();
      })
    },
    loadGradeUnits() {
      axios.get(`ajx/get_product_units/${this.product}`).then(resp => {
        this.units = resp.data;
      })
    }
  }
}
</script>

<style scoped>

</style>
