<script setup>
import CameraPhoto, {
    FACING_MODES,
    IMAGE_TYPES,
} from "jslib-html5-camera-photo";
import {onMounted, ref, defineEmits} from "vue";

const props = defineProps(["user_id", "uploaded_photo", "disabled_field"]);
const emit = defineEmits(["upload-file"]);

let cameraPhoto = null;
const takePicture = ref(false);
const showImage = ref(false);
const uploaded_photod = ref(false);
const saving = ref(false);

onMounted(function () {
    // debugger;
    if (props.uploaded_photo != null || props.uploaded_photo != undefined) {
        uploaded_photod.value = true;
    }
});

function takeAmanaPicture() {
    showImage.value = true;
    takePicture.value = false;
    const config = {};
    let dataUri = cameraPhoto.getDataUri(config);
    let imgElement = document.getElementById("imgId");
    imgElement.src = dataUri;

    const file = DataURIToBlob(dataUri);
    emit("upload-file", file);
}

function startCameraAmana() {
    let videoElement = document.getElementById("videoId");
    videoElement?.style.removeProperty('width')

    cameraPhoto = new CameraPhoto(videoElement);
    cameraPhoto.enumerateCameras().then((cameras) => {
        cameraPhoto
            .startCamera(cameras[0].deviceId, {})
            .then(() => {
                takePicture.value = true;
            })
            .catch((error) => {
                console.error("Camera not started!", error);
            });
    });
}

function restartCameraAmana() {
    let imgElement = document.getElementById("imgId");
    imgElement.src = '';
    showImage.value = false;
    takePicture.value = false;

    emit("upload-file", null);

    // startCameraAmana();
}


function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
        splitDataURI[0].indexOf("base64") >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], {type: mimeString});
}

/*
function uploadPhoto() {
    let dataUri = cameraPhoto.getDataUri({});
    const file = DataURIToBlob(dataUri);

    const form = new FormData();

    form.append("image", file, "image.jpg");
    form.append("user_id", props.user_id);

    saving.value = true;
    axios
        .post("/apis/v4/livephoto", form)
        .then((resp) => {
            saving.value = false;
            uploaded_photod.value = resp.data;
        })
        .catch((err) => {
            saving.value = false;
        })
        .finally(() => {
            saving.value = false;
        });
}
*/
</script>

<template>
    <div class="card-body">
        <div class="row p-3" v-if="!uploaded_photod">
            <div class="row">
                <template v-if="!showImage">
                    <div class="row">
                        <video
                            style="width: 1px"
                            id="videoId"
                            autoplay="true"
                            playsinline=""
                        ></video>
                    </div>
                </template>
                <div>

                    <img style="width: 100%" id="imgId" src=""/>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <template v-if="takePicture">
                        <div class="row">
                            <button
                                class="btn btn-secondary"
                                @click="takeAmanaPicture"
                            >
                                Take Picture
                                <i class="fa fa-camera"></i>
                            </button>
                        </div>
                    </template>
                    <template v-else-if="showImage">
                        <div class="d-flex flex-column">
                            <div class="mb-2">
                                <button
                                    class="btn btn-outline-secondary"
                                    @click="restartCameraAmana">
                                    Retake picture
                                    <i class="fa fa-camera"></i>
                                </button>
                            </div>
                            <!--<div class="ml-2">
                                <button
                                    class="btn btn-success"
                                    @click="uploadPhoto"
                                >
                                    Use this picture
                                </button>
                            </div>-->
                        </div>
                    </template>
                    <template v-else>
                        <div class="row">
                            <button
                                class="btn btn-success"
                                @click="startCameraAmana"
                                :disabled="disabled_field"
                            >
                                Open Camera
                            </button>
                        </div>
                    </template>
                    <div class="mt-3">
                        Please ensure the picture shows your face very well
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="d-flex flex-column align-items-center justify-content-center">
                <img :src="uploaded_photod" alt="" style="height: 150px;object-fit: cover;object-position: center">
                <div class="mt-2">
                    <button class="btn btn-success" disabled>
                        Picture Uploaded!
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>

<style scoped>
video {
    width: 100%;
    background: lightgrey;
    height: 100%;
    object-fit: cover;
}
</style>
