<script setup>
import {onMounted, ref} from "vue";
import Accordion from "./Accordion.vue";
import CameraCapture from "../../../../../userpicture/CameraCapture.vue";
import axios from "axios";
import StatusCard from "./StatusCard.vue";
import {useAuthStore} from "../../../../../../store/auth";
import InlineTopUp from "../../../../../wallet/payments/inlinetopup/InlineTopUp.vue";
import {useAppStore} from "../../../../../../store/app";
import Swal from "sweetalert2";
import moment from "moment";

const props = defineProps(["verifications", "user", "virtual_bank_account", "market"]);
// const memberVerification = useMemberVerificationStore();
const user = useAuthStore();
const app = useAppStore();
const photoFile = ref("");
const date_of_birth = ref(user.user.dob ?? moment().subtract(18, 'years').format('YYYY-MM-DD'));
const callCenterVerification = ref(null);
const submitting = ref(false);
const verificationStarted = ref(false);
const showForm = ref(false);

const toppedUpAmount = ref(0);

const first_name = ref(user.user.first_name ?? "");
const last_name = ref(user.user.last_name ?? "");
const phone_number = ref(user.user.phone ?? "");
const bvnVerification = ref(null);
const krapinVerification = ref(null);
const ninVerification = ref(null);
const livePhotoVerification = ref(null);

const bvnOrKra = ref('BVN');
const ninOrNid = ref('NIN');
const bvnOrKraFull = ref('Bank Verification Number (BVN)');

/* Identity Verification Data*/
const errors = ref([]);
const saving = ref(false);
const bvn = ref("");
const nin = ref("");
const userAddress = ref("");
const userCity = ref("");
/* Identity Verification Data*/

const verificationStatus = ref({
    status: "inprogress",
    title: "Verification is in progress",
    message: "Your Verification is in progress",
    trustLevel: 0,
});

const updateProfile = () => {
    saving.value = true;
    axios
        .post("/apis/v4/editingProfile", {
            first_name: first_name.value,
            last_name: last_name.value,
            dob: date_of_birth.value,
        })
        .then(() => {
            saving.value = false;
            Swal.fire({
                'text': "Details updated successfully",
                'position': 'top-end',
                showConfirmButton: false,
                showCloseButton: false,
                icon: 'success',
                timer: 5000
            })

        });
};

const walletToppedUp = () => {
    user.wallet.current_balance = toppedUpAmount.value;
    let myModalEl = document.querySelector("#topupInline");
    let modal = bootstrap.Modal.getOrCreateInstance(myModalEl);

    modal.hide();

    submit();
};

onMounted(() => {
    bvn.value = user.user.bvn;
    nin.value = user.user.national_id;
    userAddress.value = user.user.address;
    userCity.value = user.user.city;

    if(app.country === 'ke'){
        bvnOrKra.value = 'KRA PIN';
        ninOrNid.value = 'National ID';
        bvnOrKraFull.value = 'KRA PIN Number';
    }

    getVerifications();
});

const setPhotoURL = (e) => {
    photoFile.value = e;
};

const submit = () => {
    submitting.value = true;
    let formData = new FormData();
    formData.append('bvn', bvn.value);
    formData.append('national_id', nin.value);
    formData.append('kra_pin', bvn.value);
    formData.append('rentalagreement', null);
    formData.append('address', userAddress.value);
    formData.append('city', userCity.value);
    formData.append("types[]", "bvn");
    formData.append("types[]", "nin");

    axios
        .post("/apis/v4/verifications/market-member/savedetails", formData)
        .then(() => {
            errors.value = [];
            submitting.value = false;
            getVerifications();
            qoreIdVerifications();
        })
        .catch((err) => {
            Swal.fire("There has been an error", "error");
            errors.value = err.response.data.errors;
            submitting.value = false;
        })
        .finally(() => {
            submitting.value = false;
        });
};

const getVerifications = async () => {
    await axios.get("/apis/v4/saas/verifications").then((resp) => {
        callCenterVerification.value = resp.data.data?.recent_results?.call_center;
        bvnVerification.value = resp.data.data.recent_results.bvn;
        krapinVerification.value = resp.data.data.recent_results.krapin;
        ninVerification.value = resp.data.data.recent_results.nin;
        livePhotoVerification.value = resp.data.data.recent_results.face_match;
    });

    getVerificationStatus();
};

const qoreIdVerifications = () => {
    submitting.value = true;

    const form = new FormData();

    form.append("verification_type", "user");
    form.append("market_id", user.market?.id);
    form.append("user_id", user.user?.id);
    form.append("types[]", "bvn");
    form.append("types[]", "nin");

    // bvn-verification-form-append
    if (!bvnVerification.value || bvnVerification.value === "failed") {
        form.append("types[]", "bvn");
        form.append("bvn", bvn.value);
    } else {
        form.append("bvn", bvn.value);
    }

    // nin-verification-form-append
    if (!ninVerification.value || ninVerification.value === "failed") {
        form.append("types[]", "nin");
        form.append("nin", nin.value);
    } else {
        form.append("nin", nin.value);
    }

    // face-match-verification-form-append
    if (photoFile.value && (!livePhotoVerification.value || livePhotoVerification.value === "failed")) {
        form.append("types[]", "face_match");
        form.append("face_match[photo]", photoFile.value ? photoFile.value : null);

        form.append("face_match[id_number]", bvn.value);
        form.append("face_match[id_type]", "bvn");
        form.append("face_match[id_number]", nin.value);
        form.append("face_match[id_type]", "nin");
        form.append("user_id", user.user.id);
    }

    axios
        .post("/apis/v4/saas/verifications", form)
        .then((resp) => {
            submitting.value = false;

            broadcastListener(resp.data.data);

            // show thank you modal
            let myModalEl = document.querySelector("#thankYou");
            let modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
            modal.toggle();
            // hide details saved modal
            bootstrap.Modal.getOrCreateInstance(document.querySelector("#detailsSaved")).hide();

            getVerifications();
        })
        .catch((err) => {
            Swal.fire(
                `${err.response?.data?.message ?? "There were issues with your core id verification"}`,
                "",
                "error"
            );
        })
        .finally(() => {
        });
};

function getVerificationStatus() {
    verificationStarted.value = true;
    let trustLevel = user?.user?.agent_limit?.id ?? 0
    if (
        (callCenterVerification.value === "On Hold" || callCenterVerification.value === null) ||
        (bvnVerification.value === "failed") || krapinVerification.value === "failed" ||
        ninVerification.value === "failed" ||
        livePhotoVerification.value === "failed"
    ) {
        verificationStarted.value = true;
        verificationStatus.value = {
            status: "failed",
            title: "Verification Failed",
            message: "Your verification has failed",
            trustLevel: trustLevel,
        };
    } else if (
        callCenterVerification.value === "Success" &&
        (bvnVerification.value === "success" || krapinVerification.value === "success") &&
        ninVerification.value === "success" &&
        livePhotoVerification.value === "success"
    ) {
        verificationStarted.value = true;
        verificationStatus.value = {
            status: "success",
            title: "Verification Succeeded",
            message: "Your verification has Succeeded",
            trustLevel: trustLevel,
        };
    } else if (
        callCenterVerification.value == null &&
        (bvnVerification.value == null || krapinVerification.value == null) &&
        ninVerification.value == null &&
        livePhotoVerification.value == null
    ) {
        verificationStarted.value = false;
        verificationStatus.value = {
            status: "not_started",
            title: "Verification not Started",
            message: "Your verification has not started",
            trustLevel: trustLevel,
        };
    }
}

function makePayment() {
    let myModalEl = document.querySelector("#topupInline");
    let modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
    modal.show();
}

function saveDetails() {
    let myModalEl = document.querySelector("#detailsSaved");
    let modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
    let formData = new FormData();

    submitting.value = true;

    formData.append('bvn', bvn.value);
    formData.append('national_id', nin.value);
    formData.append('kra_pin', bvn.value);
    formData.append('rentalagreement', null);
    formData.append('address', userAddress.value);
    formData.append('city', userCity.value);

    axios.post("/apis/v4/verifications/market-member/savedetails", formData).then(() => {
        errors.value = [];
        modal.show();
    }).catch((err) => {
        errors.value = err.response.data.errors;
        Swal.fire({
            icon: 'error',
            title: 'There has been an error. Please check your details and try again.',
        });
    }).finally(() => {
        submitting.value = false;
    });
}

function onClickStartVerification() {
    // verificationStarted.value = !verificationStarted.value;
    // showForm.value = !showForm.value;
    verificationStarted.value = true;
    showForm.value = true;
}

const broadcastListener = (data) => {
    data.forEach((verification) => {
        Echo.channel("verifications" + verification["id"]).listen(".verification", () => {
            getVerifications();
        });
    });
};
</script>

<template>
    <template v-if="!verificationStarted">
        <div class="card mb-3 tw-rounded-lg tw-shadow-lg text-white bg-dark mb-3">
            <div class="card-body">
                <h4 style="color: #fff">Get Verified</h4>
                <p>
                    Get verified on Amana Market so other traders and farmers can trust you and you can access
                    financing options depending on your region.
                </p>
                <p class="text-warning">
                    <strong>Please note that this is a third party verification and will cost
                        {{ app.currencySign }}{{ app.userVerificationFee }}
                    </strong>
                </p>
                <button @click="onClickStartVerification" class="btn btn-secondary btn-sm tw-rounded tw-font-bold">
                    Start Verification
                </button>
            </div>
        </div>
    </template>
    <template v-else>
        <template v-if="verificationStatus.status == null || verificationStatus.status === 'not_started'">
            <div class="card mb-3 tw-rounded-lg tw-shadow-lg text-white bg-dark mb-3">
                <div class="card-body">
                    <h4 style="color: #fff">Get Verified</h4>
                    <p>
                        Get verified on Amana Market so other traders and farmers can trust you and you can access
                        financing options depending on your region.
                    </p>
                    <p class="text-warning">
                        <strong>Please note that this is a third party verification and will cost N500 Naira</strong>
                    </p>
                    <button @click="onClickStartVerification" class="btn btn-secondary btn-sm tw-rounded tw-font-bold">
                        Start Verification
                    </button>
                </div>
            </div>
        </template>
        <template v-else>
            <StatusCard :status="`${verificationStatus.status}`" :market="props.market"
                        :verificationstatus="verificationStatus"
                        @showForm="(args) => (showForm = args)">
                <template #title>
                    {{ verificationStatus.title }}
                </template>
                <template #message>
                    {{ verificationStatus.message }}
                </template>
                <template #trustlevel>
                    Trust Level {{ verificationStatus.trustLevel }}
                </template>
            </StatusCard>
        </template>
        <div v-if="showForm">
            <div class="row">
                <Accordion key="callcenter">
                    <template #title> Call Center Verification</template>
                    <template #status>
                        <div v-if="callCenterVerification === 'pending'">
                            <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="34" height="34" rx="17" fill="#FFF8EB"/>
                                <path
                                    d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                    stroke="#D78100"
                                    stroke-width="1.5"/>
                                <path
                                    d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                    stroke="#D78100"
                                    stroke-width="1.5"
                                    stroke-linecap="round"/>
                            </svg>
                        </div>
                        <div v-if="callCenterVerification === 'Correct' || callCenterVerification === 'Success'">
                            <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
                                <rect width="34" height="34" rx="17" fill="#41AE49"/>
                                <path
                                    d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                    stroke="#F8FBF8"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div v-if="callCenterVerification === 'InCorrect' || callCenterVerification === 'On Hold'">
                            <span class="badge badge-pill badge-danger">
                                <svg
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect width="34" height="34" rx="17" fill="#FFF2F0"/>
                                    <path
                                        d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                        stroke="#E2341D"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </div>
                        <div v-else/>
                    </template>
                    <template #errors></template>
                    <div>
                        <p v-if="callCenterVerification === 'InCorrect'" class="text-danger">
                            Call verification failed
                        </p>
                        <div class="alert alert-secondary tw-rounded-2xl" role="alert">
                            <div class="d-flex">
                                <div>
                                    <i class="fa-duotone fa-circle-info fa-4x"></i>
                                </div>
                                <div class="flex-grow-1 tw-ml-3 tw-text-black">
                                    <p class="tw-antialiased tw-font-bold tw-text-sm">
                                        Call <a class="tw-text-gray-500" :href="`tel:${app.callCenterPhoneNumber}`">
                                        <strong>
                                            {{ app.callCenterPhoneNumber }}
                                        </strong>
                                    </a> with your registered number to initiate your verification
                                        or wait for our team to call you on your registered number
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3">
                        <p class="tw-text-sm tw-font-semibold tw-text-gray-900">
                            Confirm your information below as provided in your {{ ninOrNid }} or {{ bvnOrKra }}
                        </p>
                        <div class="col-12">
                            <div class="form-floating">
                                <input
                                    type="text"
                                    class="form-control"
                                    id="first_name"
                                    v-model="first_name"
                                    aria-describedby="first_name-help"
                                    :class="[
                                    errors.first_name ? 'is-invalid' : '',
                                    !errors.first_name && Object.keys(errors).length > 1 ? 'is-valid' : '',
                                ]"/>
                                <label for="first_name">First Name</label>
                                <div class="invalid-feedback" v-if="errors.first_name">
                                    {{ errors.first_name.toString() }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-floating">
                                <input
                                    type="text"
                                    class="form-control"
                                    id="last_name"
                                    v-model="last_name"
                                    aria-describedby="last_name-help"
                                    :class="[
                                    errors.last_name ? 'is-invalid' : '',
                                    !errors.last_name && Object.keys(errors).length > 1 ? 'is-valid' : '',
                                ]"
                                />
                                <label for="last_name">Last Name</label>

                                <div class="invalid-feedback" v-if="errors.last_name">
                                    {{ errors.last_name.toString() }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <template v-if="app.country_abbr === 'ke'">
                                <!--Label: Date of Birth, Attributes:date_of_birth -->
                                <div class="form-floating">
                                    <input type="date" class="form-control" id="date_of_birth" v-model="date_of_birth"
                                           aria-describedby="date_of_birth-help"
                                           :class="[errors.date_of_birth ? 'is-invalid': '',!errors.date_of_birth && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                    >
                                    <label for="date_of_birth">Date of Birth</label>

                                    <div class="invalid-feedback" v-if="errors.date_of_birth">
                                        {{ errors.date_of_birth.toString() }}
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="col-12">
                            <div class="form-floating">
                                <input
                                    type="text"
                                    disabled
                                    class="form-control"
                                    id="phone_number"
                                    v-model="phone_number"
                                    aria-describedby="phone_number-help"
                                    :class="[
                                    errors.phone_number ? 'is-invalid' : '',
                                    !errors.phone_number && Object.keys(errors).length > 1 ? 'is-valid' : '',
                                ]"
                                    placeholder="Phone Number"
                                    required/>
                                <label for="phone_number">Phone Number</label>

                                <div class="invalid-feedback" v-if="errors.phone_number">
                                    {{ errors.phone_number.toString() }}
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <button style="color: #4b9d30" class="poppins-bold mt-3 underline btn btn-outline-secondary"
                                    @click="updateProfile">
                                Save
                                <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                            </button>
                        </div>
                    </div>
                </Accordion>
                <!--    BVN Verification    -->
                <Accordion key="bvn">
                    <template #title>
                        {{ bvnOrKra }} Verification
                    </template>
                    <template #status>
                        <div v-if="(errors && errors.bvn) || (errors && errors.kra_pin)">
                            <div
                                class="tw-inline-block tw-w-22 tw-px-3 tw-py-1 tw-rounded-full tw-bg-red-100 tw-text-red-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                <i class="fa fa-hexagon-exclamation fa-beat-fade fa-fw tw-text-red-600"></i>
                                Error
                            </div>
                        </div>
                        <template v-else>
                            <div
                                v-if="(bvnVerification === null || bvnVerification === 'pending') || krapinVerification === null || krapinVerification === 'pending'">
                                <svg
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect width="34" height="34" rx="17" fill="#FFF8EB"/>
                                    <path
                                        d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                        stroke="#D78100"
                                        stroke-width="1.5"/>
                                    <path
                                        d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                        stroke="#D78100"
                                        stroke-width="1.5"
                                        stroke-linecap="round"/>
                                </svg>
                            </div>
                            <div v-if="(bvnVerification === 'success') || (krapinVerification === 'success')">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
                                    <rect width="34" height="34" rx="17" fill="#41AE49"/>
                                    <path
                                        d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                        stroke="#F8FBF8"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div v-if="(bvnVerification === 'failed') || krapinVerification === 'failed'">
                                <span class="badge badge-pill badge-danger">
                                    <svg
                                        width="34"
                                        height="34"
                                        viewBox="0 0 34 34"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect width="34" height="34" rx="17" fill="#FFF2F0"/>
                                        <path
                                            d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                            stroke="#E2341D"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div v-else/>
                        </template>
                    </template>
                    <template #errors></template>
                    <div>
                        <p v-if="bvnVerification === 'failed' || krapinVerification === 'failed'" class="text-danger">
                            {{ bvnOrKra }} verification failed.
                            Please check your {{ bvnOrKra }}, update and resubmit with the correct information
                        </p>
                        <div class="form-floating">
                            <input
                                type="text"
                                class="form-control form-control-primary"
                                id="bvn"
                                v-model="bvn"
                                aria-describedby="bvn-help"
                                :class="[
                                errors.bvn ? 'is-invalid' : '',
                                !errors.bvn && Object.keys(errors).length > 1 ? 'is-valid' : '',
                            ]"
                                :disabled="
                                bvnVerification === null || bvnVerification === 'pending' || bvnVerification === 'success'
                            "/>
                            <label for="bvn" class="form-label">
                                Enter {{ bvnOrKraFull }}
                            </label>
                        </div>

                        <div class="invalid-feedback" v-if="errors.bvn">
                            {{ errors.bvn.toString() }}
                        </div>
                    </div>
                </Accordion>

                <!--    NIN Verification    -->
                <Accordion key="nin">
                    <template #title>
                        {{ ninOrNid }} Verification
                    </template>
                    <template #status>
                        <div v-if="errors.national_id">
                            <div
                                class="tw-inline-block tw-w-22 tw-px-3 tw-py-1 tw-rounded-full tw-bg-red-100 tw-text-red-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                <i class="fa fa-hexagon-exclamation fa-beat-fade fa-fw tw-text-red-600"></i>
                                Error
                            </div>
                        </div>
                        <template v-else>
                            <div v-if="ninVerification === null || ninVerification === 'pending'">
                                <svg
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect width="34" height="34" rx="17" fill="#FFF8EB"/>
                                    <path
                                        d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                        stroke="#D78100"
                                        stroke-width="1.5"/>
                                    <path
                                        d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                        stroke="#D78100"
                                        stroke-width="1.5"
                                        stroke-linecap="round"/>
                                </svg>
                            </div>
                            <div v-if="ninVerification === 'success'">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
                                    <rect width="34" height="34" rx="17" fill="#41AE49"/>
                                    <path
                                        d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                        stroke="#F8FBF8"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div v-if="ninVerification === 'failed'">
                                <span class="badge badge-pill badge-danger">
                                    <svg
                                        width="34"
                                        height="34"
                                        viewBox="0 0 34 34"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect width="34" height="34" rx="17" fill="#FFF2F0"/>
                                        <path
                                            d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                            stroke="#E2341D"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div v-else/>
                        </template>
                    </template>
                    <template #errors></template>
                    <!--Label: National Identification, Attributes:nin -->
                    <div class="">
                        <p v-if="ninVerification === 'failed'" class="text-danger">
                            {{ ninOrNid }} verification failed.
                            Please check your {{ ninOrNid }}, update and resubmit with the correct information
                        </p>
                        <div class="form-floating">
                            <input
                                type="text"
                                class="form-control"
                                id="nin"
                                v-model="nin"
                                aria-describedby="nin-help"
                                :class="[
                                errors.national_id ? 'is-invalid' : '',
                                !errors.national_id && Object.keys(errors).length > 1 ? 'is-valid' : '',
                            ]"
                                required
                                :disabled="
                                ninVerification === null || ninVerification === 'pending' || ninVerification === 'success'
                            "/>
                            <label for="nin" class="form-label">Enter National Identification Number</label>
                        </div>
                        <div class="invalid-feedback" v-if="errors.national_id">
                            {{ errors.national_id.toString() }}
                        </div>
                    </div>
                </Accordion>

                <!--    Live Photo Verification    -->
                <Accordion key="liveVerification">
                    <template #title> Live Verification</template>
                    <template #status>
                        <div v-if="livePhotoVerification === null || livePhotoVerification === 'pending'">
                            <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="34" height="34" rx="17" fill="#FFF8EB"/>
                                <path
                                    d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                    stroke="#D78100"
                                    stroke-width="1.5"/>
                                <path
                                    d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                    stroke="#D78100"
                                    stroke-width="1.5"
                                    stroke-linecap="round"/>
                            </svg>
                        </div>
                        <div v-if="livePhotoVerification === 'success'">
                            <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
                                <rect width="34" height="34" rx="17" fill="#41AE49"/>
                                <path
                                    d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                    stroke="#F8FBF8"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div v-if="livePhotoVerification === 'failed'">
                            <span class="badge badge-pill badge-danger">
                                <svg
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect width="34" height="34" rx="17" fill="#FFF2F0"/>
                                    <path
                                        d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                        stroke="#E2341D"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </div>
                        <div v-else/>
                    </template>
                    <template #errors></template>
                    <p v-if="livePhotoVerification === 'failed'" class="text-danger">
                        Live verification failed. Please take another photo and resubmit
                    </p>
                    <CameraCapture
                        @upload-file="setPhotoURL"
                        :uploaded_photo="null"
                        :user_id="user.user.id"
                        :disabled_field="
                            livePhotoVerification === null ||
                            livePhotoVerification === 'pending' ||
                            livePhotoVerification === 'success'
                        "></CameraCapture>
                </Accordion>
            </div>
            <!--    Submit Button    -->
            <!--    only show button if 1 or more of the verifications failed or not started   -->
            <div class="row tw-ml-3 tw-mr-3">
                <div
                    class="d-flex justify-content-end"
                    v-if="
                    !bvnVerification ||
                    bvnVerification === 'failed' ||
                    !ninVerification ||
                    ninVerification === 'failed' ||
                    !livePhotoVerification ||
                    livePhotoVerification === 'failed'
                ">
                    <template v-if="user.verificationFee === true">
                        <button
                            class="btn btn-secondary mr-3 mb-3"
                            @click.prevent="submit"
                            :disabled="
                            !(!bvnVerification || bvnVerification === 'failed') &&
                            !(!ninVerification || ninVerification === 'failed') &&
                            !(!livePhotoVerification || livePhotoVerification === 'failed')
                        ">
                            Complete
                            <i class="fa fa-spin fa-spinner" v-if="submitting"></i>
                            <i class="fa fa-thin fa-save" v-else> </i>
                        </button>
                    </template>
                    <template v-if="!user.verificationFee && user.wallet?.current_balance >= 250">
                        <div class="d-flex flex-column">
                            <div class="alert alert-success" role="alert">
                                <strong>
                                    You have enough funds in your wallet to be deducted for the verification fee. To
                                    continue, click the button below.
                                </strong>
                            </div>
                            <div>
                                <div class="d-flex justify-content-end">
                                    <button
                                        class="btn btn-secondary mr-3 mb-3"
                                        @click.prevent="submit"
                                        :disabled="
                                        !(!bvnVerification || bvnVerification === 'failed') &&
                                        !(!ninVerification || ninVerification === 'failed') &&
                                        !(!livePhotoVerification || livePhotoVerification === 'failed')
                                    ">
                                        Complete
                                        <i class="fa fa-spin fa-spinner" v-if="submitting"></i>
                                        <i class="fa fa-thin fa-save" v-else> </i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="!user.verificationFee && user.wallet?.current_balance < 250">
                        <div class="d-flex flex-column">
                            <div class="alert alert-danger" role="alert">
                                <strong>
                                    You don't have enough funds in your wallet to be deducted for the verication fee. To
                                    continue, click the button below to topup.
                                </strong>
                            </div>
                            <div>
                                <div class="d-flex justify-content-end">
                                    <button
                                        class="btn btn-secondary mr-3 mb-3"
                                        @click.prevent="saveDetails"
                                        :disabled="
                                        !(!bvnVerification || bvnVerification === 'failed') &&
                                        !(!ninVerification || ninVerification === 'failed') &&
                                        !(!livePhotoVerification || livePhotoVerification === 'failed')
                                    ">
                                        Complete
                                        <i class="fa fa-spin fa-spinner" v-if="submitting"></i>
                                        <i class="fa fa-thin fa-save" v-else> </i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </template>
    <div
        class="modal fade"
        id="detailsSaved"
        tabindex="-1"
        role="dialog"
        aria-labelledby="detailsSaved"
        aria-hidden="true"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <!--<div class="modal-header"></div>-->
                <div class="modal-body">
                    <div class="col-12" style="padding: 10px">
                        <h4 class="text-success">Your details have been saved!</h4>
                        <p>Proceed to make a one time payment for your third party verification.</p>
                        <p>
                            <strong>Third party verification will cost {{
                                    app.currencySign
                                }}{{ app.userVerificationFee }}</strong><br/>
                            (additional payment service charges may be incurred)
                        </p>
                        <button class="btn btn-success" @click.prevent="makePayment">Pay Now</button>&nbsp;
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Skip and pay later
                        </button>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div
        class="modal fade"
        id="topupInline"
        tabindex="-1"
        role="dialog"
        aria-labelledby="topupInline"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="topupInline">Make Verification Payment</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <InlineTopUp
                            ref="inlineTopUp"
                            :userobject="user"
                            @walletToppedUp="walletToppedUp"
                            :amountToPay="500"
                            :location_url="'/four/profile'"
                            :virtual_bank_account="props.virtual_bank_account"
                            @walletToppedUpAmount="(args) => (toppedUpAmount = args)"></InlineTopUp>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Verification request sent Modal -->
    <div class="modal fade" id="thankYou" tabindex="-1" role="dialog" aria-labelledby="thankYouId" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="thankYouId poppins-bold" style="color: #4b9d30">Thank you!!!</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>
                        Your verification has been submitted and you will be notified once it is complete. Please note
                        that verification can take up to 7 days If your verification is not complete in 7 days, please
                        call {{ app.callCenterPhoneNumber }} to confirm your status
                    </p>
                    <button type="button" class="btn btn-success" data-bs-dismiss="modal">Continue</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
/* .drag-target {
//border: 1px dashed #ccc; //border-radius: 5px; //padding: 10px; //text-align: center;
} */

.drag-target.dragging {
    background-color: #aaa;
}
</style>
