<script setup>
import {useOrderStore} from "../../../../../store/Admin/order";
import {ref} from "vue";
import {useAppStore} from "../../../../../store/app";
import SearchForSellerByAdmin from "./SearchForSellerByAdmin.vue";
import ChooseBuyer from "./Steps/ChooseBuyer.vue";

const orderStore = useOrderStore();
const appStore = useAppStore();
const currentStep = ref('searchForSeller')
const updateCurrentStep = (step) => {
    currentStep.value = step
};
const reloadPage = (step) => {
    window.location.reload()
};
</script>

<template>
    <SearchForSellerByAdmin :key="1" @updateStep="updateCurrentStep" v-if=" currentStep === 'searchForSeller'"/>
    <ChooseBuyer :key="1" @updateStep="updateCurrentStep" v-if="currentStep === 'chooseBuyer'"></ChooseBuyer>
    <template v-if="currentStep === 'orderconfirmed'">
       <div class="row">
           <div class="col-6 offset-3">
               <div class="card tw-rounded-2xl">
                   <div class="card-body">
                       <div class="row">
                           <div class="d-flex">
                               <i class="fa-solid fa-circle-check fa-5x tw-text-green-600"></i>
                           </div>
                           <div class="tw-mt-3 tw-antialiased">
                               <div class="tw-mb-0">
                                   <p class="tw-text-2xl tw-font-bold ">
                                       Order Confirmed
                                   </p>
                               </div>
                               <div class="tw-mt-1">
                                   <p class="text-muted">
                                       Your order was completed successfully.
                                   </p>
                               </div>
                           </div>
                       </div>
                       <div class="row">
                           <div class="table-responsive">
                               <table class="table">
                                   <tbody>
                                   <tr>
                                       <th>Order #</th>
                                       <td>{{orderStore.orderConfirmed.value.order_id}}</td>
                                   </tr>
                                   <tr>
                                       <th>Subtotal</th>
                                       <td>{{appStore.currencySign}} {{orderStore.orderConfirmed.value.items_price}}</td>
                                   </tr>
                                   <tr>
                                       <th>Total</th>
                                       <td>
                                           {{appStore.currencySign}}
                                           {{ parseFloat(orderStore.orderConfirmed.value.total).toFixed(2) }}
                                       </td>
                                   </tr>
                                   <tr>
                                       <th>Seller</th>
                                       <td>{{orderStore.seller.value.name}}</td>
                                   </tr>
                                   <tr>
                                       <th>Buyer</th>
                                       <td>{{orderStore.buyer.value.name}}</td>
                                   </tr>
                                   </tbody>
                               </table>
                           </div>
                       </div>
                   </div>
                   <div class="col-12 tw-p-3 tw-mr-5 d-flex justify-content-end">
                       <button class="btn btn-primary" @click="reloadPage">
                           Make New Order
                           <i class="fa fa-plus"></i>
                       </button>
                   </div>
               </div>
           </div>
       </div>

    </template>
</template>

