<script setup>

import {onMounted, ref} from "vue";
import {Bootstrap5Pagination} from 'laravel-vue-pagination';
import moment from "moment/moment";

const accounts = ref({});
const errors = ref({});
const searchAccount = ref('');

const getPartnerBankAccounts = async (page = 1) => {
    await axios.get(`/apis/v4/saas/pb-accounts?page=${page}&keyword=${searchAccount.value}`).then(resp => {
        accounts.value = resp.data.data;
    }).catch(err => {
        errors.value = err.response.data.errors;
    });
}

function setSearchAccount() {
    let urlParams = new URLSearchParams(window.location.search);
    searchAccount.value = urlParams.has('search') ? urlParams.get('search') : '';
}

function formatDate(value) {
    return moment(value).format('DD/MM/YYYY HH:mm:ss');
}

onMounted(function () {
    setSearchAccount();
    getPartnerBankAccounts();
});

</script>

<template>
    <div>
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <form action="/four/admin/bank-accounts" method="GET">
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Search by name, number or bvn"
                                           name="search"
                                           id="searchAccount"
                                           :value="searchAccount"
                                           aria-label="Search Account" aria-describedby="button-addon2">
                                    <button class="btn btn-outline-secondary" type="submit" id="performSearch">
                                        Search
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead v-if="accounts && accounts.data && accounts.data.length > 0">
                            <tr>
                                <th>#</th>
                                <th>Account Name</th>
                                <th>Account No.</th>
                                <th>Bank</th>
                                <th>Type</th>
                                <th>Date Created</th>
                            </tr>
                            </thead>
                            <tbody v-if="accounts && accounts.data && accounts.data.length > 0">
                            <tr v-for="(account,index) in accounts.data" :key="account.id">
                                <td scope="row">{{ index + 1 }}</td>
                                <td>{{ account.account_name }}</td>
                                <td>{{ account.account_number }}</td>
                                <td>{{ account.partner_bank }}</td>
                                <td>{{ account.account_type }}</td>
                                <td>{{ formatDate(account.created_at) }}</td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td align="center" colspan="7">No record found.</td>
                            </tr>
                            </tbody>
                        </table>
                        <bootstrap5-pagination :data="accounts" @pagination-change-page="getPartnerBankAccounts" align="center"/>
                    </div>
                    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample"
                         aria-labelledby="offcanvasExampleLabel">
                        <div class="offcanvas-header">
                            <h5 class="offcanvas-title" id="offcanvasExampleLabel">Add Account</h5>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                                    aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <add-bank-account></add-bank-account>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.page-link {
    color: #00ab4d;
}
.page-link:hover {
    color: #00ab4d;
}
.active>.page-link, .page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: #00ab4d;
    border-color: #00ab4d;
}
</style>
