<script setup>

import {onBeforeUnmount, onMounted, ref, watch} from "vue";
import Products from "./forms/products.vue";
import Fees from "../payments/forms/fees.vue";

const props = defineProps(['user', 'isAgentOrder']);
const emit = defineEmits(['setproduct', 'amountsetevent', 'quantitysetevent', 'agentFeeSetEvent']);


const purpose = ref('product');
const currentUser = ref('');
const productsRef = ref('');
const product = ref('');
const amount = ref('');
const quantity = ref('');
const agentFee = ref(0);

function productsetevent(productData) {
    product.value = productData;
    emit('setproduct', productData)
}

function amountsetevent(productData) {
    amount.value = productData;
    emit('amountsetevent', productData)
}

function quantitysetevent(productData) {
    quantity.value = productData;
    emit('quantitysetevent', productData)
}

function agentFeeSetEvent(productData) {
    agentFee.value = productData;
    emit('agentFeeSetEvent', productData)
}

const key = ref(1);

function setPurpose(parameter) {
    purpose.value = parameter;
    key.value++;
}

const mutisya = ref(0);

function updateReference() {
    mutisya.value++;
}

defineExpose({
    key,
    purpose,
    productsRef,
    updateReference
})
</script>

<template>
    <div class="col-12">
        <products
            :user="props.user"
            :key="mutisya"
            :is-agent-order="isAgentOrder"
            ref="productsRef"
            @productset="productsetevent"
            @amountset="amountsetevent"
            @quantityset="quantitysetevent"
            @agentFeeSet="agentFeeSetEvent"
        ></products>
        <div v-if="purpose === 'fees'">
            <fees :user="props.user" :key="key"></fees>
        </div>
    </div>
</template>

<style scoped>

</style>
