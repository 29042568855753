<script setup>
import { onMounted, ref } from "vue";

const props = defineProps(["markets"]);

const open = ref(false);

const toggleOpen = () => {
    open.value = !open.value;
};

const changeMarket = (id) => {
    axios
        .get(`/four/change_market/${id}`)
        .then(() => {
            window.location.href = "/four/market/profile";
        })
        .catch((err) => {
            console.log(err?.response?.data);
        });
};
</script>

<template>
    <div
        class="has-arrow"
        style="
            width: 100%;
            margin: 0;
            background-color: #283342;
            padding: 8px 15px;
            position: absolute;
            bottom: 0;
        "
        id="myaccount"
    >
        <div style="position: relative">
            <div
                @click="toggleOpen"
                class="menu-title"
                style="cursor: pointer; color: white; position: relative"
            >
                <p
                    style="
                        text-overflow: ellipsis;
                        margin: 0;
                        padding: 0;
                        white-space: nowrap;
                        width: 100%;
                        overflow: hidden;
                    "
                >
                    Change Market
                </p>
                <svg
                    style="
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 1rem;
                    "
                    width="6"
                    height="9"
                    viewBox="0 0 6 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.851974 1.58301L3.6296 4.36063L0.851974 7.13826C0.572779 7.41746 0.572779 7.86847 0.851974 8.14766C1.13117 8.42685 1.58218 8.42685 1.86137 8.14766L5.14728 4.86175C5.42647 4.58256 5.42647 4.13155 5.14728 3.85236L1.86137 0.566452C1.58218 0.287257 1.13117 0.287257 0.851974 0.566452C0.579938 0.845646 0.572779 1.30381 0.851974 1.58301Z"
                        fill="white"
                    />
                </svg>
            </div>
            <div
                style="
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    width: 200px;
                    position: absolute;
                    left: calc(100% + 15px);
                    bottom: calc(0px - 8px);
                "
                v-if="open"
            >
                <div
                    style="
                        margin: 0;
                        text-align: center;
                        background-color: #283342;
                        padding: 8px 15px;
                        width: 100%;
                        color: #fff;
                        cursor: pointer;
                    "
                    v-for="item in markets"
                    @click="changeMarket(item.id)"
                    :key="item.id"
                >
                    {{ item.name }}
                </div>
                <div
                    style="
                        margin: 0;
                        text-align: center;
                        background-color: #fff;
                        padding: 8px 15px;
                        width: 100%;
                        color: #283342;
                        cursor: pointer;
                    "
                >
                    + Create Market
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
