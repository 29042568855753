<script setup>
import {ref, watch} from "vue";

const otp = ref('');
const emit = defineEmits(['updatedotp']);
const props = defineProps(['errors'])

watch(otp,function (newValue,oldValue) {
  emit('updatedotp',newValue)
})
</script>

<template>
  <div>
    <div class="col-12">
      <!--Label: OTP, Attributes:otp -->
      <div class="form-group">
        <label for="otp">Insert OTP</label>
        <input type="text" class="form-control" id="otp" v-model="otp"
               aria-describedby="otp-help"
               :class="[errors.otp ? 'is-invalid': '',!errors.otp && Object.keys(errors).length > 1 ? 'is-valid': '']"
               placeholder="OTP" required>
        <div class="invalid-feedback" v-if="errors.otp">
          {{ errors.otp.toString() }}
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="alert alert-info" style="border-radius: 1em" role="alert">
        <strong>An OTP has been sent to the user you are requesting payment from.
          Please input the OTP Code to
          continue</strong>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
