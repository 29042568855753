<script setup>

import {onMounted, ref} from "vue";
import moment from "moment";

const staffUsers = ref([]);
const saving = ref(false);
const errors = ref([]);
const search_admin_phone = ref('');
const search_market = ref('');
onMounted(() => {
    getUsers();
});
const getUsers = () => {
    axios.get('/apis/v4/markets/staff/list')
        .then(resp => {
            staffUsers.value = resp.data;
        });
}

const searchStaffMembers = ref([]);
const searchPhoneNumber = () => {
    axios.post('/apis/v4/markets/staff/search', {
        search_admin_phone: search_admin_phone.value
    })
        .then(resp => {
            searchStaffMembers.value = resp.data;
        });
}
const marketList = ref([]);
const searchMarket = () => {
    axios.post('/apis/v4/markets/search/name', {
        search_market: search_market.value
    })
        .then(resp => {
            marketList.value = resp.data;
        });
}

const chosenUser = ref([]);
const currentStep = ref('searchadmin');
const chooseUser = (user) => {
    chosenUser.value = user;
    currentStep.value = 'searchmarket';
}
const chosenMarket = ref([]);

const chooseMarket = (market) => {
    chosenMarket.value = market;
    currentStep.value = 'summary';
    saving.value = true;
}
const formatDate = (date)=>{
    return moment(date).format('LL');
}
const resetState = () => {
    chosenMarket.value = [];
    chosenUser.value = [];
    staffUsers.value = [];
    saving.value = false;
    errors.value = [];
    search_admin_phone.value = '';
    search_market.value = '';
}
const addToMarket = () => {
    axios.post('/apis/v4/markets/staff/adduser', {
        market: chosenMarket.value,
        user: chosenUser.value,
    }).then(resp => {
        resetState();
        document.getElementById('btn-close').click();
        getUsers();
        currentStep.value = 'searchadmin';
    }).catch(() => {
    });
}
</script>

<template>
    <div>
        <div>
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <div class="d-flex justify-content-end">
                            <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addStaffID">
                                Add Staff to Market
                            </button>

                            <!-- Modal -->
                            <div class="modal fade" id="addStaffID" tabindex="-1" role="dialog"
                                 aria-labelledby="addStaffTitleId" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h4 class="modal-title" id="addStaffTitleId">Add Staff to Market </h4>
                                            <button type="button" class="btn-close" id="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close">

                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="container-fluid">
                                                <div v-if="currentStep == 'searchadmin'">
                                                    <form action="">
                                                        <!--Label: Search Phone Number, Attributes:search_admin_phone -->
                                                        <div class="input-group mb-3">
                                                            <input type="text" class="form-control"
                                                                   id="search_admin_phone" v-model="search_admin_phone"
                                                                   aria-describedby="search_admin_phone-help"
                                                                   :class="[errors.search_admin_phone ? 'is-invalid': '',!errors.search_admin_phone && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                                                   placeholder="Search Admin Phone Number" required>
                                                            <button class="btn btn-outline-secondary" type="button"
                                                                    @click.prevent="searchPhoneNumber"
                                                                    id="button-addon1">
                                                                Search
                                                            </button>

                                                            <div class="invalid-feedback"
                                                                 v-if="errors.search_admin_phone">
                                                                {{ errors.search_admin_phone.toString() }}
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div class="table-responsive">
                                                        <table class="table" v-if="searchStaffMembers.length > 0">
                                                            <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Name</th>
                                                                <th></th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr v-for="(user,index) in searchStaffMembers">
                                                                <td scope="row">{{ index + 1 }}</td>
                                                                <td>{{ user.name }}</td>
                                                                <td>
                                                                    <button class="btn btn-outline-secondary btn-sm"
                                                                            @click.prevent="chooseUser(user)">
                                                                        Choose
                                                                    </button>
                                                                </td>
                                                            </tr>

                                                            </tbody>
                                                        </table>
                                                        <div v-else>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="currentStep == 'searchmarket'">
                                                    <form action="">
                                                        <!--Label: Search Market, Attributes:search_market -->
                                                        <div class="input-group mb-3">
                                                            <input type="text" class="form-control"
                                                                   id="search_market" v-model="search_market"
                                                                   aria-describedby="search_market-help"
                                                                   :class="[errors.search_market ? 'is-invalid': '',!errors.search_market && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                                                   placeholder="Search Admin Market" required>
                                                            <button class="btn btn-outline-secondary" type="button"
                                                                    @click.prevent="searchMarket"
                                                                    id="button-addon1">
                                                                Search
                                                            </button>

                                                            <div class="invalid-feedback"
                                                                 v-if="errors.search_market">
                                                                {{ errors.search_market.toString() }}
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div class="table-responsive">
                                                        <table class="table" v-if="marketList.length > 0">
                                                            <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Name</th>
                                                                <th></th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr v-for="(market,index) in marketList">
                                                                <td scope="row">{{ index + 1 }}</td>
                                                                <td>{{ market.name }}</td>
                                                                <td>
                                                                    <button class="btn btn-outline-secondary btn-sm"
                                                                            @click.prevent="chooseMarket(market)">
                                                                        Choose
                                                                    </button>
                                                                </td>
                                                            </tr>

                                                            </tbody>
                                                        </table>
                                                        <div v-else>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="currentStep == 'summary'">
                                                    <table class="table">

                                                        <tbody>
                                                        <tr>
                                                            <th>Name:</th>
                                                            <td>
                                                                <div>
                                                                    {{ chosenUser.name }}
                                                                </div>
                                                                <div class="text-muted small">
                                                                    {{ chosenUser.phone }}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Market:</th>
                                                            <td>
                                                                {{ chosenMarket.name }}
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary"
                                                    data-bs-dismiss="modal">
                                                Close
                                            </button>
                                            <button type="button" class="btn btn-primary" :disabled="!saving"
                                                    @click.prevent="addToMarket">
                                                Add to Market
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Market Managed</th>
                                    <th>Date Added</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(staff,index) in staffUsers">
                                    <td scope="row">{{ index + 1 }}</td>
                                    <td>{{ staff.user?.name }}</td>
                                    <td>{{ staff.group?.name }}</td>
                                    <td>{{ formatDate(staff.created_at) }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
