<script setup>
import {ref} from "vue";
import Swal from "sweetalert2";

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
});

const errors = ref({});
const first_name = ref(props.user.first_name);
const last_name = ref(props.user.last_name);
const phone = ref(props.user.phone);
const email = ref(props.user.email);
const saving = ref(false);

function updateProfileDetails() {
    saving.value = true;
    axios.post('/apis/v4/editingProfile',{
        first_name: first_name.value,
        last_name: last_name.value,
        email: email.value
    }).then(response => {
        saving.value = false;
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Profile Information updated successfully',
        });
    }).catch(error => {
        errors.value = error.response.data.errors;
    }).then(() => {
        saving.value = false;
    });
}
</script>

<template>
    <div>
        <form action="">
            <div class="row g-3">
                <div class="col-12">
                    <!--Label: First Name, Attributes:first_name -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="first_name" v-model="first_name"
                               aria-describedby="first_name-help"
                               :class="[errors.first_name ? 'is-invalid': '',!errors.first_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               required>
                        <label for="first_name">First Name</label>
                        <div class="invalid-feedback" v-if="errors.first_name">
                            {{ errors.first_name.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <!--Label: Last Name, Attributes:last_name -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="last_name" v-model="last_name"
                               aria-describedby="last_name-help"
                               :class="[errors.last_name ? 'is-invalid': '',!errors.last_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               required>
                        <label for="last_name">Last Name</label>
                        <div class="invalid-feedback" v-if="errors.last_name">
                            {{ errors.last_name.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <!--Label: Email, Attributes:email -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="email" v-model="email"
                               aria-describedby="email-help"
                               :class="[errors.email ? 'is-invalid': '',!errors.email && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               required>
                        <label for="email">Email</label>

                        <div class="invalid-feedback" v-if="errors.email">
                            {{ errors.email.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <!--Label: Phone, Attributes:phone -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="phone" v-model="phone"
                               disabled
                               aria-describedby="phone-help"
                               :class="[errors.phone ? 'is-invalid': '',!errors.phone && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               required>
                        <label for="phone">Phone</label>

                        <div class="invalid-feedback" v-if="errors.phone">
                            {{ errors.phone.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-secondary" @click.prevent="updateProfileDetails">
                            Update Profile Details

                            <i class="fa fa-thin fa-save" v-if="!saving"></i>
                            <i class="fa fa-spin fa-spinner" v-else></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<style scoped>

</style>
