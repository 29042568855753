<script setup>
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;

const props = defineProps({
    value: String,
    title: String,
    tooltipText: String
})

</script>

<template>
    <div class="col">
        <div class="card radius-10">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <div>
                        <p class="mb-0 text-secondary">{{ props.title }}</p>
                        <h6 class="my-1">
                            {{ currency }}
                            {{ props.value }}
                        </h6>
                    </div>
                    <div class="text-secondary ms-auto">

                        <div class="tooltip-1">
                            <i class="fa fa-exclamation-circle"></i>
                            <span class="tooltip-text">{{ props.tooltipText }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.tooltip-1 {
    position: relative;
    display: inline-block;
}

.tooltip-1 .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #1c1a1a;
    color: #d5d2d2;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.tooltip-1:hover .tooltip-text {
    visibility: visible;
}
</style>
