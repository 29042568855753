<script setup>
import { onMounted, ref } from "vue";

const props = defineProps(["signups_url"]);

const passwordType = ref("password");
const stage = ref(1);
const token = ref("");
const email = ref("");
const password = ref("");
const errorMessage = ref("");
const markets = ref();
const selectedMarket = ref("");
const loading = ref(false);

function togglePasswordType() {
    if (passwordType.value === "text") {
        passwordType.value = "password";
    } else if (passwordType.value === "password") {
        passwordType.value = "text";
    }
}

function resetErrorMessage() {
    errorMessage.value = "";
}

function goToDashboard() {
    loading.value = true;

    let config = {
        headers: {
            Authorization: "Bearer " + token.value,
        },
    };

    axios
        .post(
            "/apis/v4/choose-market",
            {
                market: selectedMarket.value,
            },
            config
        )
        .then(() => {
            window.location.href = "/four";
        })
        .catch((err) => {
            console.log(err);
        });
}

function getMarkets() {
    let config = {
        headers: {
            Authorization: "Bearer " + token.value,
        },
    };
    axios
        .post("/apis/v4/my-markets", {}, config)
        .then((resp) => {
            markets.value = resp.data;
            if (Object.keys(resp.data).length === 1) {
                selectedMarket.value = resp.data[0].id;
                goToDashboard();
            }
        })
        .catch((err) => {
            console.log(err);
        });
}

function goToStage2() {
    loading.value = true;
    errorMessage.value = "";
    axios
        .post("/apis/v4/sanctum/token", {
            phone: email.value,
            password: password.value,
            device_name: "iPhone",
        })
        .then((resp) => {
            token.value = resp.data;
            getMarkets();
            stage.value = 2;
            loading.value = false;
        })
        .catch((err) => {
            loading.value = false;
            errorMessage.value = err?.response?.data?.message;
        });
}
</script>

<template>
    <div
        class="relative flex min-h-screen w-[100vw] flex-col bg-gradient-to-br from-[#52BDAA90] via-white to-[#54BE9690] antialiased"
    >
        <header class="w-full"></header>
        <main class="flex min-h-[90vh] w-full items-center justify-center">
            <div
                class="w-[90%] rounded-[1.25rem] bg-white p-[2rem] shadow-card lg:w-fit lg:min-w-[30%]"
                style="box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15)"
            >
                <form v-if="stage === 1">
                    <h3 class="font-medium text-[2rem] text-black">
                        Login to your account
                    </h3>
                    <p class="text-[1rem] font-[500] text-[#AAA]">
                        Login to your account
                    </p>
                    <div class="space-y-2 my-[2.5rem]">
                        <p
                            v-if="errorMessage"
                            class="mx-auto w-full text-center text-[1rem] font-[500] text-red-700"
                        >
                            {{ errorMessage }}
                        </p>
                        <div>
                            <input
                                class="font-body ring-offset-[#00803A] placeholder:font-body flex h-12 w-full rounded-[0.75rem] bg-[#FAFAFA] p-4 text-base placeholder:text-base placeholder:font-[500] placeholder:text-[#A0AEC0] focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-primary-base focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:border-[.5px] disabled:border-[#C4C4C4] disabled:opacity-50"
                                :class="[
                                    errorMessage
                                        ? '!border !border-red-500'
                                        : '',
                                ]"
                                placeholder="Phone number"
                                aria-invalid="false"
                                v-model="email"
                            />
                        </div>
                        <div class="relative">
                            <input
                                class="font-body placeholder:font-body h-12 w-full rounded-[0.75rem] bg-[#FAFAFA] p-4 text-base placeholder:text-base placeholder:font-[500] placeholder:text-[#A0AEC0] focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-primary-base focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:border-[.5px] disabled:border-[#C4C4C4] disabled:opacity-50"
                                placeholder="Password"
                                aria-invalid="false"
                                :type="passwordType"
                                v-model="password"
                                :class="[
                                    errorMessage
                                        ? '!border !border-red-500'
                                        : '',
                                ]"
                            />
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                class="cursor-pointer absolute top-1/2 right-4 -translate-y-1/2"
                                @click="togglePasswordType"
                            >
                                <path
                                    d="M3 3L21 21"
                                    stroke="#A0AEC0"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M10.584 10.5859C10.2087 10.961 9.99775 11.4697 9.99756 12.0002C9.99737 12.5308 10.2079 13.0397 10.583 13.4149C10.958 13.7902 11.4667 14.0012 11.9973 14.0013C12.5278 14.0015 13.0367 13.791 13.412 13.4159"
                                    stroke="#A0AEC0"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M9.363 5.36506C10.2204 5.11978 11.1082 4.9969 12 5.00006C16 5.00006 19.333 7.33306 22 12.0001C21.222 13.3611 20.388 14.5241 19.497 15.4881M17.357 17.3491C15.726 18.4491 13.942 19.0001 12 19.0001C8 19.0001 4.667 16.6671 2 12.0001C3.369 9.60506 4.913 7.82506 6.632 6.65906"
                                    stroke="#A0AEC0"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                    <div class="flex flex-col space-y-2">
                        <button
                            class="inline-flex items-center justify-center whitespace-nowrap rounded-[0.75rem] text-sm font-[700] ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#30946D] text-slate-50 hover:bg-[#30946D]/90 h-12 px-4 py-2 w-full"
                            type="button"
                            @click="goToStage2"
                            :disabled="loading || !email || !password"
                        >
                            <span v-if="loading">Loading...</span>
                            <span v-else>Log in</span>
                        </button>
                    </div>

                    <div class="mt-4 text-[1rem] font-[500] text-[#AAA]">
                        Don’t have an account?
                        <a
                            class="text-[#00803A] font-medium"
                            v-bind:href="props.signups_url"
                        >
                            Get Started
                        </a>
                    </div>
                    <a
                        class="text-[#00803A] font-medium"
                        href="/forgot-password"
                    >
                        Forgot your password?
                    </a>
                </form>
                <form v-else-if="stage === 2">
                    <h3 class="font-medium text-[2rem] text-black">
                        Select a market
                    </h3>
                    <p class="text-[1rem] font-[500] text-[#AAA]">
                        Pick a market to log into
                    </p>
                    <div class="space-y-2 my-[2.5rem]">
                        <div>
                            <select
                                class="appearance-none font-body ring-offset-[#00803A] placeholder:font-body flex h-12 w-full rounded-[0.75rem] bg-[#FAFAFA] px-4 text-base focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-primary-base focus-visible:ring-offset-2"
                                v-model="selectedMarket"
                            >
                                <option disabled value="">
                                    Select a market
                                </option>
                                <option
                                    v-for="market in markets"
                                    :key="market.id"
                                    :value="market.id"
                                >
                                    {{ market.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="flex flex-col space-y-2">
                        <button
                            class="inline-flex items-center justify-center whitespace-nowrap rounded-[0.75rem] text-sm font-[700] ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#30946D] text-slate-50 hover:bg-[#30946D]/90 h-12 px-4 py-2 w-full"
                            type="button"
                            :disabled="loading || !selectedMarket"
                            @click.prevent="goToDashboard"
                        >
                            <span v-if="loading">Loading...</span>
                            <span v-else>Continue</span>
                        </button>
                    </div>
                    <div class="mt-4 text-[1rem] font-[500] text-[#AAA]">
                        Don’t have an account?
                        <a
                            class="text-[#00803A] font-medium"
                            v-bind:href="props.signups_url"
                        >
                            Get Started
                        </a>
                    </div>
                    <a
                        class="text-[#00803A] font-medium"
                        href="/forgot-password"
                    >
                        Forgot your password?
                    </a>
                </form>
            </div>
        </main>
    </div>
</template>

<style scoped></style>
