<template>
  <div>
    <template>
      <template v-if="!showForm">
        <form action="/admin/sms/preview" method="POST" name="frmSMSSend">
          <input type="hidden" name="_token" :value="csrf_token">
          <div class="page-body">
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3">
                <p class="text-success"><u>Step 1: Choose Target Audience</u></p>
                <div>
                  <input v-model="flagselected" name="flag" type="radio" id="flag_none" value="none" checked @change="tick">
                  <label for="flag_none">Use Manual Recepient List</label>
                </div>
                <div>
                  <input v-model="flagselected" name="flag" type="radio" id="flag_all" value="all" @change="tick">
                  <label for="flag_all">Send to ALL Platform Users*</label>
                </div>
                <div v-for="flag in flags">
                  <input v-model="flagselected" name="flag" type="radio" :value="flag.id" :id="'flag_'+flag.id" @change="tick">
                  <label :for="'flag_'+flag.id">{{ flag.name }}</label>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3" v-if="flagselected=='none'">
                <p class="text-success"><u>Step 1b: Enter Recepient Numbers</u></p>
                <p>Paste a Comma-separated list of recepients here.<br/>(11-digit GSM numbers only)</p>
                <textarea v-model="recepeints" name="recepients" id="recepients" cols="30" rows="10"
                          class="form-control"
                          required>
                </textarea>
              </div>
              <div class="col-12 col-md-4 col-lg-6">
                <p class="text-success"><u>Step 2: Message Content</u></p>
                <p>Placeholders are replaced by actual content.
                  Available Placeholders (Case-Sensitive): <b>FNAME</b>, <b>MNAME</b>, <b>LNAME</b></p>
                <textarea v-model="message" name="message" id="message" cols="30" rows="5" class="form-control"></textarea>
                <hr />
                <p class="text-success"><u>Step 3: When to Send</u></p>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="date">Date</label>
                      <input type="date" class="form-control" id="date" v-model="date"
                             aria-describedby="date-help"
                             name="send_date"
                             :min="date_min"
                             :class="[errors.date ? 'is-invalid': '',!errors.date && Object.keys(errors).length > 1 ? 'is-valid': '']"
                             placeholder="Date" required>
                      <div class="invalid-feedback" v-if="errors.date">
                        {{ errors.date.toString() }}
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="time">Time</label>
                      <input type="time" class="form-control" id="time" v-model="time"
                             aria-describedby="time-help"
                             name="send_time"
                             :min="time_min"
                             :class="[errors.time ? 'is-invalid': '',!errors.time && Object.keys(errors).length > 1 ? 'is-valid': '']"
                             placeholder="Time" required>
                      <div class="invalid-feedback" v-if="errors.time">
                        {{ errors.time.toString() }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12" v-if="flagselected=='all'">
                      <div class="alert alert-info"><i class="fa fa-info"></i> Note: To send to ALL users, system needs an hour to prep.</div>
                  </div>
                </div>  
                
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <button type="submit" class="btn btn-success">
                  <span v-if="loading">
                    <i class="fa fa-spin fa-spinner"></i>
                    Saving ...
                  </span>
                  <span v-else>
                    Preview Now
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>

      </template>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: "index",
  props: {
    flags: {
      type: Array | Object,
      default: []
    },
  },
  data() {
    return {
      csrf_token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      flagselected: "none",
      errors: '',
      recepeints: '',
      showrecepients: false,
      message: '',
      messages: [],
      user_id: [],
      phone: [],
      time: moment().add(5, 'minutes').format('HH:mm'),
      time_min: moment().add(5, 'minutes').format('HH:mm'),
      time_min_long: moment().add(60, 'minutes').format('HH:mm'),
      date: moment().format('YYYY-MM-DD'),
      date_min: moment().format('YYYY-MM-DD'),
      previewData: '',
      showForm: false,
      successfullySentSMS: false,
      successfullySentSMSResponse: '',
      loading: false
    }
  },
  methods: {

    tick(){
      if(this.flagselected=='all'){
        this.time_min = moment().add(60, 'minutes').format('HH:mm');
      }else{
        this.time_min = moment().add(5, 'minutes').format('HH:mm');
      }
      this.time = this.time_min
    },

    previewSMS() {
      return;
      this.loading = true;
      axios.post('/admin/sms/preview', {
        recepients: this.recepeints,
        flag: this.flagselected,
        message: this.message,
        time: this.time,
        date: this.date
      }).then(resp => {
        this.loading = false;

        // this.showForm = true;
        // window.location.href = '/admin/sms/logs';
        // let Self = this;
        // resp.data.PreviewData.forEach(function (index, element) {
        //   Self.user_id[element] = index.user_id
        //   Self.phone[element] = index.recepient
        //   Self.messages[element] = index.message
        // });
        // this.previewData = resp.data.PreviewData
      }).catch()
          .finally(() => {
            this.loading = false;
          });
    },
    sendSMS() {
      axios.post('/admin/sms/send', {
        phone: this.phone,
        message: this.messages,
        user_id: this.user_id,
        time: this.time,
        date: this.date
      }).then(resp => {
        $('#schedulesms').modal('hide');
        this.successfullySentSMS = true;
        window.location.href = '/admin/sms/logs';
      }).catch(err => {

      });
    }
  },
}
</script>

<style scoped>

</style>