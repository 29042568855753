<template>
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-8">
                    <!--Label: Search for Seller, Attributes:seller_phone -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="seller_phone" v-model="seller_phone"
                               aria-describedby="seller_phone-help"
                               :class="[errors.seller_phone ? 'is-invalid': '',!errors.seller_phone && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="seller_phone" class="form-label">Search for Seller</label>

                        <div class="invalid-feedback" v-if="errors.seller_phone">
                            {{ errors.seller_phone.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-4 d-flex align-items-end align-self-center">
                    <button class="btn btn-outline-primary" @click="searchForSeller">
                        Search
                        <i class="fa fa-spin fa-spinner" v-if="searchingForSeller"></i>
                        <i class="fa fa-search" v-else></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3" v-if="initializeSearch">
        <div v-if="noSearchResults">
            <div class="alert alert-danger" role="alert">
                <strong>No Products were found for this Seller or Seller does not exist in the platform.</strong>
            </div>
        </div>
        <div v-else>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Product</th>
                                <th>Market</th>
                                <th>Price</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(product,index) in orderStore.products.value">
                                <td>{{ index + 1 }}</td>
                                <td>{{ product?.product?.name }}</td>
                                <td>{{ product?.market?.name }}</td>
                                <td>{{ appStore.currencySign }}{{ product?.amount }}</td>
                                <td>
                                    <button
                                        @click="selectProduct(product)"
                                        class="btn btn-sm btn-outline-secondary">
                                        Buy
                                        <i class="fa fa-cart-plus fa-duotone fa-sm"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script setup>
import {useOrderStore} from "../../../../../store/Admin/order";
import {useAppStore} from "../../../../../store/app";
import {ref} from "vue";

const orderStore = useOrderStore();
const appStore = useAppStore();

const emits = defineEmits(['updateStep']);
const searchForSeller = () => {
    searchingForSeller.value = true;
    initializeSearch.value = true;
    errors.value = {};

    if(!seller_phone.value){
        errors.value = {seller_phone: 'Seller phone is required'};
        searchingForSeller.value = false;
        initializeSearch.value = false;
        return;
    }

    axios.post('/ajx/search_products', {
        search: seller_phone.value
    }).then(resp => {
        if (resp.data.payload.products.length < 1) {
            noSearchResults.value = true;
            orderStore.resetState();
            return;
        }
        noSearchResults.value = false;
        orderStore.products.value = resp.data.payload.products;
        orderStore.seller.value = resp.data.user;
    }).finally(() => {
        searchingForSeller.value = false;
    })
};
const selectProduct = (product) => {
    orderStore.selectedProduct.value = product;
    emits('updateStep','chooseBuyer')
};

const errors = ref({});
const initializeSearch = ref(false);
const seller_phone = ref('');
// const seller_phone = ref('0791489172');
const noSearchResults = ref(false);
const searchingForSeller = ref(false);
</script>
