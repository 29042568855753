<script setup>
import {onMounted, ref} from "vue";
import Multiselect from 'vue-multiselect'
import iziToast from "izitoast"
import 'izitoast/dist/css/iziToast.min.css'

const errors = ref({});
const first_name = ref('');
const last_name = ref('');
const phone_number = ref('');
const email = ref('');
const roles = ref([]);
const role = ref(null);
const saving = ref(false);

onMounted(() => {
    getRoles();
});
const getRoles = () => {
    axios.get('/apis/v4/admin/rbac').then(resp => {
        resp.data.map(function (data) {
            roles.value.push({name: data.name, code: data.id})
        });
    });
}

function addUser() {
    saving.value = true;
    axios.post('/apis/v4/users', {
        'first_name': first_name.value,
        'last_name': last_name.value,
        'phone_number': phone_number.value,
        'email': email.value,
        'roles': role.value,
    }).then(resp => {
        saving.value = false;
        errors.value = [];
        iziToast.success({
            title: 'Admin User Added',
            position: 'topRight',
            timeout: 1000,
            icon: 'fa fa-solid fa-check',
            balloon: false,
            message: `Admin User has been added.`,
        })
    }).catch(err => {
        errors.value = err.response.data.errors;
        saving.value = false;
    });
}

</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="row g-3">
                <div class="col-md-12">
                    <!--Label: First Name, Attributes:first_name -->
                    <div class="form-floating">
                        <input id="first_name" v-model="first_name"
                               :class="[errors.first_name ? 'is-invalid': '',!errors.first_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               aria-describedby="first_name-help"
                               class="form-control"
                        >
                        <label for="first_name">First Name</label>
                        <div v-if="errors.first_name" class="invalid-feedback">
                            {{ errors.first_name.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <!--Label: Last Name, Attributes:last_name -->
                    <div class="form-floating">
                        <input id="last_name" v-model="last_name"
                               :class="[errors.last_name ? 'is-invalid': '',!errors.last_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               aria-describedby="last_name-help"
                               class="form-control"
                        >
                        <label for="last_name">Last Name</label>
                        <div v-if="errors.last_name" class="invalid-feedback">
                            {{ errors.last_name.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <!--Label: Email, Attributes:email -->
                    <div class="form-floating">
                        <input id="email" v-model="email"
                               :class="[errors.email ? 'is-invalid': '',!errors.email && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               aria-describedby="email-help"
                               class="form-control"
                        >
                        <label for="email">Email</label>
                        <div v-if="errors.email" class="invalid-feedback">
                            {{ errors.email.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <!--Label: Phone Number, Attributes:phone_number -->
                    <div class="form-floating">
                        <input id="phone_number" v-model="phone_number"
                               :class="[errors.phone_number ? 'is-invalid': '',!errors.phone_number && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               aria-describedby="phone_number-help"
                               class="form-control"
                        >
                        <label for="phone_number">Phone Number</label>

                        <div v-if="errors.phone_number" class="invalid-feedback">
                            {{ errors.phone_number.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <!--Label: dfsd, Attributes:adasd -->
                    <div class="form-group">
                        <multiselect
                            v-model="role"
                            :multiple="true"
                            :taggable="true"
                            track-by="name"
                            label="name"
                            placeholder="`Select Role & Permissions`"
                            tag-placeholder="Select Role & Permissions"
                            :options="roles">
                        </multiselect>
                        <p class="tw-text-red-500 tw-mt-1 tw-font-light" v-if="errors.roles"> {{ errors.roles.toString() }}</p>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-end">
                    <button :disabled="saving"
                            class="btn btn-outline-dark"
                            @click.prevent="addUser"
                    >
                        Add Admin User
                        <i v-if="!saving" class="fa-solid fa-user-plus"></i>
                        <i v-else class="fa fa-spin fa-spinner"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>

</style>
