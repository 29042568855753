<script setup>
    import Swal from "sweetalert2";
    import openConfirmation from "../../confirmation-reuse";

    const props = defineProps(['deleteUrl', 'deleteType']);
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-danger",
            cancelButton: "btn btn-secondary"
        },
    });

    function confirmationAction() {
        axios.delete(props.deleteUrl, { data: { type: props.deleteType } })
            .finally(() => {
                location.href = `/four/market/charges-rent-fees-and-taxes#${props.deleteType}`;
            });
    }

    function deleteChargeFee() {
        const message = `Are you sure you want to delete this ${props.deleteType}?`;
        openConfirmation(swalWithBootstrapButtons, confirmationAction, message)
    }
</script>

<template>
    <button
        class="btn btn-delete btn-outline-danger"
        id="deleteChargeButton"
        data-bs-target="#delete-confirmation"
        @click.prevent="deleteChargeFee"
    >
        <i class="fa fa-light fa-trash-clock fa-sm"></i>
        <strong>Delete {{ props.deleteType }}</strong>
    </button>
</template>

<style scoped>
.btn-delete:hover {
    background-color: #dc3545 !important;
}
</style>
