<template>
    <div>
        <form>
            <div class="row">
                <div class="alert alert-info" role="alert">
                    Please provide an account number to receive payments on individual transactions directly to you. (This is not an account for the market.)
                </div>
            </div>
            <div class="row gap-3">
                <div class="col-12">
                    <div class="form-floating">
                        <select
                            :disabled="review"
                            class="form-select"
                            id="bankSelect"
                            v-model="selectedBank"
                            :class="[errors.bank ? 'is-invalid' : '', !errors.bank && Object.keys(errors).length > 1 ? 'is-valid' : '']"
                        >
                            <option v-for="bank in banks" :value="bank.id">{{ bank.name }}</option>
                        </select>
                        <label for="bankSelect">Select a Bank</label>
                        <div class="invalid-feedback" v-if="errors.bank">
                            {{ errors.bank.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-floating">
                        <input
                            :disabled="review"
                            type="text"
                            class="form-control"
                            id="accountNumber"
                            v-model="accountNumber"
                            :class="[errors.account_number ? 'is-invalid' : '', !errors.account_number && Object.keys(errors).length > 1 ? 'is-valid' : '']"
                        >
                        <label for="accountNumber">Account Number</label>
                        <div class="invalid-feedback" v-if="errors.account_number">
                            {{ errors.account_number.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-floating">
                        <input
                            disabled
                            type="text"
                            class="form-control"
                            id="accountName"
                            v-model="accountName"
                            :class="[errors.account_name ? 'is-invalid' : '', !errors.account_name && Object.keys(errors).length > 1 ? 'is-valid' : '']"
                        >
                        <label for="accountName">Account Name</label>
                        <div class="invalid-feedback" v-if="errors.account_name">
                            {{ errors.account_name.toString() }}
                        </div>
                    </div>
                </div>
                <button v-if="review" type="button" class="btn btn-success" @click.prevent="submitForm">
                    Submit
                    <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                </button>
                <button v-else type="button" class="btn btn-primary" @click.prevent="saveDetails">
                    Find Account
                    <i class="fa fa-spin fa-spinner" v-if="finding"></i>
                </button>
            </div>
        </form>
    </div>
</template>
<script setup>
import axios from 'axios';
import Swal from 'sweetalert2';
import {onMounted, ref} from "vue";

const props = defineProps(["user"]);
const banks = ref(['KCB', 'Equity', 'Cooperative', 'Diamond Trust', 'Stanbic', 'Barclays', 'Standard Chartered', 'CFC Stanbic', 'NIC', 'Commercial Bank of Africa']);
const selectedBank = ref(null);
const accountNumber = ref(null);
const accountName = ref(null);
const saving = ref(false);
const finding = ref(false);
const accountFound = ref(false);
const review = ref(false);
const errors = ref({});

onMounted(() => {
    accountName.value = props.user.account_name
    accountNumber.value = props.user.account_number
    selectedBank.value = props.user.bank_id
    getBanks();
})
const getBanks = () => {
    axios.get('/apis/v4/saas/vfd/banks').then((response) => {
        banks.value = response.data.data
        banks.value.forEach((bank) => {
            let hasPaystackCode = !!bank?.metadata?.mapped_codes?.paystack ?? false;
            if(!hasPaystackCode){
                banks.value.splice(banks.value.indexOf(bank), 1)
            }
        })
    });
}

const saveDetails = () => {
    errors.value = {};
    finding.value = true;

    axios
        .post("/apis/v4/bank-account-info", {
            bank: selectedBank.value,
            account_number: accountNumber.value,
            amount_to_withdraw: 0,
            bank_type: 'paystack',
        })
        .then((resp) => {
            finding.value = false;
            let accName = resp.data?.accountDetails?.account_name ?? null;

            if(accName){
                accountFound.value = true;
                accountName.value = accName;

                errors.value = {};
                review.value = true;
            }else{
                errors.value = {account_number: 'Account not found', account_name: ' '};
                accountName.value = 'ACCOUNT NOT FOUND';
            }
        })
        .catch((error) => {
            finding.value = false;
            errors.value = error?.response?.data?.errors ?? '';
        })
}
const submitForm = () => {
    saving.value = true;
    axios.put('/apis/v4/profile/banks', {
        bank: selectedBank.value,
        account_name: accountName.value,
        account_number: accountNumber.value
    })
        .then(() => {
            saving.value = false;
            Swal.fire('Success', 'Bank account added successfully', 'success');
            location.href = '/four/profile';
        })
        .catch((error) => {
            saving.value = false;
            errors.value = error?.response?.data?.errors ?? '';
            if(!errors.value){
                Swal.fire('Error', 'Something went wrong', 'error');
            }
        });
}
</script>
