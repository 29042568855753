<script setup>
import UserIcons from "../../../Verification/UserIcons.vue";

const props = defineProps(['user', 'market'])

$(document).ready(function() {
    $('[data-toggle="tooltip"]').tooltip();
});


const user = props.user;
const market = props.market;
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
</script>

<template>
    <div class="card custom-card-coamana">
        <div class="card-body">
            <div class="row">
                <div class="d-flex flex-column align-content-center align-items-center justify-content-center">
                    <div class="col-md-12">
                        <template v-if="user.user_avatar">
                            <div
                                class="d-flex flex-column align-content-center align-items-center justify-content-center">
                                <img :src=" user.user_avatar" class="img-fluid img-coamana"
                                     alt="user avatar"/>
                            </div>
                        </template>
                        <template v-else>
                            <div
                                class="d-flex flex-column align-content-center align-items-center justify-content-center">
                                <img src="/v4/avatar.png" class="user-img" alt="user avatar">
                            </div>
                        </template>
                    </div>
                    <div class="mt-3">
                        <div class="d-flex">
                            <div>
                                <h6 class="tw-antialiased">
                                    <strong>{{ user?.name }} </strong>

                                </h6>
                            </div>
                            <div class="tw-ml-2">
                                <UserIcons
                                    :user="user"
                                ></UserIcons>
                            </div>
                        </div>

                    </div>
                    <div>
                        <a href="/four/my-profile" class="btn btn-outline-secondary btn-sm">
                            Edit
                        </a>
                    </div>
                </div>
            </div>
            <div class="row mt-0 pt-0">
                <table class="table mt-0 pt-0">
                    <tbody>
                    <tr class="mt-0 pt-0">
                        <th></th>
                        <td></td>
                    </tr>
                    <tr>
                        <th>Phone Number</th>
                        <td>{{ user?.formattedphone ?? '—' }}</td>
                    </tr>
                    <tr>
                        <th>Email</th>
                        <td>{{ user?.email ?? '—' }}</td>
                    </tr>
                    <tr>
                        <th>Gender</th>
                        <td>{{ user?.gender ?? '—' }}</td>
                    </tr>
                    <tr>
                        <th>Markets</th>
                        <td>{{ market?.name ?? '—' }}</td>
                    </tr>

                    <tr>
                        <th>BNPL</th>
                        <td> {{ currency }} {{ user?.agent_limit?.agent_limit ?? 0 }}</td>
                    </tr>
                    <tr>
                        <th>Credit Score</th>
                        <td>{{ user.credit_score ?? '—' }}</td>
                    </tr>
<!--                    <tr v-if="Object.keys(user.credit_scoring).length > 1">-->
<!--                        <th>-->
<!--                            Credit Score Breakdown-->
<!--                            <span class="text-muted" data-toggle="tooltip" data-placement="top"-->
<!--                                  title="baseScore * (assetScore + statementScore + paymentScore + genderScore + ageScore)">-->
<!--                                <i class="fas fa-info-circle"></i>-->
<!--                            </span>-->

<!--                            &lt;!&ndash;                            <span class="text-muted small">&ndash;&gt;-->
<!--                            &lt;!&ndash;&ndash;&gt;-->
<!--                            &lt;!&ndash;                            </span>&ndash;&gt;-->
<!--                        </th>-->
<!--                        <td>-->
<!--                            <table>-->
<!--                                <tbody>-->
<!--                                <tr>-->
<!--                                    <td>Age Score</td>-->
<!--                                    <td>{{ user.credit_scoring?.ageScore?.score }}</td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>Asset Score</td>-->
<!--                                    <td>{{ user.credit_scoring?.assetscore?.score }}</td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>Base Score (Clearance)</td>-->
<!--                                    <td>{{ user.credit_scoring?.baseScore?.clearance?.score }}</td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>Base Score (Inclusion)</td>-->
<!--                                    <td>{{ user.credit_scoring?.baseScore?.inclusion?.score }}</td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>Gender Score</td>-->
<!--                                    <td>{{ user.credit_scoring?.genderscore?.score }}</td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>Payment Score</td>-->
<!--                                    <td>{{ user.credit_scoring?.paymentscore?.score }}</td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>Statement Score</td>-->
<!--                                    <td>{{ user.credit_scoring?.statementscore?.score }}</td>-->
<!--                                </tr>-->
<!--                                </tbody>-->
<!--                            </table>-->
<!--                        </td>-->
<!--                    </tr>-->
                    <tr>
                        <th>Verification Status</th>
                        <td>N/A</td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<style scoped>
.custom-card-coamana {
    border-radius: 0.25rem;
    border: 0.2px solid #1d242e;
    box-shadow: none;
}

.img-coamana {
    width: 75px;
    height: 75px;
    border-radius: 50% !important;
    background-position: center;
    background-size: cover;
}

.rounded-thumbnail img {
}
</style>
