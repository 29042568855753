<script setup>
import {ref} from "vue";

const props = defineProps(['members']);
const user = ref({});

function setUser(userData) {
  user.value = userData;
}

function approve(approval) {
  if (user.value.bvn == '' && user.value.national_id == '') {
    alert("In order to your market members to buy and sell on credit, please have them complete level 1 profile information on their account.");
    return;
  }
  axios.post('/apis/v4/approval',{
    'user_id': user.value?.pivot?.id,
    'toggle': approval
  }).then(()=>{
    location.reload();
  });
}
</script>

<template>
  <div>
    <table class="table table-bordered table-sm">
      <thead>
      <tr>
        <th>#</th>
        <th>Name</th>
        <th>Member Role</th>
        <th>Phone</th>
        <th>Black Listed</th>
        <th>Approved</th>
        <th>Approved for Credit</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody class="members">
      <tr v-for="(member,index) in members">
        <td>{{ index + 1 }}</td>
        <td scope="row">
          {{ member.name }}

         <div>
<!--  TODO            -->
<!--           <span class="badge rounded-pill bg-danger">Defaulting</span>-->
         </div>
        </td>
        <td>
          {{ member.role }}
        </td>
        <td>
          {{ member.phone }}
        </td>
        <td>
        <span class="badge badge-pill bg-danger" v-if="member.pivot.black_listed == 1">
                                            Black Listed
                                        </span>
          <span class="badge badge-pill bg-success" v-else>
                                            Not Black Listed
                                            <i class="fa  fa-badge-check fa-fw"></i>
                                        </span>
        </td>
        <td>
        <span class="badge badge-pill bg-secondary" v-if="member.pivot.approved == 0">
                                            Unapproved
                                        </span>
          <span class="badge badge-pill bg-success" v-else>
                                            Approved
                                            <i class="fa fa-check-circle"></i>
                                        </span>
        </td>
        <td>

        <span class="badge badge-pill bg-success" v-if="member.pivot.approved_for_credit_purchase == 1">
                                            Approved for Credit Purchase
                                            <i class="fa fa-check-circle"></i>
                                        </span>
          <span class="badge badge-pill bg-secondary" v-else>
                                            Unapproved for Credit Purchase
                                        </span>
        </td>
        <td>
          <button class="btn btn-outline-primary btn-sm"
                  @click="setUser(member)"
                  type="button"
                  title="edituser"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasWithBothOptions"
                  aria-controls="offcanvasWithBothOptions">
            View / Edit
          </button>

        </td>
      </tr>
      </tbody>
    </table>
    <div class="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1"
         id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasWithBothOptionsLabel">
          Managing <strong>{{ user.name }}</strong>
        </h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <div>
          <tr>
            <th scope="row">Name:</th>
            <td>{{ user.name }}</td>
          </tr>
          <tr>
            <th scope="row">Phone:</th>
            <td>{{ user.phone }}</td>
          </tr>
          <tr v-if="user.email">
            <th scope="row">Email:</th>
            <td>{{ user.email }}</td>
          </tr>
        </div>
        <div class="border border-3 p-3 mt-3 rounded">
          <table class="table table-sm table-striped table-inverse table-bordered">
            <tbody class="thead-default ">
            <tr>
              <th scope="row">
                <div>
                  Approved For Credit
                </div>
                <div>
                  <template v-if="user?.pivot?.approved_for_credit_purchase == 1">
                    <span class="badge rounded-pill bg-primary">Approved For Credit</span>
                  </template>
                  <template v-else>
                    <span class="badge rounded-pill bg-danger">Not Approved for Credit</span>
                  </template>
                </div>
              </th>
              <td>
                <template v-if="user?.pivot?.approved == 1 && (user?.pivot?.black_listed == 0 || user?.pivot?.black_listed == null)">
                  <template v-if="user?.pivot?.approved_for_credit_purchase == 1">
                    <button id="unapproved_for_credit_purchase" class="btn btn-sm btn-outline-secondary"
                            @click="approve('approved_for_credit_purchase')">
                      Un-Approve
                    </button>
                  </template>
                  <template v-else>
                    <button id="approved_for_credit_purchase" class="btn btn-sm btn-outline-secondary"
                            @click="approve('approved_for_credit_purchase')">
                      Approve
                    </button>
                  </template>
                </template>

               <template v-else>
                 This member cannot be approved for credit.
               </template>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <div>
                  Approved
                </div>
                <div>
                  <template v-if="user?.pivot?.approved == 1">
                    <span class="badge rounded-pill bg-primary">Approved</span>
                  </template>
                  <template v-else>
                    <span class="badge rounded-pill bg-danger">Not Approved</span>
                  </template>
                </div>
              </th>
              <td>
                <template v-if="user?.pivot?.approved == 1">
                  <button id="unapprove" class="btn btn-sm btn-outline-secondary" @click="approve('approved')">
                    Un-Approve
                  </button>
                </template>
                <template v-else>
                  <button id="approve"
                          title="approveuser"
                          class="btn btn-sm btn-outline-secondary" @click="approve('approved')">
                    Approve
                  </button>
                </template>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <div>
                  BlackListed
                </div>

                <div>
                  <template v-if="user?.pivot?.black_listed == 1">
                    <span class="badge rounded-pill bg-danger">Black Listed</span>
                  </template>
                  <template v-else>
                    <span class="badge rounded-pill bg-success">Not Black Listed</span>
                  </template>
                </div>
              </th>
              <td>
                <template v-if="user?.pivot?.black_listed == 1">
                  <button id="unblacklist" class="btn btn-sm btn-outline-secondary" @click="approve('black_listed')">
                    Un-BlackList
                  </button>
                </template>
                <template v-else>
                  <button id="blacklist" class="btn btn-sm btn-outline-secondary" @click="approve('black_listed')">
                    BlackList
                  </button>
                </template>

              </td>
            </tr>
            </tbody>
          </table>
        </div>
<!--        TODO: Ensure this works well-->
        <div class=" mt-3 rounded">
          <div class="alert alert-success" role="alert">
            <h4 class="alert-heading">No delayed Payments </h4>
            <p>This member has no delayed payments.</p>
            <hr>
            <p class="mb-0">
              You will be notified if any of their orders becomes overdue.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>