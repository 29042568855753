<script setup>
import ComplianceProfile from "./ComplianceProfile.vue";
import ReferralCodeCompliance from "./ReferralCodeCompliance.vue";
import Verification from "./Verifications/Index.vue";
import {useAppStore} from "../../../../../store/app";

const props = defineProps([
    'user',
    'bnpl',
    'market',
    'verifications',
    'subscription_plan',
    'subscriptions_url',
    'token',
    'virtual_bank_account'
]);

const user = props.user;
const market = props.market;

const app = useAppStore();

const alreadyUpgraded =
    props.subscription_plan?.status === "active" && props.subscription_plan?.payment_status === "paid";
</script>

<template>
    <div class="row">
        <div class="col-md-6 order-2 order-md-1">
            <ComplianceProfile :user="user" :market="market"></ComplianceProfile>
            <ReferralCodeCompliance :user="user"></ReferralCodeCompliance>
        </div>
        <div class="col-md-6 order-1 order-md-2 mb-4">
            <template v-if="alreadyUpgraded">
                <Verification
                    :verifications="props.verifications"
                    :user="user"
                    :virtual_bank_account="props.virtual_bank_account"
                    :market="props.market" />
            </template>
            <template v-else>
                <div class="card mb-3" style="border-radius: 0.25rem; box-shadow: none">
                    <div class="card-body">
                        <h4 class="text-success">Unlock Full Verification: Upgrade Your Market</h4>
                        <p style="color: #283342">
                            You market is currently on the free plan. To get your personal verification, your market
                            needs to be on the
                            <span style="color: #4b9d30">value builder monthly</span>
                            or
                            <span style="color: #4b9d30">value builder yearly plan</span>
                            . Upgrade today to get trusted by vendors
                        </p>
                        <template v-if="app.country == 'ke'">
                            <a
                                :href="`${subscriptions_url}/upgrade?token=${token}&market_id=${market.id}`"
                                target="_blank"
                                class="btn btn-success"
                                rel="noreferrer noopener">
                                <button class="btn btn-success">Upgrade My Market</button>
                            </a>
                        </template>
                       <template v-else>
                           <a
                               :href="`${subscriptions_url}/upgrade?token=${token}&market_id=${market.id}`"
                               target="_blank"
                               class="btn btn-success"
                               rel="noreferrer noopener">
                               <button class="btn btn-success">Upgrade My Market</button>
                           </a>
                       </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<style scoped></style>
