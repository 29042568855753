<script setup>
import {ref, watch} from "vue";
import UserIcons from "../../v4/Verification/UserIcons.vue";
import {useMakeOrderStore} from "../../../store/makeorder";
import Swal from "sweetalert2";

let emits = defineEmits([
    "productSeller",
    "productChosen",
    "loggedInUserUpdate",
]);

const orderStore = useMakeOrderStore();
const searchingMember = ref(false);
const searchmember = ref(null);
const marketMembers = ref(props.members);
const props = defineProps(["borrowinglimit_clear", "members", "authUser"]);
const currentPageIs = ref(true);
const showResults = ref(false);
const forMarketMember = ref(false);
const searching = ref(false);
const search = ref("");
const selectedProduct = ref("");
const results = ref("");
const errors = ref({});
const member = ref("");
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const searchedUser = ref("");
const disableProceedBtn = ref(false);
const choosingMember = ref(null);

watch(search, function (newValue, oldValue) {
    disableProceedBtn.value = false;
});

const searchMember = () => {
    searchingMember.value = true;
    errors.value = {};

    axios.post('/apis/v4/search-member', {
        phone: searchmember.value
    }).then((resp) => {
        marketMembers.value = resp.data;
    }).catch(err => {
        errors.value = err.response.data.errors;
    }).finally(() => {
        searchingMember.value = false;
    });
}

function gotoNextStep() {
    currentPageIs.value = false;
}

function validatePhone(phone) {

    if (phone.length < 1) {
        errors.value = {search: "Please enter a valid phone number"};
        return false;
    }

    if (phone === props.authUser.phone) {
        errors.value = {search: "You can't place other to yourself"};
        return false;
    }

    return true;
}

function searchPhoneNumber() {
    if (!validatePhone(search.value)) {
        return;
    }

    errors.value = [];
    searching.value = true;
    disableProceedBtn.value = true;

    axios
        .post("/ajx/search_products", {
            search: search.value,
        })
        .then((res) => {
            searching.value = false;
            searchedUser.value = res.data.user;
            showResults.value = true;
            results.value = res.data.payload;
            // console.log(">>: ", res.data);
        });
}

function setSellerAndChosenProduct(product) {
    let myModalEl = document.querySelector("#proceedPurchaseModal");
    let modal = myModalEl ? bootstrap.Modal.getOrCreateInstance(myModalEl) : null;

    emits("productSeller", product.member);
    emits("productChosen", product);

    modal?.hide();
}

function chooseProductForModal(product) {
    let myModalEl = document.querySelector("#proceedPurchaseModal");
    let modal = myModalEl ? bootstrap.Modal.getOrCreateInstance(myModalEl) : null;
    modal?.toggle();

    selectedProduct.value = product;
    orderStore.selectedProduct = product;
    orderStore.seller = searchedUser.value;
}

function changeUser() {
    emits("loggedInUserUpdate", member.value);
}

async function chooseMember(user, index) {
    choosingMember.value = index;
    member.value = user.id;
    changeUser();
    choosingMember.value = null;
    orderStore.buyer = user;
    orderStore.sendOtpTo = user.id;
}

function notifySeller() {

    let message = `Hello ${searchedUser.value.name}, ${props.authUser.name} `;
    message += `is trying to purchase products from you but you have no products on Amana Market, `;
    message += `visit https://amanamarket.ng today and add products so you can sell and earn today.`;

    axios.post('/apis/v4/saas/sms/send', {
        phone: searchedUser.value.phone,
        message: message
    }).then((resp) => {
        Swal.fire({
            title: "<h5>Notification Sent</h5>",
            text: "The seller has been notified of your intention to buy",
            icon: "success",
            position: "center",
        }).then(() => {
            location.reload()
        });
    }).catch(err => {
        Swal.fire({
            position: "center",
            icon: "error",
            title: "<h5>Error while notifying seller.</h5>",
        }).then(() => {
            location.reload()
        });
    })
}

function updateSearch(data, products) {
    searching.value = false;
    searchedUser.value = products.member;
    showResults.value = true;
    search.value = data;
    results.value = {
        blacklisted: false,
        bnpl_balance: "0.00",
        credit_score: 15,
        products: [products],
    };
    // chooseProductForModal(data);
    //
    // window.addEventListener("DOMContentLoaded", () => {
    //     let button = document.getElementById(
    //         "blacklistedandassortedPopUpButton0"
    //     );
    //     button.click();
    // });
}

defineExpose({
    updateSearch,
});
</script>

<template>
    <div @keyup.prevent.enter="searchPhoneNumber">
        <div class="col-12">
            <div class="border-3 border p-4 rounded">
                <div class="input-group mb-3">
                    <input
                        type="text"
                        v-model="search"
                        :class="[
                            errors.search ? 'is-invalid' : '',
                            !errors.search && Object.keys(errors).length > 1
                                ? 'is-valid'
                                : '',
                        ]"
                        class="form-control"
                        id="searchforseler"
                        placeholder="Phone Number of Recipient"
                        aria-label="Phone Number of Recipient"
                        aria-describedby="button-addon2"
                    />
                    <button
                        class="btn btn-primary"
                        style="color: white; background-color: #00ab4d;border-color: #00ab4d;"
                        type="button"
                        id="button-addon2"
                        @click.prevent="searchPhoneNumber"
                        :disabled="disableProceedBtn"
                    >
                        <strong>Proceed</strong>
                        <i class="fa fa-spin fa-spinner" v-if="searching"></i>
                    </button>
                    <div class="invalid-feedback" v-if="errors.search">
                        {{ errors.search.toString() }}
                    </div>
                </div>
            </div>
            <div class="col-12" v-if="showResults">
                <template v-if="!searchedUser">
                    <div class="alert alert-danger mt-2" role="alert">
                        <strong>This number is not assigned to a user on the platform, please confirm the phone
                            number.</strong>
                    </div>
                </template>
                <template v-else-if="Object.keys(results.products).length < 1">
                    <div
                        class="alert mt-2"
                        role="alert"
                        :style="{color: '#ff7900', border: '1px solid #ff7900'}"
                    >
                        This user has not added a product. Please notify seller of intention to buy.
                    </div>
                    <button
                        :style="{color: '#ff7900 !important'}"
                        class="btn warn-button-border"
                        @click.prevent="notifySeller"
                    >
                        Notify Seller
                    </button>
                </template>
                <template v-else>
                    <div
                        class="row m-1 d-none d-sm-none d-xs-none d-md-block"
                        v-for="result in results.products"
                        :key="result.products"
                    >
                        <div class="d-flex flex-row products-services-card py-2 px-4 my-2">
                            <div class="col-2 d-flex align-items-center">
                                <img width="100" :src="result.product_image_paths"/>
                            </div>
                            <div class="d-flex flex-column col-8">
                                <div>
                                    <strong>{{ result.product.name }}</strong>
                                    {{ result.measurement_unit.name }}
                                </div>
                                <div>
                                    <strong>Listed by</strong>:
                                    {{ result.member?.name }}
                                    <span
                                        v-if="typeof result.member?.verifications?.metadata?.failed ==='object' &&
                                            Object.keys(result.member?.verifications?.metadata?.failed).length > 0"
                                    >
                                        <i class="fa-solid fa-face-angry fa-lg" style="color: #6c757d"></i>
                                    </span>
                                    <span v-if="!result.member?.verifications">
                                        <i class="fa-solid fa-face-meh-blank fa-lg" style="color: #6c757d"></i>
                                    </span>
                                    <span
                                        v-if="typeof result.member?.verifications?.metadata?.failed ==='object' &&
                                            Object.keys(result.member?.verifications?.metadata?.failed).length < 0"
                                    >
                                        <i class="fa-solid fa-face-smile fa-lg" style="color: #6c757d"></i>
                                    </span>
                                    in {{ result.market?.name }} | {{ result.updated_at }}
                                </div>
                                <br/>
                                <div>
                                    <strong>Phone Number </strong>:
                                    <span v-if="authUser.verification_status ='verified'">{{ result?.member?.phone ?? "" }}</span>
                                    <span v-else>{{ result?.member?.formattedphone ?? ""  }}</span>
                                </div>
                                <div>
                                    <strong>Last Sold at {{ result?.market?.name }}</strong>:
                                    {{ currency }}{{ result.last_sold_at }}: 1 x{{ result.measurement_unit.name }} |
                                    {{ result.updated_at }}
                                </div>
                                <div>
                                    <strong>{{ result?.market?.name }} Recommended Price</strong>:
                                    {{ currency }}{{ result.last_sold_at }}: 1 x{{ result.measurement_unit.name }} |
                                    {{ result.updated_at }}
                                </div>
                                <div>
                                    <strong>Market</strong>:
                                    {{ result.market?.name }} | {{ result.market?.address ?? ' ' }}, {{ result.market?.l_g_a?.name}} , {{ result.market?.geo_state?.name}}                                
                                </div>
                            </div>
                            <div class="d-flex flex-column col-2">
                                <div>
                                    {{ currency }}{{ result.amount.toLocaleString() }}
                                </div>
                                <div
                                    style="cursor: pointer"
                                    class="success-text"
                                    id="proceedPurchaseModalButton"
                                    @click="chooseProductForModal(result)"
                                    data-bs-toggle="modal"
                                    data-bs-target="#proceedPurchaseModal"
                                >
                                    <strong>Buy</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Card for Mobile -->
                    <div class="row m-1" v-for="result in results.products" :key="result.products">
                        <div class="products-services-card py-2 px-4 my-2 d-md-none d-lg-none d-xl-none">
                            <div class="col-md-2">
                                <img width="100" :src="result.product_image_paths"/>
                            </div>
                            <div class="col-md-8">
                                <div>
                                    <strong>{{ result.product.name }}</strong>
                                    {{ result.measurement_unit.name }}
                                </div>
                                <div>
                                    <strong>Listed by</strong>: {{ result.member?.name }}
                                    <span v-if="result.member?.verification ==='verified'">
                                        <i class="fa-solid fa-face-angry fa-lg" style="color: #6c757d"></i>
                                    </span>
                                    <span v-if="result.member?.verification ==='verified'">
                                        <i class="fa-solid fa-face-meh-blank fa-lg" style="color: #6c757d"></i>
                                    </span>
                                    <span v-if="result.member?.verification ==='verified'">
                                        <i class="fa-solid fa-face-smile fa-lg" style="color: #6c757d"></i>
                                    </span>
                                    in {{ result.market?.name }} | {{ result.updated_at }}
                                </div>
                                <br/>
                                <div>
                                    <strong>Last Sold at {{ result?.market?.name }}</strong>:
                                    {{ currency }}{{ result.last_sold_at }}: 1 x{{ result.measurement_unit.name }} |
                                    {{ result.updated_at }}
                                </div>
                                <div>
                                    <strong>{{ result?.market?.name }} Recommended Price</strong>:
                                    {{ currency }}{{ result.last_sold_at }}: 1 x{{ result.measurement_unit.name }} |
                                    {{ result.updated_at }}
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="strong leading-3">
                                    <strong>{{ currency }}{{ result.amount.toLocaleString() }}</strong>
                                </div>
                                <div
                                    style="cursor: pointer"
                                    class="success-text text-center"
                                    id="proceedPurchaseModalButton"
                                    @click="chooseProductForModal(result)"
                                    data-bs-toggle="modal"
                                    data-bs-target="#proceedPurchaseModal"
                                >
                                    <strong>Buy</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="">
                <div
                    class="modal fade"
                    id="proceedPurchaseModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="proceedPurchaseModal"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-md" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="sub-header-font">Proceed with Purchase</h4>
                                <button
                                    type="button"
                                    class="btn-close warn-text"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div class="modal-body">
                                <template v-if="currentPageIs">
                                    <div v-if="selectedProduct?.blacklisted">
                                        <div class="alert alert-danger" role="alert">
                                            <h4 class="sub-header-font">Suspended or blacklisted seller:</h4>
                                            <p>
                                                You cannot make a purchase from this seller.
                                                - This seller was blacklisted/suspended by x market.
                                            </p>
                                            <hr/>
                                            <p class="mb-0"></p>
                                        </div>
                                    </div>
                                    <div v-else-if="selectedProduct?.member?.verification_status !== 'verified'">
                                        <div class="alert alert-warning" role="alert">
                                            <h4 class="sub-header-font">Unverified seller</h4>
                                            <p>
                                                This is an unverified seller, we recommend you pay
                                                at pick-up of goods or service. Note that you will be
                                                taking full responsibility for this purchase.
                                            </p>
                                            <hr/>
                                            <p class="mb-0"></p>
                                        </div>
                                    </div>
                                    <div v-else-if="selectedProduct?.member?.verification_status === 'verified'">
                                        <div class="alert alert-success" role="alert">
                                            <h4 class="sub-header-font">Verified seller</h4>
                                            <p>
                                                Based on our assessment, this seller can supplier good up to
                                                {{ currency }}{{ results.bnpl_balance.toLocaleString() }}
                                            </p>
                                            <hr/>
                                            <p class="mb-0"></p>
                                        </div>
                                    </div>
                                    <div class="alert alert-warning" role="alert" v-else>
                                        <h4 class="sub-header-font">Unverified seller</h4>
                                        <p>
                                            This is an unverified seller, we recommend you
                                            pay at pick-up of goods or service. Note that
                                            you will be taking full responsibility for this purchase.
                                        </p>
                                        <hr/>
                                        <p class="mb-0"></p>
                                    </div>
                                </template>
                                <template v-else>
                                    <div>
                                        <div class="alert alert-success" role="alert">
                                            <strong>
                                                You are a market leader and you can purchase goods and
                                                services on behalf of your users.
                                            </strong>

                                            <div class="row mt-3">
                                                <div class="col-md-6">
                                                    <a href="">
                                                        <div class="card">
                                                            <div
                                                                class="card-body text-black"
                                                                :class="{'active-card-purchase text-white': !forMarketMember}"
                                                                @click.prevent="forMarketMember = false"
                                                            >
                                                                For you
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div class="col-md-6">
                                                    <a href="" @click.prevent="forMarketMember = true">
                                                        <div class="card">
                                                            <div class="card-body"
                                                                 :class="{'active-card-purchase text-white': forMarketMember}"
                                                            >
                                                                For Market Member
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div class="col-md-6"></div>
                                            </div>
                                            <div class="row g-3" v-if="forMarketMember">
                                                <form action="">
                                                    <div class="col-12">
                                                        <div class="card text-white tw-accent-gray-300">
                                                            <div class="card-body">
                                                                <!--Label: Search Member, Attributes:searchmember -->
                                                                <div class="form-group">
                                                                    <div class="input-group mb-3">
                                                                        <input
                                                                            placeholder="Search Member by Phone Number"
                                                                            type="text" class="form-control"
                                                                            id="searchmember"
                                                                            v-model="searchmember"
                                                                            aria-describedby="searchmember-help"
                                                                            :class="[errors.phone ? 'is-invalid': '',!errors.phone && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                                                        >
                                                                        <button @click="searchMember"
                                                                                class="btn btn-outline-secondary"
                                                                                type="button" id="button-addon1">
                                                                            Search
                                                                            <i class="fa fa-spin fa-spinner"
                                                                               v-if="searchingMember"></i>
                                                                            <i class="fa fa-search" v-else></i>
                                                                        </button>
                                                                        <div class="invalid-feedback"
                                                                             v-if="errors.phone">
                                                                            {{ errors.phone.toString() }}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 tw-mt-6">
                                                        <div class="card">
                                                            <div class="card-body">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>Name</th>
                                                                            <th></th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr v-for="(member,index) in marketMembers">
                                                                            <td scope="row">{{ index + 1 }}</td>
                                                                            <td>
                                                                                <div>
                                                                                    {{ member.name }}
                                                                                </div>
                                                                                <div
                                                                                    class="tw-text-sm tw-font-semibold tw-text-gray-500">
                                                                                    {{ member.formattedphone }}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <button
                                                                                    :disabled="choosingMember == index"
                                                                                    class="btn btn-sm btn-primary"
                                                                                    @click.prevent="chooseMember(member,index)">
                                                                                    Choose
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="modal-footer d-flex justify-content-between">
                                <div>
                                    <button
                                        type="button"
                                        class="btn primary-button-coamana text-white"
                                        data-bs-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                </div>
                                <div class="">
                                    <div>
                                        <template v-if="selectedProduct?.blacklisted"></template>
                                        <template v-else>
                                            <template v-if="currentPageIs">
                                                <button
                                                    type="button"
                                                    @click.prevent="gotoNextStep"
                                                    class="btn text-white add-button-coamana"
                                                >
                                                    Proceed
                                                    <i class="fa fa-step-forward"></i>
                                                </button>
                                            </template>
                                            <template v-else>
                                                <button
                                                    type="button"
                                                    :disabled="forMarketMember && !member"
                                                    id="proceedButtonForward"
                                                    @click.prevent="setSellerAndChosenProduct(selectedProduct)"
                                                    class="btn text-white add-button-coamana"
                                                >
                                                    Proceed
                                                    <i class="fa fa-ban" v-if="forMarketMember && !member"></i>
                                                    <i class="fa fa-step-forward" v-else></i>
                                                </button>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
