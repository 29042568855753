import {defineStore} from "pinia";
import {ref} from "vue";
import {useAuthStore} from "./auth";

export const useMakeOrderStore = defineStore('makeorder', () => {

    const auth = useAuthStore();

    const seller = ref([]);
    const buyer = ref(auth.user);
    const amount = ref(0);
    const agentFee = ref(0);
    const quantity = ref();
    const loggedInUser = ref(auth.user);
    const selectedProduct = ref([]);
    const deductMoneyFrom = ref('wallet');
    const sendOtpTo = ref();

    const getMarketFees = () => {

    };

    return {
        selectedProduct,
        seller,
        buyer,
        amount,
        agentFee,
        quantity,
        loggedInUser,
        getMarketFees,
        sendOtpTo,
    };
});
