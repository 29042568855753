<script setup>

import {onMounted, ref} from "vue";
import {Bootstrap5Pagination} from 'laravel-vue-pagination';
import moment from "moment/moment";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const transactions = ref({});
const errors = ref({});
const searchTransaction = ref('');

const accountNumber = ref('');
const accountName = ref('');
const bank = ref('');
const senderAccountNumber = ref('');
const senderAccountName = ref('');
const senderBank = ref('');
const sentAmount = ref(0);
const bankCharge = ref(0);
const transType = ref('');
const reference = ref('');
const remark = ref('');
const transId = ref('');
const sentOn = ref('');
const transact_status = ref('');

function populateBankTransaction(bankTransaction) {
    if (bankTransaction) {
        accountNumber.value = bankTransaction.account_number;
        accountName.value = bankTransaction.account_name;
        bank.value = bankTransaction.bank;
        senderAccountNumber.value = bankTransaction.sender_account_number;
        senderAccountName.value = bankTransaction.sender_account_name;
        senderBank.value = bankTransaction.sender_bank;
        sentAmount.value = bankTransaction.amount;
        bankCharge.value = bankTransaction.bank_charge;
        transType.value = bankTransaction.type;
        reference.value = bankTransaction.reference;
        transact_status.value = bankTransaction.status;
        remark.value = bankTransaction.sender_remark;
        transId.value = bankTransaction.transact_id;
        sentOn.value = formatDate(bankTransaction.created_at);
    }
}

const getPartnerBankTransactions = async (page = 1) => {
    await axios.get(`/apis/v4/saas/pb-transactions?page=${page}&keyword=${searchTransaction.value}`).then(resp => {
        transactions.value = resp.data.data;
    }).catch(err => {
        errors.value = err.response.data.errors;
    });
}

function setSearchTransaction() {
    let urlParams = new URLSearchParams(window.location.search);
    searchTransaction.value = urlParams.has('search') ? urlParams.get('search') : '';
}

function formatDate(value) {
    return moment(value).format('DD/MM/YYYY HH:mm:ss');
}

onMounted(function () {
    setSearchTransaction();
    getPartnerBankTransactions();
});

</script>

<template>
    <div>
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <form action="/four/admin/bank-transactions" method="GET">
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Search by account, account number or bank"
                                           name="search"
                                           id="searchTransaction"
                                           :value="searchTransaction"
                                           aria-label="Search Transaction" aria-describedby="button-addon2">
                                    <button class="btn btn-outline-secondary" type="submit" id="performSearchTransaction">
                                        Search
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead v-if="transactions && transactions.data && transactions.data.length > 0">
                            <tr>
                                <th>#</th>
                                <th>Sender</th>
                                <th>Sender No.</th>
                                <!--<th>Bank</th>-->
                                <th>Amount</th>
                                <!--<th>Status</th>-->
                                <th>Receiver</th>
                                <th>Receiver No.</th>
                                <th>Receiver Bank</th>
                            </tr>
                            </thead>
                            <tbody v-if="transactions && transactions.data && transactions.data.length > 0">
                            <tr v-for="(transaction,index) in transactions.data" :key="transaction.id">
                                <td scope="row">{{ index + 1 }}</td>
                                <td>{{ transaction.sender_account_name }}</td>
                                <td>{{ transaction.sender_account_number }}</td>
                                <!--<td>{{ transaction.sender_bank }}</td>-->
                                <td class="text-success">{{ currency }}{{ transaction.amount.toLocaleString() }}</td>
                                <!--<td v-if="transaction.status === 'success'" class="text-success">{{ transaction.status }}</td>
                                <td v-else class="text-danger">{{ transaction.status }}</td>-->
                                <td>{{ transaction.account_name }}</td>
                                <td>{{ transaction.account_number }}</td>
                                <td>{{ transaction.bank }}</td>
                                <td>
                                    <button class="btn btn-sm btn-default text-primary"
                                            @click="populateBankTransaction(transaction)"
                                            data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
                                            aria-controls="offcanvasExample"
                                    >
                                        See More <i class="fa fa-angle-double-right fa-thin"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td align="center" colspan="7">No record found.</td>
                            </tr>
                            </tbody>
                        </table>
                        <bootstrap5-pagination :data="transactions" @pagination-change-page="getPartnerBankTransactions" align="center"/>
                    </div>
                    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample"
                         aria-labelledby="offcanvasExampleLabel">
                        <div class="offcanvas-header">
                            <h5 class="offcanvas-title" id="offcanvasExampleLabel">Bank Transaction</h5>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <tbody>
                                    <tr>
                                        <td>Transaction Type:</td>
                                        <td><b>{{ transType }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Sender Account Name:</td>
                                        <td><b>{{ senderAccountName }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Sender Account Number:</td>
                                        <td><b>{{ senderAccountNumber }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Sender Bank:</td>
                                        <td><b>{{ senderBank }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Amount:</td>
                                        <td class="text-success"><b>{{ currency }}{{ sentAmount.toLocaleString() }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Bank Charge:</td>
                                        <td class="text-success"><b>{{ currency }}{{ bankCharge.toLocaleString() }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Receiver Account Name:</td>
                                        <td><b>{{ accountName }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Receiver Account Number:</td>
                                        <td><b>{{ accountNumber }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Receiver Bank:</td>
                                        <td><b>{{ bank }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Sent Date:</td>
                                        <td><b>{{ sentOn }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Sender Remark:</td>
                                        <td><b>{{ remark }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td v-if="transact_status === 'success'" class="text-success">
                                            <b>{{ transact_status }}</b>
                                        </td>
                                        <td v-else class="text-danger">
                                            <b>{{ transact_status }}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Reference:</td>
                                        <td><b>{{ reference }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Transact ID:</td>
                                        <td><b>{{ transId }}</b></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.page-link {
    color: #00ab4d;
}
.page-link:hover {
    color: #00ab4d;
}
.active>.page-link, .page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: #00ab4d;
    border-color: #00ab4d;
}
</style>
