<script setup>
import {ref} from "vue";

const props = defineProps([
    'user',
    'market',
    'img',
]);

const avatar = ref(props.user.user_avatar);
const name = ref(props.user.name);
const marketname = ref(props.market.name);
Echo.channel(`profile-updated-${props.user.id}`)
    .listen('ProfileUpdatedEvent', (e) => {
        avatar.value = e.user.user_avatar;
        name.value = e.user.name;
    });
</script>

<template>
    <template v-if="avatar">
        <img :src="avatar" class="user-img" alt="user avatar">
    </template>
    <template v-else>
        <img :src="img" class="user-img" alt="user avatar">

    </template>
    <div class="user-info ps-3">
        <p class="user-name mb-0">{{ name }}</p>
        <p class="designattion mb-0">{{ marketname }}</p>
    </div>
</template>

<style scoped>

</style>
