<script setup>
import {ref} from "vue";
import Swal from "sweetalert2";

const errors = ref({});
const bvn = ref('');
const account_type = ref('virtual');
const saving = ref(false);

async function addAccount() {
    saving.value = true;

    await axios.get(`/apis/v4/saas/user-bvn?bvn=${bvn.value}`).then(resp => {
        let userId = resp.data.data['id'];

        if(userId){

            let api = account_type.value === 'virtual' ? '/apis/v4/saas/pb-accounts' : '/apis/v4/saas/pb-accounts/credit';

            axios.post(api, {
                'account_type': account_type.value,
                'bvn': bvn.value,
                'user_id': userId,
            }).then(resp => {
                saving.value = false;
                Swal.fire({
                    position: 'top-right',
                    toast: true,
                    icon: 'success',
                    title: 'Account created successfully',
                    timer: 2000
                });
                location.href = '/four/admin/bank-accounts';
            }).catch(err => {
                saving.value = false;
                let valError = err.response.data.errors;
                errors.value = valError ? valError : "";

                let apiError = err.response.data?.trace?.error[0];
                Swal.fire({
                    position: 'top-right',
                    toast: true,
                    icon: 'error',
                    title: apiError ? apiError : 'An error occurred',
                    timer: 4000
                });
            });
        }else{
            saving.value = false;
            errors.value = {'bvn': 'User with bvn not found'};
        }

    }).catch(err => {
        saving.value = false;
        errors.value = err.response.data.errors;
    });
}

</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="row g-3">
                <div class="col-md-12">
                    <!--Label: Bvn, Attributes:bvn -->
                    <div class="form-group">
                        <label for="bvn">Bvn</label>
                        <input id="bvn" v-model="bvn"
                               :class="[errors?.bvn ? 'is-invalid': '',!errors?.bvn && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               aria-describedby="bvn-help"
                               class="form-control"
                               placeholder="Input User Bvn"
                               required type="text">
                        <div v-if="errors.bvn" class="invalid-feedback">
                            {{ errors.bvn.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <!--Label: Account Type, Attributes:account_type -->
                    <div class="form-group">
                        <label for="account_type">Account Type</label>
                        <select type="text" class="form-control" id="account_type" v-model="account_type"
                                aria-describedby="account_type-help"
                                :class="[errors?.account_type ? 'is-invalid': '',!errors?.account_type && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                required>
                            <option value="virtual">Virtual</option>
                            <option value="credit">Credit</option>
                        </select>
                        <div v-if="errors.account_type" class="invalid-feedback">
                            {{ errors.account_type.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <button :disabled="saving"
                            class="btn btn-outline-dark"
                            @click.prevent="addAccount"
                    >
                        Add Account
                        <i v-if="!saving" class="fa-solid fa-plus-circle"></i>
                        <i v-else class="fa fa-spin fa-spinner"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>

</style>
