<script setup>

import {onMounted, ref} from "vue";
import {Bootstrap5Pagination} from 'laravel-vue-pagination';
import moment from "moment/moment";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const props = defineProps(['totalActive', 'totalPending', 'totalNonRenewal', 'totalExpired']);

const subscriptions = ref({});
const errors = ref({});
const searchRequest = ref('');

const plan = ref('');
const planType = ref('');
const marketName = ref('');
const paymentEmail = ref('');
const subscriptionEmail = ref('');
const paymentStatus = ref('');
const status = ref('');
const startDate = ref('');
const endDate = ref('');
const reminderDate = ref('');
const dateCreated = ref('');
const autoRenew = ref('');
const payReference = ref('');
const amount = ref(0);

const globalBalance = ref(0);
const totalAllocated = ref(0);
const totalUnallocated = ref(0);

function populateSubscription(subscription) {
    if (subscription) {
        let subPlan = subscription.subscriptionPlan;

        plan.value = subPlan.name;
        planType.value = subPlan.type.charAt(0).toUpperCase() + subPlan.type.slice(1);
        marketName.value = subscription.market.name;
        status.value = subscription.status;
        paymentStatus.value = subscription.payment_status;
        paymentEmail.value = subscription.payment_email;
        subscriptionEmail.value = subscription.subscription_email;
        autoRenew.value = subscription.auto_renew;
        payReference.value = subscription.payment_reference;
        startDate.value = subscription.start_date ? formatDate(subscription.start_date) : '---';
        endDate.value = subscription.end_date ? formatDate(subscription.end_date) : '---';
        reminderDate.value = subscription.reminder_date ? formatDate(subscription.reminder_date) : '---';
        dateCreated.value = formatDate(subscription.created_at);
        amount.value = subPlan.partner_data?.paystack?.amount ?? 0;
        amount.value = amount.value > 0 ? amount.value/100 : 0;
    }
}

const getSubscriptions = async (page = 1) => {
    await axios.get(`/apis/v4/saas/subscriptions?page=${page}&keyword=${searchRequest.value}`).then(resp => {
        subscriptions.value = resp.data.data;
    }).catch(err => {
        errors.value = err.response.data.errors;
    });
}

function setSearchSubscription() {
    let urlParams = new URLSearchParams(window.location.search);
    searchRequest.value = urlParams.has('search') ? urlParams.get('search') : '';
}

function formatDate(value) {
    return moment(value).format('DD/MM/YYYY HH:mm:ss');
}

onMounted(function () {
    setSearchSubscription();
    getSubscriptions();
});

</script>

<template>
    <div>
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
            <div class="col">
                <div class="card radius-10">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">{{ 'Active Subscriptions' }}</p>
                                <h6 class="my-1">
                                    {{ totalActive }}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card radius-10">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">{{ 'Active (Non-Renewing)' }}</p>
                                <h6 class="my-1">
                                    {{ totalNonRenewal }}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card radius-10">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">{{ 'Pending Subscriptions' }}</p>
                                <h6 class="my-1">
                                    {{ totalPending }}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card radius-10">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">{{ 'Expired Subscriptions' }}</p>
                                <h6 class="my-1">
                                    {{ totalExpired }}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <form action="/four/admin/finance/subscriptions" method="GET">
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Search by plan or market"
                                           name="search"
                                           id="searchSubscription"
                                           :value="searchRequest"
                                           aria-label="Search Subscriptions" aria-describedby="button-addon2">
                                    <button class="btn btn-outline-secondary" type="submit" id="performSearchRequest">
                                        Search
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead v-if="subscriptions && subscriptions.data && subscriptions.data.length > 0">
                            <tr>
                                <th>#</th>
                                <th>Plan</th>
                                <th>Market</th>
                                <th>Status</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Payment</th>
                                <th>Renewing</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody v-if="subscriptions && subscriptions.data && subscriptions.data.length > 0">
                            <tr v-for="(subscription,index) in subscriptions.data" :key="subscription.id">
                                <td scope="row">{{ index + 1 }}</td>
                                <td>{{subscription.subscriptionPlan.name }}</td>
                                <td>{{ subscription.market.name }}</td>
                                <td v-if="subscription.status === 'active'" class="text-success">
                                    Active
                                </td>
                                <td v-else-if="subscription.status === 'pending'" class="text-warning">
                                    Pending
                                </td>
                                <td v-else-if="subscription.status === 'expired' || subscription.status === 'canceled'" class="text-danger">
                                    {{ subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1) }}
                                </td>
                                <td v-else>
                                    {{ subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1) }}
                                </td>
                                <td>{{ subscription.start_date ? formatDate(subscription.start_date) : '---' }}</td>
                                <td>{{ subscription.end_date ? formatDate(subscription.end_date) : '---' }}</td>
                                <td v-if="subscription.payment_status === 'paid'" class="text-success">
                                    Paid
                                </td>
                                <td v-else-if="subscription.payment_status === 'not_paid'" class="text-danger">
                                    Not-Paid
                                </td>
                                <td v-else class="text-primary">
                                    {{ subscription.payment_status.charAt(0).toUpperCase() + subscription.payment_status.slice(1) }}
                                </td>
                                <td v-if="subscription.auto_renew == 1" style="color: brown">
                                    <i class="fa-thin fa-thumbs-up"/>
                                </td>
                                <td v-else><i class="fa fa-thumbs-down"/></td>
                                <td>
                                    <button class="btn btn-sm btn-default"
                                            @click="populateSubscription(subscription)"
                                            data-bs-toggle="offcanvas" href="#viewSubscription" role="button"
                                            aria-controls="viewSubscription"
                                    >
                                        See More <i class="fa fa-angle-double-right fa-thin"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td align="center" colspan="7">No record found.</td>
                            </tr>
                            </tbody>
                        </table>
                        <bootstrap5-pagination :data="subscriptions" @pagination-change-page="getSubscriptions" align="center"/>
                    </div>
                    <div class="offcanvas offcanvas-end" tabindex="-1" id="viewSubscription"
                         aria-labelledby="viewSubscriptionLabel">
                        <div class="offcanvas-header">
                            <h5 class="offcanvas-title" id="viewSubscriptionLabel">Subscription</h5>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                                    aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <tbody>
                                    <tr>
                                        <td>Plan:</td>
                                        <td><b>{{ plan }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Market:</td>
                                        <td><b>{{ marketName }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Type:</td>
                                        <td><b>{{ planType + ' Plan' }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Amount:</td>
                                        <td><b>{{ currency }}{{ amount.toLocaleString() }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Subscription Email:</td>
                                        <td><b>{{ subscriptionEmail ?? '---' }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td v-if="status === 'active'" class="text-success">
                                            <b>Active</b>
                                        </td>
                                        <td v-else-if="status === 'pending'" class="text-warning">
                                            <b>Pending</b>
                                        </td>
                                        <td v-else-if="status === 'expired' || status === 'canceled'" class="text-danger">
                                            <b>{{ status.charAt(0).toUpperCase() + status.slice(1) }}</b>
                                        </td>
                                        <td v-else>
                                            <b>{{ status.charAt(0).toUpperCase() + status.slice(1) }}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Start Date:</td>
                                        <td><b>{{ startDate }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>End Date:</td>
                                        <td><b>{{ endDate }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Reminder Date:</td>
                                        <td><b>{{ reminderDate }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Payment Status:</td>
                                        <td v-if="paymentStatus === 'paid'" class="text-success">
                                            <b>Paid</b>
                                        </td>
                                        <td v-else-if="paymentStatus === 'not_paid'" class="text-danger">
                                            <b>Not-Paid</b>
                                        </td>
                                        <td v-else class="text-primary">
                                            <b>{{ paymentStatus.charAt(0).toUpperCase() + paymentStatus.slice(1) }}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Payment Email:</td>
                                        <td><b>{{ paymentEmail ?? '---' }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Payment Reference:</td>
                                        <td><b>{{ payReference ?? '---' }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Auto-Renewal:</td>
                                        <td><b>{{ autoRenew == '1' ? 'YES' : 'NO' }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Date Created:</td>
                                        <td><b>{{ dateCreated }}</b></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <button class="btn btn-outline-danger text-reset"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Back"
                                    style="border-color: #00ab4d;width: 100%"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.page-link {
    color: #00ab4d;
}

.page-link:hover {
    color: #00ab4d;
}

.active > .page-link, .page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: #00ab4d;
    border-color: #00ab4d;
}
</style>
