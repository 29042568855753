<template>
    <div>
        <div action="">
            <div class="row">
                <div class="col-12"><h6>Make Payment</h6></div>
                <div class="row mt-1" v-if="paymentData && paymentData.amount <= 0">
                    <div class="col-12">
                        <div class="alert alert-warning d-flex" role="alert">
                            <p>
                                Amount to top-up is 0 or not given.
                                You cannot top-up wallet with given amount.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row mt-1" v-else-if="showFundsWarning">
                    <div class="col-12">
                        <div class="alert alert-warning d-flex" role="alert">
                            <p>
                                Amount to top-up is less than the amount to pay.
                                We will update your wallet but order status remains the same
                            </p>
                        </div>
                    </div>
                </div>
               <div class="col-12">
                   <div class="form-floating mt-2">
                       <input type="text" class="form-control" id="amount" v-model.number="amount"
                              aria-describedby="amount-help"
                              :class="[errors.amount ? 'is-invalid': '',!errors.amount && Object.keys(errors).length > 1 ? 'is-valid': '']"
                       >
                       <label for="amount" class="form-label text-black p-3">Amount to Top Up</label>

                       <div class="invalid-feedback" v-if="errors.amount">
                           {{ errors.amount.toString() }}
                       </div>
                   </div>
               </div>

                <template v-if="locale === 'ke'">
                <div class="col-12">
                    <!--Label: Phone Number, Attributes:phone -->
                    <div class="form-floating mt-3">
                        <input type="text" class="form-control" id="phone" v-model="phone"
                               aria-describedby="phone-help"
                               :class="[errors.phone ? 'is-invalid': '',!errors.phone && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="phone">Phone Number</label>
                        <div class="invalid-feedback" v-if="errors.phone">
                            {{ errors.phone.toString() }}
                        </div>
                    </div>
                </div>
                    <div class="mt-3" id="topupaccount">
                        <button class="btn btn-primary" @click.prevent="depositViaMPESA">
                            Top Up
                            <i class="fa fa-spinner fa-spin" v-if="depositing"></i>
                        </button>
                    </div>
                </template>
                <template v-else>
                    <div class="form-floating mt-3">
                        <input type="text" class="form-control" name="gatewayfees" id="gatewayfees"
                               aria-describedby="gatewayHelpId"
                               disabled
                               :value="gatewayfees"
                               placeholder="gatewayfees">
                        <label for="gatewayfees" class="form-label text-black">Total Including Gateway Fees </label>

                        <small id="gatewayHelpId" class="form-text text-muted">Fees charged by our payment provider.</small>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12">
                            <div class="alert bg-light-success" role="alert" v-if="virtualBankAccount?.account_number">
                                <p><strong>Make transfer to:</strong></p>
                                <p>
                                    <strong>Bank:</strong> VFD Microfinance Bank Limited <br/>
                                    <strong>Account Number:</strong> {{ virtualBankAccount?.account_number }} <br/>
                                    <strong>Account Name:</strong> {{ virtualBankAccount?.account_name }}
                                </p>

                                <button
                                    :disabled="paymentData && paymentData.amount <= 0"
                                    type="button"
                                    class="col-12 btn btn-outline-success"
                                    @click="onClickAlreadyPaid"
                                >
                                    I have paid
                                </button>

                                <div class="mt-3" id="topupaccount">
                                    <paystack
                                        :disabled="paymentData && paymentData.amount <= 0"
                                        buttonClass="col-12 btn btn-outline-success"
                                        buttonText="Pay with card"
                                        :publicKey="publicKey"
                                        :email="email ? email : 'finance@coamana.com'"
                                        :amount="gatewayfees * 100"
                                        :reference="reference"
                                        :onSuccess="onSuccessfulPayment"
                                        :onCancel="onCancelledPayment">
                                    </paystack>
                                </div>
                            </div>
                            <div v-else>
                                <div class="mt-3 mb-3" id="topupaccount">
                                    <paystack
                                        :disabled="paymentData && paymentData.amount <= 0"
                                        buttonClass="col-12 btn btn-success"
                                        buttonText="Pay"
                                        :publicKey="publicKey"
                                        :email="email ? email : 'finance@coamana.com'"
                                        :amount="gatewayfees * 100"
                                        :reference="reference"
                                        :onSuccess="onSuccessfulPayment"
                                        :onCancel="onCancelledPayment">
                                    </paystack>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

    </div>
</template>
<script setup>
const emit = defineEmits(['walletToppedUp', 'submit', 'walletToppedUpAmount']);

</script>
<script>
import paystack from "vue3-paystack";
import Swal from "sweetalert2";

export default {
    name: "inlinetopup",
    props: [
        'amountToPay', 'userobject', 'virtual_bank_account', 'location_url'
    ],
    components: {
        paystack,
    },
    mounted() {
        this.amount = this.amountToPay ?? 500;

        this.user = JSON.parse(document.querySelector('meta[name="user"]').content) ?? this.userobject;

        this.paymentData.currency = this.currency;
        this.paymentData.meta.consumer_id = this.user.id;
        this.paymentData.meta.consumer_phone = this.user.phone;
        this.paymentData.customer.name = this.user.name;
        this.paymentData.customer.email = this.user.email ?? 'info@coamana.com';
        this.email = this.user.email ?? 'info@coamana.com';
        this.paymentData.customer.phone_number = this.user.phone;
        this.virtualBankAccount = this.virtual_bank_account;
        this.locationUrl = this.location_url;
        this.showFundsWarning = false;
    },
    data() {
        return {
            errors: '',
            // gatewayfees: 500,
            withdrawalId: '',
            transactionInitiated: false,
            saving: false,
            for_you: 'for_you',
            user: '',
            amount: 1000,
            publicKey: import.meta.env.VITE_PAYSTACK_PUBLIC_KEY,
            email: 'info@coamana.com',
            reference: this.generateReference(),
            paymentData: {
                tx_ref: this.generateReference(),
                amount: this.amount,
                currency: "NGN",
                payment_options: "card,ussd",
                redirect_url: ``,
                meta: {
                    consumer_id: '',
                    consumer_phone: '',
                    consumer_mac: "kjs9s8ss7dd"
                },
                customer: {
                    name: "",
                    email: "",
                    phone_number: ""
                },
                customizations: {
                    title: "Amana Market",
                    description: "Amana Market",
                    logo: "https://flutterwave.com/images/logo-colored.svg"
                },
                callback: this.makePaymentCallback,
                onclose: this.closedPaymentModal
            },

            bank: '',
            account_number: '',
            // totalamount: 0,
            amount_to_withdraw: '',
            otp: '',
            complete: false,
            message: "",
            phone: "",
            locale: import.meta.env.VITE_LOCALIZATION_COUNTRY_ABBR,
            virtualBankAccount: this.virtual_bank_account,
            showFundsWarning: this.showFundsWarning,
        }
    },
    methods: {
        depositViaMPESA(){
            let Self = this;

            this.depositing = true;
            axios.post('/apis/v4/topup/wallet/mpesa',{
                'phone': this.phone,
                'amount': this.amount,
                'reason': 'topup'
            }).then((resp)=>{
                Self.$emit('walletToppedUp', resp.data);
                emit('walletToppedUp', resp.data)
            }).catch(err=>{
                this.errors = err.response.data.errors;
                this.depositing = false;
            }).finally(()=>{
                this.depositing = false;
            });
        },
        totalAmount(amountPassed) {
            this.amount = amountPassed;
        },
        sendOtp() {
            this.complete = true;
            this.saving = true;
            axios.post('/apis/v4/withdraw/otp', {
                'bank_id': this.bank,
                'account_number': this.account_number,
                'user_id': this.user.id,
                'amount': this.amount_to_withdraw
            }).then(resp => {
                this.saving = false;
                this.withdrawalId = resp.data.id;
            }).finally(() => {
                this.saving = false;
            });
        },
        changeStuff() {
            // `this` points to the vm instance
            let paystackFees = 0;

            if (parseInt(this.amount) <= 2000) {
                paystackFees = (this.amount * 0.015);
            } else {
                paystackFees = (this.amount * 0.015) + 100;
            }
            return this.amount + paystackFees;
        },
        onSuccessfulPayment(response) {
            let Self = this;
            axios.post('/api/wallet/topup', {
                success: response,
                payload: this.paymentData
            }).then(resp => {
                Self.$emit('walletToppedUpAmount', this.paymentData?.amount)
                Self.$emit('walletToppedUp', resp.data);
            }).catch(err => {})
        },
        onCancelledPayment(response) {
            console.log(response);
        },
        makePaymentCallback(response) {
            axios.post('/api/wallet/topup', {
                success: response,
                payload: this.paymentData
            }).then(resp => {
                // window.location.reload();
            }).catch(err => {
                // window.location.reload();
            })
        },
        closedPaymentModal() {
            console.log('payment modal is closed');
        },
        generateReference() {
            let date = new Date();
            return date.getTime().toString();
        },
        onClickAlreadyPaid() {
            Swal.fire({
                position: 'center',
                icon: 'info',
                title: 'Your wallet will be updated once payment is confirmed. Please try again after wallet has been updated',
                timer: 9000
            }).then(() => {
                location.href = this.locationUrl ?? '/four';
            });
        }
    },
    computed: {
        totalamount: function () {
            let amount = 0;

            if (this.amount_to_withdraw < 5000) {
                amount = this.amount_to_withdraw + 10;
            }

            if (this.amount_to_withdraw > 5000 && this.amount_to_withdraw <= 49999) {
                amount = this.amount_to_withdraw + 25;
            }

            if (this.amount_to_withdraw >= 50000) {
                amount = this.amount_to_withdraw + 50;
            }
            this.totalamount = amount;
            return amount;
        },
        chargedAmount: function () {
            // `this` points to the vm instance
            return this.amount
        },
        gatewayfees: function () {
            // `this` points to the vm instance
            let paystackFees = 0;

            if (parseInt(this.amount) <= 2000) {
                paystackFees = (this.amount * 0.015);
            } else {
                paystackFees = (this.amount * 0.015) + 100;
            }
            let total = paystackFees + 160;

            if (total >= 2500) {
                total = 2500;
            }
            return this.amount + total;
        }
    },
    watch: {
        amount(newValue, oldValue) {
            this.paymentData.amount = newValue;
            this.showFundsWarning = newValue < this.amountToPay;
        }
    }
}
</script>

<style scoped>

</style>
