<script setup>
import {useFetch} from '@vueuse/core'
import {ref} from "vue";
import _ from "lodash";
import Members from "./Members.vue";
import Orders from "./Orders.vue";


const markets = ref([]);
const {isFetching, data, error} = useFetch('/apis/v4/admin/onboarding').json();

const activeMarket = ref(false);
const membersref = ref(null);
const key = ref(1);
const ordersKey = ref(1);

const fetchMembers = () => {
    key.value++;
}
const fetchOrders = () => {
    ordersKey.value++;
}

const activateMarket = (id) => {
    activeMarket.value = _.find(data.value?.markets, ['id', id]);
    let button = document.getElementById('marketinfo');
    if (button) {
        button.click();
    }
}

</script>

<template>
    <div v-if="error">
        We had an issue calling our API
    </div>
    <div v-else-if="isFetching">
        Loading...
    </div>
    <template v-else>
        <div class="chat-wrapper">
            <div class="chat-sidebar">
                <div class="chat-sidebar-content">
                    <div id="pills-tabContent" class="tab-content">
                        <div id="pills-Chats" class="tab-pane fade show active">
                            <div class="p-3">
                                <div class=" mt-3">
                                    <a
                                        class="text-uppercase text-secondary"
                                        href="#">Recent Onboarded Markets
                                    </a>
                                </div>
                            </div>
                            <div class="chat-list overflow-y-auto">
                                <div class="list-group list-group-flush overflow-y-auto">
                                    <hr>
                                    <template v-for="market in data?.markets" :key="market?.id">
                                        <a class="list-group-item"
                                           @click="activateMarket(market?.id)">
                                            <div class="d-flex">
                                                <div class="flex-grow-1 ms-2">
                                                    <h6 class="mb-0 chat-title">{{ market.name }}</h6>
                                                    <p class="mb-0 chat-msg">{{ market.class }}</p>
                                                </div>
                                            </div>
                                        </a>
                                        <hr>
                                    </template>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="!activeMarket"></template>
            <template v-else>
                <div class="chat-header d-flex align-items-center">
                    <div>
                        <h4 class="mb-1 font-weight-bold">{{ activeMarket.name }}</h4>
                        <div class="list-inline d-sm-flex mb-0 d-none"><a
                            class="list-inline-item d-flex align-items-center text-secondary"
                            href="javascript:"><small
                            class="bx bxs-circle me-1 chart-online"></small>Active Now</a>
                        </div>
                    </div>
                </div>
                <div class="chat-content">
                    <div class="row">
                        <ul class="nav nav-tabs nav-primary" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a id="marketinfo" aria-selected="true" class="nav-link active" data-bs-toggle="tab"
                                   href="#primaryhome"
                                   role="tab">
                                    <div class="d-flex align-items-center">
                                        <div class="tab-icon"><i class='bx bx-home font-18 me-1'></i>
                                        </div>
                                        <div class="tab-title">Market Information</div>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a id="members" aria-selected="false" class="nav-link" data-bs-toggle="tab"
                                   href="#primaryprofile"
                                   role="tab"
                                   @click="fetchMembers">
                                    <div class="d-flex align-items-center">
                                        <div class="tab-icon"><i class='bx bx-user-pin font-18 me-1'></i>
                                        </div>
                                        <div class="tab-title">Members</div>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a aria-selected="false"
                                   class="nav-link"
                                   data-bs-toggle="tab" href="#orders" role="tab"
                                   @click="fetchOrders">
                                    <div class="d-flex align-items-center">
                                        <div class="tab-icon"><i class='bx bx-user-pin font-18 me-1'></i>
                                        </div>
                                        <div class="tab-title">Orders</div>
                                    </div>
                                </a>
                            </li>
                            <!--                            <li class="nav-item" role="presentation">-->
                            <!--                                <a aria-selected="false" class="nav-link" data-bs-toggle="tab" href="#primaryprofile"-->
                            <!--                                   role="tab">-->
                            <!--                                    <div class="d-flex align-items-center">-->
                            <!--                                        <div class="tab-icon"><i class='bx bx-user-pin font-18 me-1'></i>-->
                            <!--                                        </div>-->
                            <!--                                        <div class="tab-title">Fees And Charges</div>-->
                            <!--                                    </div>-->
                            <!--                                </a>-->
                            <!--                            </li>-->
                            <!--                            <li class="nav-item" role="presentation">-->
                            <!--                                <a aria-selected="false" class="nav-link" data-bs-toggle="tab" href="#primarycontact"-->
                            <!--                                   role="tab">-->
                            <!--                                    <div class="d-flex align-items-center">-->
                            <!--                                        <div class="tab-icon"><i class='bx bx-microphone font-18 me-1'></i>-->
                            <!--                                        </div>-->
                            <!--                                        <div class="tab-title">Finance</div>-->
                            <!--                                    </div>-->
                            <!--                                </a>-->
                            <!--                            </li>-->
                        </ul>
                        <div class="tab-content py-3">
                            <div id="primaryhome" class="tab-pane fade show active" role="tabpanel">

                                <div class="row">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-6 mt-4">
                                                    <div class="table-responsive">
                                                        <table class="table table-sm table-condensed table-columned">
                                                            <tbody>
                                                            <tr>
                                                                <th>Name:</th>
                                                                <td>{{ activeMarket?.name }}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Address:</th>
                                                                <td>{{ activeMarket?.address }}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Allocated Tier:</th>
                                                                <td>{{ activeMarket?.market_limit?.name }}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>State:</th>
                                                                <td>{{ activeMarket?.geo_state?.name }}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Local Government:</th>
                                                                <td>{{ activeMarket?.l_g_a?.name }}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mt-4">
                                                    <div class="table-responsive">
                                                        <table class="table table-sm table-condensed table-columned">
                                                            <tbody>
                                                            <tr>
                                                                <th>Market Leader:</th>
                                                                <td>{{
                                                                        activeMarket?.market_leader_optimized?.name
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Market Leader Phone Number:</th>
                                                                <td>{{
                                                                        activeMarket?.market_leader_optimized?.phone
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Number of Members:</th>
                                                                <td>{{ activeMarket?.members_count }}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Verification Status:</th>
                                                                <td>{{ activeMarket?.verification_status }}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div id="primaryprofile" class="tab-pane fade" role="tabpanel">
                                <Members :key="key" ref="membersref" :marketid="activeMarket?.id"></Members>
                            </div>
                            <div id="orders" class="tab-pane fade" role="tabpanel">
                                <Orders :key="ordersKey" ref="orders" :marketid="activeMarket?.id"></Orders>
                            </div>
                            <div id="primarycontact" class="tab-pane fade" role="tabpanel">
                                <p>Etsy mixtape wayfarers, ethical wes anderson tofu before they sold out
                                    mcsweeney's organic lomo retro fanny pack lo-fi farm-to-table readymade.
                                    Messenger bag gentrify pitchfork tattooed craft beer, iphone skateboard
                                    locavore carles etsy salvia banksy hoodie helvetica. DIY synth PBR banksy
                                    irony. Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh mi
                                    whatever gluten-free, carles pitchfork biodiesel fixie etsy retro mlkshk
                                    vice blog. Scenester cred you probably haven't heard of them, vinyl craft
                                    beer blog stumptown. Pitchfork sustainable tofu synth chambray yr.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </template>
</template>

<style scoped>
.chat-wrapper{
    min-height: 100vh !important;
}
</style>
