<script setup>
import Payfororder from "../../wallet/orders/PayForOrder.vue";
import { ref } from "vue";
import Stepper from "bs-stepper";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const props = defineProps(["upcomingpayments", "auth_id"]);

const payment_type = ref("");
const payment_id = ref("");
const payment_amount = ref("");
const payfororderref = ref("");

function showModal(payment) {
    let myModalEl = document.querySelector("#payfororder");
    let modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
    modal.toggle();

    payment_type.value = payment.type;
    payment_id.value = payment.payable.id;
    payment_amount.value = payment.amount;
    payfororderref.value.sendOTP();
}
</script>

<template>
    <div>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th class="text-nowrap" scope="row">Transaction Type</th>
                    <th class="text-nowrap">Due Date</th>
                    <th class="text-nowrap">From</th>
                    <th class="text-nowrap">To</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Total Amount ( {{ currency }})</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr :key="payment?.id" v-for="payment in upcomingpayments">
                    <td>
                        <template v-if="payment.payable_type === 'Order'">
                            <div v-if="payment.payable.buyer_id === auth_id">
                                Purchase
                            </div>
                            <div v-else>Sale</div>
                            <br />
                            <span
                                class="badge rounded-pill bg-linkedin"
                                v-if="
                                    payment.payable.summary_line.includes(
                                        'Skipped'
                                    )
                                "
                            >
                                Direct Purchase
                            </span>
                            <span
                                class="badge rounded-pill bg-secondary"
                                v-else
                                >{{ payment.payable.summary_line }}</span
                            >
                        </template>
                        <template v-if="payment.payable_type === 'Fee'">
                            {{ payment.type }}
                            <br />
                            <span class="badge rounded-pill bg-secondary">
                                {{ payment.payable.type }}
                            </span>
                        </template>
                    </td>
                    <td>{{ payment.upcoming_at }}</td>
                    <td>
                        <template v-if="payment.payable_type === 'Order'">
                            {{ payment.payable.buyer.name }}
                            &mdash;
                            <span class="text-muted">{{
                                payment.payable.buyer.phone
                            }}</span>
                        </template>
                        <template v-else>
                            {{ payment.market?.name }}
                        </template>
                    </td>
                    <td>
                        <template v-if="payment.payable_type === 'Order'">
                            {{ payment.payable.seller.name }}
                            <span>
                                <i
                                    class="fa-solid fa-face-angry fa-lg"
                                    style="color: #cc0000"
                                ></i>
                            </span>
                            <span>
                                <i
                                    class="fa-solid fa-face-meh-blank fa-lg"
                                    style="color: #ff7a00"
                                ></i>
                            </span>
                            <span>
                                <i
                                    class="fa-solid fa-face-smile fa-lg"
                                    style="color: #00ab4d"
                                ></i>
                            </span>

                            &mdash;
                            <span class="text-muted">{{
                                payment.payable.seller.phone
                            }}</span>
                        </template>
                        <template v-else>
                            {{ payment.market?.name }}
                        </template>
                    </td>

                    <td>
                        <div
                            v-if="payment.status === 'Pending'"
                            class="badge rounded-pill text-warning bg-light-warning p-2 text-uppercase px-3"
                        >
                            <i class="bx bxs-circle align-middle me-1"></i>
                            Pending
                        </div>
                        <div
                            v-else-if="payment.status === 'Paid'"
                            class="badge rounded-pill text-success bg-light-success p-2 text-uppercase px-3"
                        >
                            <i class="bx bxs-circle align-middle me-1"></i>
                            Paid
                        </div>
                        <div
                            v-else-if="payment.status === 'Accepted'"
                            class="badge rounded-pill text-success bg-light-success p-2 text-uppercase px-3"
                        >
                            <i class="bx bxs-circle align-middle me-1"></i>
                            Accepted
                        </div>
                        <div
                            v-else-if="
                                payment.status === 'Rejected' ||
                                payment.status === 'Automated Rejection'
                            "
                            class="badge rounded-pill text-danger bg-light-danger p-2 text-uppercase px-3"
                        >
                            <i class="bx bxs-circle align-middle me-1"></i>
                            {{ payment.status }}
                        </div>
                        <div
                            v-else-if="payment.status === 'OverDue'"
                            class="badge rounded-pill text-warning bg-light-warning p-2 text-uppercase px-3"
                        >
                            <i class="bx bxs-circle align-middle me-1"></i>
                            OverDue
                        </div>
                        <div
                            v-else-if="payment.status === 'DUE'"
                            class="badge rounded-pill text-white bg-linkedin p-2 text-uppercase px-3"
                        >
                            <i class="bx bxs-circle align-middle me-1"></i>
                            Due
                        </div>
                        <div
                            v-else
                            class="badge rounded-pill text-success bg-light-success p-2 text-uppercase px-3"
                        >
                            <i class="bx bxs-circle align-middle me-1"></i>
                            {{ payment.status }}
                        </div>
                    </td>
                    <td>
                        {{ currency }}{{ payment.amount?.toLocaleString(2) }}
                    </td>
                    <td>
                        <template v-if="payment.payable_type === 'Order'">
                            <div
                                class="d-flex justify-content-between"
                                v-if="
                                    payment?.payable?.meta
                                        ?.order_accepted_by_seller == null
                                "
                            >
                                <a
                                    :href="`/four/admin/upcoming-payments/${payment.id}`"
                                    type="button"
                                    class="btn btn-primary btn-sm radius-30 px-4"
                                >
                                    Confirm
                                </a>
                            </div>

                            <template
                                v-else-if="
                                    payment.payable.pay_with_working_capital &&
                                    !payment.payable.payment_made &&
                                    payment?.payable?.meta
                                        ?.order_accepted_by_seller === true
                                "
                            >
                                <a
                                    :href="`/four/admin/upcoming-payments/${payment.id}`"
                                    type="button"
                                    :disabled="payment.status === 'Rejected'"
                                    class="btn btn-outline-secondary btn-sm radius-30 px-4"
                                >
                                    <span v-if="payment.status === 'Rejected'">
                                        View
                                    </span>
                                    <span v-else> Pay </span>
                                </a>
                            </template>
                            <template v-else>
                                <a
                                    :href="`/four/admin/upcoming-payments/${payment.id}`"
                                    type="button"
                                    class="btn btn-outline-secondary btn-sm radius-30 px-4"
                                >
                                    View
                                </a>
                            </template>
                        </template>

                        <template v-else>
                            <template
                                v-if="
                                    payment.payable_type === 'Fee' &&
                                    payment.status != 'Paid'
                                "
                            >
                                <a
                                    :href="`/four/admin/upcoming-payments/${payment.id}`"
                                    type="button"
                                    class="btn btn-outline-secondary btn-sm radius-30 px-4"
                                >
                                    Pay
                                </a>
                            </template>
                            <template v-else>
                                <a
                                    :href="`/four/admin/upcoming-payments/${payment.id}`"
                                    type="button"
                                    class="btn btn-outline-secondary btn-sm radius-30 px-4"
                                >
                                    View
                                </a>
                            </template>
                        </template>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th class="text-nowrap" scope="row">Transaction Type</th>
                    <th class="text-nowrap">Due Date</th>
                    <th class="text-nowrap">From</th>
                    <th class="text-nowrap">To</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Total Amount ( {{ currency }})</th>
                    <th></th>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<style scoped></style>
