<script setup>
import {onMounted, ref, watch} from "vue";
import axios from "axios";

const banks = ref([]);
const account_name = ref("");
const account_number = ref("");
const bank_code = ref("");
const bankIds = ref([]);
const finding = ref(false);
const accountFound = ref(false);
const errors = ref({});

const emit = defineEmits(["updatedAccountName", "updatedAccountNumber", "updatedBankCode", "updatedAccountFound"]);
const props = defineProps(["errors", "bankCode", "accountNumber", "accountName"]);

watch(account_name, function (newValue, oldValue) {
    emit("updatedAccountName", newValue);
});
watch(account_number, function (newValue, oldValue) {
    emit("updatedAccountNumber", newValue);
});
watch(bank_code, function (newValue, oldValue) {
    emit("updatedBankCode", newValue);
});
watch(accountFound, function (newValue, oldValue) {
    emit("updatedAccountFound", newValue);
});

const fetchAccountDetails = () => {
    finding.value = true;
    errors.value = {};

    let code = bank_code.value;
    let account = account_number.value;

    axios
        .post("/apis/v4/bank-account-info", {
            bank: bankIds.value[bank_code.value],
            account_number: account_number.value,
            amount_to_withdraw: 0,
            bank_type: 'paystack',
        })
        .then((resp) => {
            finding.value = false;
            let accName = resp.data?.accountDetails?.account_name ?? null;

            if(accName){
                accountFound.value = true;
                account_name.value = accName;
                bank_code.value = code;
                account_number.value = account;
                errors.value = {};
            }else{
                errors.value = {account_number: 'Account not found', account_name: ' '};
                account_name.value = 'ACCOUNT NOT FOUND';
            }
        })
        .catch((error) => {
            finding.value = false;
            errors.value = error?.response?.data?.errors ?? '';
        })
}

async function getBanks() {
    await axios.get("/apis/v4/saas/vfd/banks")
        .then(response => {
            response.data.data.map((bank) => {
                let hasPaystackCode = !!bank?.metadata?.mapped_codes?.paystack ?? false;
                if(hasPaystackCode){
                    banks.value.push(bank);
                    bankIds.value[bank.code] = bank.id;
                }
            })
        }).catch();
}

onMounted(function () {
    getBanks();

    errors.value = props.errors;
    bank_code.value = props.bankCode;
    account_name.value = props.accountName;
    account_number.value = props.accountNumber;
});

</script>

<template>
    <div>
        <div class="row g-3">
            <!--Label: Bank, Attributes:bank_code -->
            <div class="col-md-12">
                <div class="form-group">
                    <select :disabled="accountFound" type="text" class="form-control" id="bank_code" v-model="bank_code"
                            aria-describedby="bank_code-help"
                            :class="[errors?.bank_code ? 'is-invalid': '',!errors?.bank_code && Object.keys(errors).length > 1 ? 'is-valid': '']"
                            required>
                        <option value="" selected> --- Select Bank ---</option>
                        <option v-for="b in banks" :value="b.code">{{ b.name }}</option>
                    </select>
                </div>
            </div>
            <!--Label: Account Number, Attributes:account_number -->
            <div class="col-md-12">
                <div class="form-group">
                    <input
                        :disabled="accountFound"
                        type="text"
                        class="form-control"
                        id="account_number"
                        v-model="account_number"
                        aria-describedby="account_number-help"
                        :class="[errors.account_number ? 'is-invalid' : '',!errors.account_number && Object.keys(errors).length > 1? 'is-valid': '',]"
                        placeholder="Account Number"
                    />
                    <div class="invalid-feedback" v-if="errors.account_number">
                        {{ errors.account_number.toString() }}
                    </div>
                </div>
            </div>
            <!--Label: Account Name, Attributes:account_name -->
            <div class="col-md-12">
                <div class="form-group">
                    <input
                        disabled
                        type="text"
                        class="form-control"
                        id="account_name"
                        v-model="account_name"
                        aria-describedby="account_name-help"
                        :class="[errors.account_name ? 'is-invalid' : '',!errors.account_name && Object.keys(errors).length > 1? 'is-valid': '',]"
                        placeholder="Account Name"
                    />
                    <div class="invalid-feedback" v-if="errors.account_name">
                        {{ errors.account_name.toString() }}
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <button v-if="!accountFound" type="button" class="btn btn-sm btn-outline-secondary" @click.prevent="fetchAccountDetails">
                    Find Account
                    <i class="fa fa-spin fa-spinner" v-if="finding"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped></style>

