<script setup>
import {ref, watch} from "vue";

const props = defineProps({
    status: {
        type: String,
        default: "inprogress",
    },
    market: {
        type: Object,
        default: "Level 0",
    },
    verificationstatus: {
        type: Object,
    },
});

const emit = defineEmits(['showForm']);
const showForm = ref(false);

watch(showForm, function (newValue) {
    emit("showForm", newValue);
});

const reloadPage = () => {
    window.location.reload();
};

const getProgressBarWidth = () => {
    const value = props.verificationstatus.trustLevel ?? 0;
    const level = parseInt(value);
    const percentage = (level / 8) * 100;
    return isNaN(percentage) ? 0 : percentage;
};
</script>

<template>
    <div class="card mb-3 tw-rounded-lg tw-shadow-lg" :class="`card-${status}`">
        <div class="card-body">
            <h4 class="card-title">
                <slot name="title"> Market Verification is in Progress</slot>
            </h4>
            <div class="card tw-border-r-2 tw-mt-5" style="border-radius: 1rem">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="circle-container" :class="`card-${status}-border`">
                                <div class="circle-text">
                                    <p class="small">0</p>
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1 align-self-end">
                            <div class="d-flex">
                                <div style="width: 100%" class="tw-ml-3 tw-mr-3">
                                    <div class="text-xs-center text-black mb-2" id="progressId">
                                        <slot name="trustlevel" class="tw-font-bold"> Trust Level 1</slot>
                                    </div>
                                    <div class="progress">
                                        <div
                                            class="progress-bar"
                                            :class="`progress-bar-${status}`"
                                            role="progressbar"
                                            :style="{
                                                width: getProgressBarWidth() + '%',
                                            }"
                                            :aria-valuenow="getProgressBarWidth()"
                                            aria-valuemin="0"
                                            aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                                class="circle-container"
                                :class="`card-${status}-border circle-contained-${status}-filled`">
                                <div class="circle-text">
                                    <p class="small">8</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p class="text-white">
                <slot name="message">
                    Your market is verified and is at trust level 1 and your members have access to N100,000
                </slot>
            </p>
            <div class="card-text">
                <button v-if="status != 'inprogress'"
                    class="btn btn-view btn-sm tw-rounded tw-font-bold"
                    :class="`card-${status}-text`"
                    @click="showForm = !showForm">
                    View Status 
                </button>
                &nbsp;
                <button
                    style="color: #ee8509"
                    class="btn btn-view btn-sm tw-rounded tw-font-bold"
                    @click="reloadPage"
                    v-if="status === 'inprogress' || status === 'failed'">
                    Refresh <span class="fa fa-refresh"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@use "sass:map";

.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
}

.poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
}

.poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
}

.poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
}

.accordion-title {
    color: #1d242e;
}

.card-title {
    color: white;
}

.card-pending {
    background-color: #ee8509;
    color: white;
}

.circle-container {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 2px solid #ee8509;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    font-weight: bold;
}

.circle-text {
    position: relative;
    top: 5px;
    color: #4b9d30;
}

.btn-view {
    color: #ee8509;
    background-color: white !important;
    border-color: transparent;
}

.drag-target {
    //border: 1px dashed #ccc; //border-radius: 5px; //padding: 10px; //text-align: center;
}

.drag-target.dragging {
    background-color: #aaa;
}

/* Cards*/

$theme-colors: (
    "inprogress": #ee8509,
    "pending": #1e1e1e,
    "success": #4b9d30,
    "failed": #f0483e,
);
$status: (
    "inprogress": "#EE8509",
    "pending": "#1E1E1E",
    "success": "#4B9D30",
    "failed": "#F0483E",
);

@each $status, $customCoAmanaColor in $status {
    .card-#{$status} {
        background-color: map.get($theme-colors, $status);
        border-radius: 1rem;
    }

    .card-#{$status}-text {
        color: map.get($theme-colors, $status);
    }

    .progress-bar-#{$status} {
        background-color: fade-out(rgba(map.get($theme-colors, $status), 0.5), 0.1) !important;
    }

    .card-#{$status}-border {
        border-color: map.get($theme-colors, $status);

        .circle-text {
            color: map.get($theme-colors, $status);
        }
    }
    .circle-contained-#{$status}-filled {
        color: map.get($theme-colors, $status);
        background-color: fade-out(rgba(map.get($theme-colors, $status), 0.5), 0.1);
        border-color: fade-out(rgba(map.get($theme-colors, $status), 0.3), 0.1);
        .circle-text {
            color: white;
        }
    }
    .card-#{status}-text {
        color: map.get($theme-colors, $status);
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-style: normal;
    }
}

//.card-pending-border{
//    border-color: #EE8509;
//    .circle-text {
//        color: #EE8509
//    }
//}
</style>
