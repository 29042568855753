import {defineStore} from 'pinia'
import {ref} from "vue";

export const useAuthStore = defineStore('auth', () => {

    const user = ref([]);
    const market = ref([]);
    const permissions = ref([]);
    const wallet = ref([]);
    const verificationFee = ref([]);

    const updateUserDetails = () => {
        const json = window.App?.me;
        user.value = {...json};
        market.value = {...window.App?.market};
        permissions.value = {...window.App?.Permissions};
        wallet.value = {...window.App?.wallet};
        verificationFee.value = window.App?.verificationFee ?? false;
    }


    return {
        user,
        market,
        permissions,
        updateUserDetails,
        wallet,
        verificationFee,
    }
})
