<script setup>
import {onMounted, ref} from "vue";

function generateRandomId(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let randomId = '';
    for (let i = 0; i < length; i++) {
        randomId += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return randomId;
}

const uuid = ref('')
const loading = ref(true)

onMounted(() => {
    uuid.value = generateRandomId(4);
    loading.value = false;
});
</script>

<template>
    <template v-if="loading">
        <p>This page is still loading. If it persists, please contact our call center</p>
    </template>
    <template v-else>
        <div
            class="accordion mb-4"
            :id="`${uuid}Example`"
        >
            <div
                class="accordion-item"
                style="border-color: rgba(29, 36, 46, 0.3)"
            >
                <h2 class="accordion-header" :id="`${uuid}headingOne`">
                    <button
                        class="d-flex justify-content-start align-items-center accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="`#${uuid}`"
                        aria-expanded="true"
                        :aria-controls="uuid"
                    >
                        <div
                            class="d-flex justify-content-between align-items-center"
                            style="width: 100vw"
                        >
                            <div>
                                <slot name="title"></slot>
                            </div>
                            <div>
                                <slot name="status">
                                    {{ $slots.status() }}
                                </slot>
                            </div>
                        </div>
                    </button>
                </h2>
                <div
                    :id="uuid"
                    class="accordion-collapse collapse"
                    :aria-labelledby="`${uuid}headingOne`"
                    :data-bs-parent="`#${uuid}Example`"
                >
                    <div class="accordion-body">
                        <div class="row">
                            <slot name="errors"></slot>
                        </div>
                        <div class="row">
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<style scoped>

</style>
