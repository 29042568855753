import {defineStore} from 'pinia'
import {ref} from "vue";

export const usePayoutsStore = defineStore('payoutsStore', () => {
    const payouts = ref([]);
    const selectedPayout = ref([]);
    const getPayouts = (page = 1, search = null) => {
        payouts.value = {};
    }

    const setPayouts = (payoutProps) => {
        payouts.value = payoutProps;
    }
    const setSelectedPayout = (order) => {
        selectedPayout.value = [];

        selectedPayout.value = {
            order: {...order?.additionalData?.order[0]},
            buyer: {...order?.additionalData?.order[0]?.buyer},
            seller: {...order?.additionalData?.order[0]?.seller},
            payout: {},
            transactionStatus: {...order?.metadata.transactionStatusDetails},
            mobileMoneyInfo: {...order?.metadata.transactionStatusDetails?.data.mobileMoneyInfo},
        }
    }

    return {
        payouts,
        getPayouts,
        setPayouts,
        setSelectedPayout,
        selectedPayout,
    }
})
