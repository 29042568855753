<script setup>
import SearchforSeller from "./searchforSeller.vue";
import {ref} from "vue";
import PaymentPurpose from "./PaymentPurpose.vue";
import PaymentSummary from "./PaymentSummary.vue";
import SendOTPForPayment from "./sendOTPForPayment.vue";

const props = defineProps(['borrowinglimit_clear', 'balance_clear'])
const user = ref({})
const product = ref({})
const payment = ref('')
const amount = ref('')
const quantity = ref('')
const paymentsummary = ref('')
const paymentMethod = ref('')
const makePayment = ref(false)
const sendOTPToUser = ref(false)
const otp = ref(false)
const errors = ref({})
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;


function setUser(data) {
  user.value = data;
  payment.value.purpose = '';
  payment.value.products = {};
  goToNextPage();
}

function goToNextPage() {
  document.getElementById("next").click();
}

function payments(enabled,method) {
  makePayment.value = enabled;
  paymentMethod.value = method
}

function sendOTP() {
    sendOTPToUser.value = true;
    let buyer = JSON.parse(document.querySelector('meta[name="user"]').content);
    let strAmount = currency + amount.value.toLocaleString();

    axios.post('/apis/v4/otp/send', {
        user_id: buyer.id,
        message: `You are about to pay ${strAmount} from your wallet. ${strAmount} will be removed from your wallet. To accept, enter this code {otp}.`,
        key: 'order_make_payment',
    }).then(function () {
        sendOTPToUser.value = true;
    });
}

function makeOrder() {
    let buyer = JSON.parse(document.querySelector('meta[name="user"]').content);

    axios
        .post("/apis/v4/otp/validate", {
            otp: otp.value,
            user_id: buyer.id,
            key: 'order_make_payment',
        }).then(() => {
            completeMakingOrder(buyer.id);
        }).catch((err) => {
            errors.value = err?.response?.data?.errors ?? '';
        });
}

function completeMakingOrder(buyerId) {
    axios.post('/api/requestPayment', {
        product: product.value,
        quantity: quantity.value,
        amount: amount.value,
        seller: user.value,
        user_id: buyerId,
        paymentMethod: paymentMethod.value,
    }).then(() => {
        errors.value = {};
        location.reload();
    }).catch(err => {
        errors.value = err?.response?.data?.errors ?? '';
    });
}

function check() {
  paymentsummary.value.updatePaymentMethod('wallet')
  paymentsummary.value.resetMethod()
}

function productset(productData) {
  product.value = productData;
}
</script>

<template>
  <div class="wizard-container">

    <div class="card wizard-card" data-color="green" id="wizardProfile">
      <form action="" method="">
        <!--        You can switch " data-color="orange" "  with one of the next bright colors: "blue", "green", "orange", "red", "azure"          -->

        <div class="wizard-header text-center">
          <h3 class="wizard-title">Request Payment</h3>
          <p class="category">Request a payment from a user.</p>
        </div>

        <div class="wizard-navigation">
          <div class="progress-with-circle">
            <div class="progress-bar" role="progressbar" aria-valuenow="1" aria-valuemin="1" aria-valuemax="3"
                 style="width: 21%;"></div>
          </div>
          <ul>
            <li>
              <a href="#about" data-toggle="tab">
                <div class="icon-circle">
                  <i class="ti-search"></i>
                </div>
                Search for Seller
              </a>
            </li>
            <li>
              <a href="#account" data-toggle="tab">
                <div class="icon-circle">
                  <i class="ti-folder"></i>
                </div>
                Select Product
              </a>
            </li>
            <li>
              <a href="#address" data-toggle="tab">
                <div class="icon-circle">
                  <i class="ti-receipt"></i>
                </div>
                Make Payment
              </a>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div class="tab-pane" id="about">
            <div class="row">
              <h5 class="info-text"></h5>
              <div class="col-12">
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <searchfor-seller @userSelected="setUser"></searchfor-seller>
              </div>

            </div>
          </div>
          <div class="tab-pane" id="account">
            <h5 class="info-text mt-3"> Select Purpose of payment </h5>
            <div class="row">
              <payment-purpose
                  :user="user"
                  ref="payment"
                  @setproduct="productset"
                  @amountsetevent="(e)=> amount = e"
                  @quantitysetevent="(e)=> quantity = e"
              ></payment-purpose>
            </div>
          </div>
          <div class="tab-pane" id="address">
            <div class="row">
              <div class="col-sm-12">
                <h5 class="info-text">  </h5>
              </div>
              <div class="col-12">
                <template v-if="sendOTPToUser">
                    <send-o-t-p-for-payment
                        :errors="errors"
                    @updatedotp="args => otp = args"
                    ></send-o-t-p-for-payment>
                </template>
                <template v-else>
                  <PaymentSummary
                      ref="paymentsummary"
                      @enablepayment="payments"
                      :product="product"
                      :amount="amount"
                      :quantity="quantity"
                      :borrowinglimit_clear="borrowinglimit_clear"
                      :balance_clear="balance_clear"
                  ></PaymentSummary>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="wizard-footer">
          <div class="d-flex justify-content-between">
            <div class="pull-left">
              <input type='button' class='btn btn-previous btn-default btn-wd' name='previous' value='Previous'/>
            </div>
            <div class="pull-right">
              <input @click="check" type='button' class='btn btn-next btn-fill btn-primary btn-wd' id="next"
                     name='next'
                      value='Next'/>
              <template v-if="sendOTPToUser">
                <input type='button' class='btn btn-finish btn-fill btn-primary btn-wd' name='finish'
                       :disabled="makePayment"
                       @click="makeOrder"
                       value="Finish"/>
              </template>
              <template v-else>
                <input type='button' class='btn btn-finish btn-fill btn-primary btn-wd' name='finish'
                       :disabled="makePayment"
                       @click="sendOTP"
                       value="Proceed"/>
              </template>

            </div>

          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>

</style>
