<script setup>
import {useAuthStore} from "../../../../../store/auth";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const props = defineProps(['user'])

const userStore = useAuthStore();
const user = userStore.user;

function copyToClipboard(text) {
    var input = document.createElement('textarea');
    input.style.position = 'fixed';
    input.style.opacity = 0;
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    var button = document.getElementById('copyButton');
    button.classList.add('copied');

    button.innerText = 'Referral code copied!';

    setTimeout(function () {
        button.classList.remove('copied');
    }, 500);
}
</script>

<template>
    <div class="card"
         style=" border-radius: 0.25rem; border: 0.2px solid #1d242e; box-shadow: none; padding: 1rem; background: #fff;">
        <div class="card-body"
             style=" padding: 0; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;">
            <div
                style=" width: 100%; padding: 0; display: flex; flex-direction: column; justify-content: center; align-items: flex-start; flex: 1;">
                <div
                    style=" display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; width: 100%; gap: 0.5rem;">
                    <h4 style="font-size: 18px; padding: 0; margin: 0">
                        <template v-if="!user.is_admin">
                            Share your referral code
                        </template>
                        <template v-else>
                          Your Referral Code
                        </template>
                    </h4>
                    <template v-if="!user.is_admin">
                        <a
                            href="/four/my-profile?referral_program=true"
                            class="btn btn-outline-secondary btn-sm"
                            style="
                                    border-radius: 1rem;
                                    font-size: 12px;
                                    color: #4f5d75;
                                    background: #f5f5f5;
                                    border: 1px solid #f5f5f5;
                                "
                        >
                            My referral dashboard
                            <i
                                style="transform: scale(0.6)"
                                class="fa-light fa-angle-right"
                            ></i>
                        </a>
                    </template>
                    <template v-else>
                        <a
                            href="/four/admin/referral"
                            class="btn btn-outline-secondary btn-sm"
                            style="
                                    border-radius: 1rem;
                                    font-size: 12px;
                                    color: #4f5d75;
                                    background: #f5f5f5;
                                    border: 1px solid #f5f5f5;
                                "
                        >
                            My referral dashboard
                            <i
                                style="transform: scale(0.6)"
                                class="fa-light fa-angle-right"
                            ></i>
                        </a>
                    </template>

                </div>
                <p style="padding: 0; margin: 0.5rem 0">
                    <template v-if="!user.is_admin">
                        Earn rewards on Amana Market by referring new users
                        and markets
                    </template>

                </p>
            </div>

            <div
                style=" display: flex; justify-content: space-between; align-items: center; gap: 0.3rem; width: 100%; background: #f5f5f5; border: 1px solid #f5f5f5; padding: 11px 24px; flex-wrap: wrap; border-radius: 6px; overflow-x: hidden;">
                <p
                    style=" margin: 0; font-weight: 600; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
                >
                    {{ user?.referral_code?.code }}
                </p>
                <div
                    style="
                                color: #00ab4d;
                                font-size: 12px;
                                display: flex;
                                justify-content: center;
                                gap: 0.2rem;
                                align-items: center;
                                cursor: pointer;
                            "
                    @click="
                                copyToClipboard(
                                    user?.referral_code?.code
                                )
                            "
                >
                    <i
                        class="fa-light fa-copy"
                        style="cursor: pointer; color: #00ab4d"
                    ></i>
                    <p id="copyButton" class="copycode" style="margin: 0; padding: 0">Copy code</p>
                </div>
            </div>

            <template v-if="!user.is_admin">
                <hr style="color: grey; width: 100%; margin: 1.5rem 0"/>

                <div
                    style="
                            width: 100%;
                            padding: 0;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            flex: 1;
                        "
                >
                    <div
                        style="
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                flex-wrap: wrap;
                                width: 100%;
                            "
                    >
                        <h4 style="font-size: 18px; padding: 0; margin: 0">
                            What is the “Referral Program”?
                        </h4>
                    </div>
                    <p style="padding: 0; margin: 0.5rem 0">
                        The referral program is a program that enables you
                        to earn money by making referrals (inviting) friends
                        and other markets to the platform
                    </p>
                </div>

                <hr style="color: grey; width: 100%; margin: 1.5rem 0"/>

                <div
                    style="
                            width: 100%;
                            padding: 0;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            flex: 1;
                        "
                >
                    <div
                        style="
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                flex-wrap: wrap;
                                width: 100%;
                            "
                    >
                        <h4 style="font-size: 18px; padding: 0; margin: 0">
                            Benefits of the “Referral Program”
                        </h4>
                    </div>

                    <p style="padding: 0; margin: 0.5rem 0">
                        When you refer people to use market services with
                        your referral code, you get:
                    </p>
                    <ol style="margin: 0">
                        <li style="color: #4f5d75; font-size: 14px">
                            {{ currency }}100 for each user who signs up and
                            gets verified on Amana Market
                        </li>
                        <li style="color: #4f5d75; font-size: 14px">
                            {{ currency }}4,000 per each subscription a
                            market leader pays for their market on Amana
                            Market
                        </li>
                    </ol>

                </div>
            </template>
        </div>
    </div>

</template>

<style scoped>

.btn {
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.copycode:hover {
//background-color: #0056b3 !important;
}

.copied {
    color: #00ab4d;
    animation: pulse 0.5s ease;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
</style>
