<script setup>
import { ref } from "vue";

let emits = defineEmits(["userSelected"]);

const showResults = ref(false);
const search = ref("");
const selectedUser = ref("");
const results = ref("");
const errors = ref({});

function searchPhoneNumber() {
    axios
        .post("/api/searchPhoneNumber", {
            phone: search.value,
        })
        .then((res) => {
            showResults.value = true;
            results.value = res.data;
        });
}

function selectUser(user) {
    selectedUser.value = user;
    emits("userSelected", user);
}
</script>

<template>
    <div>
        <div class="col-12">
            <label for="search">Phone Number of Recipient</label>
            <div class="input-group">
                <input
                    type="text"
                    class="form-control"
                    id="search"
                    v-model="search"
                    aria-describedby="search-help"
                    :class="[
                        errors.search ? 'is-invalid' : '',
                        !errors.search && Object.keys(errors).length > 1
                            ? 'is-valid'
                            : '',
                    ]"
                    placeholder="Search"
                    required
                />
                <span
                    class="input-group-btn"
                    @click.prevent="searchPhoneNumber"
                >
                    <button
                        class="btn btn-outline-dark"
                        style="border: transparent"
                        type="button"
                        aria-label="search"
                    >
                        Search
                        <i class="fa fa-search"></i>
                    </button>
                </span>
                <div class="invalid-feedback" v-if="errors.search">
                    {{ errors.search.toString() }}
                </div>
            </div>
            <div class="col-12" v-if="showResults">
                <template v-if="Object.keys(results).length < 1">
                    <div class="alert alert-danger" role="alert">
                        <strong>We could not find any results.</strong>
                    </div>
                </template>
                <template v-else>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Phone Number</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                :key="user.name"
                                v-for="(user, index) in results"
                            >
                                <td scope="row">{{ index + 1 }}</td>
                                <td>{{ user.name }}</td>
                                <td>{{ user.formattedphone }}</td>
                                <td>
                                    <button
                                        class="btn btn-outline-dark"
                                        @click.prevent="selectUser(user)"
                                    >
                                        Select
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped></style>

