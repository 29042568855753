<script setup>
import {onMounted, ref} from "vue";

const marketfees = ref('');
const feeChosen = ref('');
const showPaymentOptions = ref(false);
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const saving = ref(false);

onMounted(() => {
  getMarketFees();
});

function chooseFee(fee) {
  feeChosen.value = fee;
  showPaymentOptions.value = true;
}

function deductCharge() {
  saving.value = true;
  axios.post('/market/addFee',{
    fee_id: feeChosen.value,
  }).then(resp=>{
    saving.value = false;
    location.reload();
  }).catch(err=>{
    saving.value = false;
    errors.value = err.response.data.errors;
  });
}

function getMarketFees() {
  axios.get('/market/fees').then(resp => {
    marketfees.value = resp.data.fees;
  });
}
</script>

<template>
  <div>
    <div v-if="!showPaymentOptions">
      <table class="table table-bordered table-striped table-sm table-condensed">
        <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Amount</th>
          <th>Frequency</th>
          <th>Applied To</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="fees in marketfees">
          <td scope="row">{{ fees.name }}</td>
          <td>{{ fees.type }}</td>
          <td>{{ currency }}{{ fees.amount }}</td>
          <td>{{ fees.frequency }}</td>
          <td>
            <template v-if="fees.member_selection === 'phone'">
              {{ fees.phoneNumber }}
            </template>
            <template v-else>
              All Market Members
            </template>
          </td>
          <td>
            <button class="btn btn-primary" @click="chooseFee(fees)">
              Pay
            </button>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
    <div v-else>
      <div class="jumbotron">

        <table class="table table-bordered">
          <tbody>
          <tr>
            <th>Amount to be Deducted:</th>
            <td>{{currency}}{{ feeChosen.amount }}</td>
          </tr>
          <tr>
            <th>Fee Name:</th>
            <td>{{ feeChosen.name }}</td>
          </tr>
          <tr>
            <th>Fee Type</th>
            <td>{{ feeChosen.type}}</td>
          </tr>
          <tr>
            <th>Fee Frequency</th>
            <td>{{ feeChosen.frequency}}</td>
          </tr>
          <tr>
            <th>Applied to</th>
            <td>
              <template v-if="feeChosen.member_selection === 'phone'">
                {{feeChosen.phoneNumber}}
              </template>
              <template v-else>
                All Market Members
              </template>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="alert alert-success" role="alert">
          <strong>By confirming, {{currency}}{{feeChosen.amount}} will be deducted from your wallet balance.</strong>
        </div>
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary" @click.prevent="deductCharge">
            Confirm
            <i class="fa fa-spin fa-spinner" v-if="saving"></i>
            <i class="fa-duotone fa-check-to-slot text-white" v-else></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>