<script setup>

import {onBeforeUnmount, onMounted, ref, watch} from "vue";

const props = defineProps(['user']);

const emits = defineEmits(['productset','amountset', 'quantityset']);

onMounted(function () {
  getItems();
});

const products = ref('');
const product = ref('');
const loading = ref(true);
const amount = ref('');
const quantity = ref('');
const errors = ref({});
const showExtraInformation = ref(false);
const currencySign = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN

watch(amount, async (newAmount, oldAmount) => {
  emits('amountset',newAmount);
})
watch(quantity, async (newAmount, oldAmount) => {
  emits('quantityset',newAmount);
})
function getItems() {
  axios.post(`/api/getItems/product_and_services`, {
    user_id: props.user.id
  }).then(res => {
    products.value = res.data;
  });
}

function selectProduct(productData) {
  product.value = productData;
  showExtraInformation.value = true;
  emits('productset',productData)
}

</script>

<template>
  <div>
    <div class="jumbotron jumbotron-fluid">
      <div class="container-fluid">
        <template v-if="showExtraInformation">
          <div class="card">
            <div class="card-body">
              <div class="row">
              <div class="col-md-12">
                <table class="table table-sm table-bordered table-card">
                  <tbody>
                  <tr>
                    <th>Product:</th>
                    <td>{{ product.product.name }}</td>
                  </tr>
                  <tr>
                    <th>Measurement Unit:</th>
                    <td>{{ product.measurement_unit.name }}</td>
                  </tr>
                  <tr>
                    <th>Market:</th>
                    <td>{{ product.group.name }}</td>
                  </tr>
                  <tr>
                    <th>Sellers Price:</th>
                    <td>
<!--                      {{import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN}}-->
                      {{currencySign}}{{ product.amount }}
                    </td>
                  </tr>
<!--                  <tr>-->
<!--                    <th>Recommended Price:</th>-->
<!--                    <td>-->
<!--                      <small class="text-muted">Implement this</small>-->
<!--                    </td>-->
<!--                  </tr>-->
<!--                  <tr>-->
<!--                    <th>Last Traded Price:</th>-->
<!--                    <td>-->
<!--                      <small class="text-muted">Implement this</small>-->
<!--                    </td>-->
<!--                  </tr>-->
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <!--Label: Amount, Attributes:amount -->
                <div class="form-group">
                  <label for="amount">Amount</label>
                  <input type="number" class="form-control" id="amount" v-model="amount"
                         aria-describedby="amount-help"
                         :class="[errors.amount ? 'is-invalid': '',!errors.amount && Object.keys(errors).length > 1 ? 'is-valid': '']"
                         placeholder="Amount" required>
                  <div class="invalid-feedback" v-if="errors.amount">
                    {{ errors.amount.toString() }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!--Label: Quantity, Attributes:quantity -->
                <div class="form-group">
                  <label for="quantity">Quantity</label>
                  <input type="number" class="form-control" id="quantity" v-model="quantity"
                         aria-describedby="quantity-help"
                         :class="[errors.quantity ? 'is-invalid': '',!errors.quantity && Object.keys(errors).length > 1 ? 'is-valid': '']"
                         placeholder="Quantity" required>
                  <div class="invalid-feedback" v-if="errors.quantity">
                    {{ errors.quantity.toString() }}
                  </div>
                </div>
              </div>
            </div></div>
          </div>
        </template>
        <template v-else>
          <table class="table">
            <thead>
            <tr>
              <th>Product</th>
              <th></th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(product,index) in products">
              <td scope="row">{{ index + 1 }}</td>
              <td>
                {{ product.product.name }}
                <span class="text-muted">
{{ product.measurement_unit.name }}
                      </span>
                {{ product.grade }}
              </td>
              <td>
                <button class="btn btn-primary" @click.prevent="selectProduct(product)">
                  Select Product
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>