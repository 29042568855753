<script setup>
import { computed, ref } from "vue";

const props = defineProps({
    sendOtp: Function
});

const COUNT_TIME = 120;

const countdown = ref(COUNT_TIME); // Initial countdown for 2 minutes
let intervalId = null;

const formattedTime = computed(() => {
    const minutes = Math.floor(countdown.value / 60);
    const seconds = countdown.value % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')} ${minutes > 0 ? 'minutes' : 'seconds'}`;
});

const startTimer = () => {
    if (countdown.value <= 0) {
       countdown.value = COUNT_TIME;
    }
    intervalId = setInterval(() => {
        countdown.value--;
        if (countdown.value <= 0) {
            clearInterval(intervalId);
        }
    }, 1000);
};

const stopTimer = () => {
    clearInterval(intervalId);
    countdown.value = COUNT_TIME;
    intervalId = null;
};

function handleSend() {
    if (countdown.value === 0 || !intervalId) props.sendOtp();
}

defineExpose({
    startTimer,
    stopTimer,
});
</script>

<template>
    <div class="resend-otp-container">
        <div class="countdown-btn-wrapper">
            <span
                v-if="countdown === 0 || !intervalId"
                class="text-sm pointer-event countdown-btn disabled"
                @click.prevent="handleSend">
                Resend OTP
            </span>
        </div>
        <div class="resend-counter">
            {{ countdown === 0 ? 'click above to resend OTP again' : `You can resend OTP after ${formattedTime}`}}
        </div>
    </div>
</template>

<style scoped>
.countdown-btn {
    cursor: pointer;
    text-decoration: underline;
}
.resend-otp-container {
    font-size: 0.7em;
    margin-right: auto;
}
</style>
