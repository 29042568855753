<script setup>
import SendOTPForPayment from "./wallet/payments/sendOTPForPayment.vue";
import {ref} from "vue";
import Swal from "sweetalert2";

const props = defineProps([
    'user',
    'phone',
    'user_id',
    'id'
]);

const mode = ref('');
const otp = ref('');
const errors = ref({});

const account_name = ref("");
const account_number = ref("");
const bank_code = ref("");

const saving = ref(false);

function sendOTP(data) {
    mode.value = data;
    axios.post('/apis/v4/otp/send', {
        'phone': props.phone,
        'user_id': props.user.id,
        'message': `Your one-time password to ${data} payment on Amana Market is {otp} and is valid for the next 2 minutes.`,
        'key': 'accept_or_reject_order',
    })
        .then(function (response) {
            // console.log(response); commented for security reasons
        })
        .catch(function (error) {
            // console.log(error); // commented for security reasons
        });
}

function complete() {
    saving.value = true;

    axios.post('/apis/v4/otp/validate', {
        'otp': otp.value,
        'user_id': props.user.id,
        'key': 'accept_or_reject_order',
    }).then(() => {

        if (mode.value === 'accept') {
            approvePayment();

            return;
        }

        rejectPayment();

    }).catch((error) => {
        saving.value = false;
        errors.value = error?.response?.data?.errors ?? '';
    });
}

function approvePayment(){

    axios.post(`/four/admin/orders/approve`, {
        'otp': otp.value,
        'user_id': props.user.id,
        'id': props.id,
    }).then(resp => {
        window.location.href += "?approved=1";
    }).catch(err => {
        let valError = err.response.data.errors;
        errors.value = valError ? valError : "";

        Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Error accepting payment. Please try again and contact our support team if problem persists.',
            timer: 9000
        });
    }).finally(() => {
        saving.value = false;
    });
}

function rejectPayment(){

    axios.post(`/four/admin/orders/approve?reject=1`, {
        'user_id': props.user.id,
        'id': props.id,
    }).then(resp => {
        location.reload();
    }).catch(err => {
        let valError = err.response.data.errors;
        errors.value = valError ? valError : "";

        Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Error rejecting payment. Please try again and contact our support team if problem persists.',
            timer: 9000
        });

    }).finally(() => {
        saving.value = false;
    });
}

</script>

<template>
    <button
        @click="sendOTP('accept')"
        type="button"
        class="btn btn-primary btn-sm radius-30 px-4"
        data-bs-toggle="modal"
        data-bs-target="#accept"
    >
        Accept
    </button>
    <button @click="sendOTP('reject')" type="button" class="btn mx-2 btn-outline-danger btn-sm radius-30 px-4 mt-2"
            data-bs-toggle="modal"
            data-bs-target="#accept">
        Reject
    </button>

    <!-- Modal -->
    <div class="modal fade" id="accept" tabindex="-1" role="dialog"
         aria-labelledby="accept" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="accept">
                        <template v-if="mode==='accept'">Accepting Order</template>
                        <template v-else>Rejecting Order</template>
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close">
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="mode==='accept'">
                        <div class="alert bg-light-success" role="alert">
                            <strong>By Accepting this order. Money will be transferred into your wallet.</strong>
                        </div>
                    </div>
                    <div v-else>
                        <div class="alert bg-light-danger" role="alert">
                            <strong>By Rejecting, Money will be returned to the buyer's wallet.</strong>
                        </div>
                    </div>
                    <send-o-t-p-for-payment
                        :errors="errors"
                        @updatedotp="args => otp = args"
                    ></send-o-t-p-for-payment>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                            data-bs-dismiss="modal">Close
                    </button>
                    <button type="button"
                            :disabled="saving"
                            class="btn btn-success" @click.prevent="complete" v-if="mode==='accept'">
                        Accept
                        <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                    </button>
                    <button type="button" class="btn btn-danger"
                            :disabled="saving"
                            @click.prevent="complete" v-else>
                        Reject
                        <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
