<script setup>
import {useFetch} from "@vueuse/core/index";
import {ref} from "vue";

const props = defineProps(['marketid']);
const url = ref(`/apis/v4/admin/onboarding/${props.marketid}?include=Orders.Buyer,Orders.Seller`)
const {isFetching, data, error} = useFetch(url, {
    refetch: true
}).json();


</script>

<template>
    <div v-if="error">
        We encountered an error while fetching orders
    </div>
    <div v-else-if="isFetching">
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Product</th>
                    <th>Buyer</th>
                    <th>Seller</th>
                    <th>Total</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(order,index) in data?.orders">
                    <td scope="row">{{ order.order_id }}</td>
                    <td>{{ order.summary_line }}</td>
                    <td>{{ order.buyer?.name }}</td>
                    <td>{{ order.seller?.name }}</td>
                    <td>{{ order.items_price }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style scoped>

</style>
