<script setup>
import {ref} from "vue";
import Swal from "sweetalert2";

const props = defineProps(['user_id']);

const errors = ref([])
const password = ref('');
const updatingPassword = ref(false);

const updatePassword = () => {
    updatingPassword.value = true;
    axios.post('/apis/v4/admin/userAdministration', {
        'action': 'changePassword',
        'password': password.value,
        'user_id': props.user_id.id
    }).then(resp => {
        Swal.fire({
            'text': "Password has been updated.",
            'position': 'top-end',
            showConfirmButton: false,
            showCloseButton: false,
            timer: 1000
        })
    }).catch(err => {
        errors.value = err.response.data.errors;
    }).finally(() => {
        updatingPassword.value = false;
    })
}

const updatingUserInfo = ref(false);
const first_name = ref(props.user_id.first_name ?? '');
const last_name = ref(props.user_id.last_name);
const email = ref(props.user_id.email ?? '');
const gender = ref(props.user_id.gender ?? '');
const bvn = ref(props.user_id.bvn ?? '');
const krapin = ref(props.user_id.kra_pin ?? '');
const nationalid = ref(props.user_id.national_id ?? '');
const updateUserInfo = () => {
    updatingUserInfo.value = true;
    axios.post('/apis/v4/admin/userAdministration', {
        'action': 'changeUserInfo',
        'first_name': first_name.value,
        'last_name': last_name.value,
        'email': email.value,
        'gender': gender.value,
        'user_id': props.user_id.id,
        'bvn': bvn.value,
        'krapin': krapin.value,
        'nationalid': nationalid.value,
    }).then(resp => {
        Swal.fire({
            'text': "User Information has been updated.",
            'position': 'top-end',
            showConfirmButton: false,
            showCloseButton: false,
            timer: 1000
        })
    }).catch(err => {
        errors.value = err.response.data.errors;
        updatingUserInfo.value = false;
    }).finally(() => {
        updatingUserInfo.value = false;
    })
}
</script>

<template>
    <div class="row">
        <div class="col-12">
            <!--<div class="row">
                <div class="col-md-4">
                    <h6>Change Password</h6>
                </div>
                <div class="col-md-8">
                    <form autocomplete="off">
                        <div class="row gap-2">
                            <div class="col-12">
                                Label: Password, Attributes:password
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="password" v-model="password"
                                           aria-describedby="password-help"
                                           autocomplete="off"
                                           :class="[errors.password ? 'is-invalid': '',!errors.password && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                    >
                                    <label for="password">New Password</label>

                                    <div class="invalid-feedback" v-if="errors.password">
                                        {{ errors.password.toString() }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="col-12 d-flex justify-content-end">
                                    <button class="btn btn-outline-primary btn-sm" :disabled="updatingPassword"
                                            @click="updatePassword">
                                        Update Password
                                        <i class="fa fa-spin fa-spinner" v-if="updatingPassword"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <hr>-->
            <div class="row tw-mt-5">
                <div class="col-md-4">
                    <h6>Change User Details</h6>
                </div>
                <div class="col-md-8">
                    <form autocomplete="off">
                        <div class="row gap-3">
                            <div class="col-12">
                                <!--Label: First Name, Attributes:first_name -->
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="first_name" v-model="first_name"
                                           aria-describedby="first_name-help"
                                           :class="[errors.first_name ? 'is-invalid': '',!errors.first_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                    >
                                    <label for="first_name">First Name</label>

                                    <div class="invalid-feedback" v-if="errors.first_name">
                                        {{ errors.first_name.toString() }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <!--Label: Last Name, Attributes:last_name -->
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="last_name" v-model="last_name"
                                           aria-describedby="last_name-help"
                                           :class="[errors.last_name ? 'is-invalid': '',!errors.last_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                    >
                                    <label for="last_name">Last Name</label>
                                    <div class="invalid-feedback" v-if="errors.last_name">
                                        {{ errors.last_name.toString() }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <!--Label: Email, Attributes:email -->
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="email" v-model="email"
                                           aria-describedby="email-help"
                                           :class="[errors.email ? 'is-invalid': '',!errors.email && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                    >
                                    <label for="email">Email</label>

                                    <div class="invalid-feedback" v-if="errors.email">
                                        {{ errors.email.toString() }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <!--Label: BVN, Attributes:bvn -->
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="bvn" v-model="bvn"
                                           aria-describedby="bvn-help"
                                           :class="[errors.bvn ? 'is-invalid': '',!errors.bvn && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                          >
                                    <label for="bvn">BVN</label>
                                    <div class="invalid-feedback" v-if="errors.bvn">
                                        {{ errors.bvn.toString() }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <!--Label: KRA PIN, Attributes:krapin -->
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="krapin" v-model="krapin"
                                           aria-describedby="krapin-help"
                                           :class="[errors.krapin ? 'is-invalid': '',!errors.krapin && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                           >
                                    <label for="krapin">KRA PIN</label>
                                    <div class="invalid-feedback" v-if="errors.krapin">
                                        {{ errors.krapin.toString() }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <!--Label: National ID, Attributes:nationalid -->
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="nationalid" v-model="nationalid"
                                           aria-describedby="nationalid-help"
                                           :class="[errors.nationalid ? 'is-invalid': '',!errors.nationalid && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                           >
                                    <label for="nationalid">National ID</label>
                                    <div class="invalid-feedback" v-if="errors.nationalid">
                                        {{ errors.nationalid.toString() }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <!--Label: Gender, Attributes:gender -->
                                <div class="form-floating">
                                    <select type="text" class="form-control form-select" id="gender" v-model="gender"
                                            aria-describedby="gender-help"
                                            :class="[errors.gender ? 'is-invalid': '',!errors.gender && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                    >
                                        <option value="" disabled>----</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                    <label for="gender">Gender</label>

                                    <div class="invalid-feedback" v-if="errors.gender">
                                        {{ errors.gender.toString() }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="col-12 d-flex justify-content-end">
                                    <button class="btn btn-outline-primary btn-sm" @click="updateUserInfo"
                                            :disabled="updatingUserInfo">
                                        Update
                                        <i class="fa fa-spin fa-spinner" v-if="updatingUserInfo"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
