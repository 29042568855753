import {useAuthStore} from "./auth";
import _ from 'lodash'
export function usePermissions() {

    function $can(permissionName) {
        const { user,permissions } = useAuthStore();

        if ($isAdmin() === true) return true;
        if (permissions === null || Object.entries(permissions).length === 0) {
            return false;
        }
        if (Array.isArray(permissionName)) {
            return permissionName.some(p => permissions.indexOf(p) !== -1);
        }
       let permissionFound = _.find(permissions, {name:permissionName});

        if (permissionFound === undefined) {
            return false;
        }
        return Object.keys(permissionFound).length > 0;
    }

    function $isAdmin() {

        const { user } = useAuthStore();

        return user?.is_admin;

    }



    return { $can, $isAdmin };
}
