<script setup>
import { onMounted, ref } from "vue";
import Swal from "sweetalert2";
import Subscription from "../subscriptions/Subscription.vue";

const props = defineProps(["market", "showUpgrade"]);
const websiteData = ref([]);
const editWebsiteInfo = ref(false);
const errors = ref([]);

/*Form*/
const header_section = ref(websiteData.value.header);
const sub_header = ref("");
const about_section = ref("");
const website_logo = ref("");
const website_banner = ref("");
const facebook = ref("");
const instagram = ref("");
const twitter = ref("");
const header = ref("");
/*End Form*/

onMounted(() => {
    getDetails();
    showUpgrades.value = props.showUpgrade;
});
const editSite = () => {
    editWebsiteInfo.value = !editWebsiteInfo.value;
};
const getDetails = () => {
    axios
        .get(`/apis/v4/saas/market-websites?user_group_id=${props.market.id}`)
        .then((resp) => {
            websiteData.value = resp.data.data[0];
            header_section.value = websiteData.value?.header;
            sub_header.value = websiteData.value?.sub_header;
            about_section.value = websiteData.value?.about;
            // website_logo.value = websiteData.value.logo;
            // website_banner.value = websiteData.value.banner;
            facebook.value = websiteData.value?.social_links?.facebook;
            instagram.value = websiteData.value?.social_links?.instagram;
            twitter.value = websiteData.value?.social_links?.twitter;
        });
};

const uploadwebsiteLogo = ref("");
const uploadLogo = () => {
    const fileInput = uploadwebsiteLogo.value;

    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!allowedTypes.includes(fileInput.files[0].type)) {
        Swal.fire({
            position: "top-end",
            toast: true,
            icon: "error",
            title: "Upload only images.",
            showConfirmButton: false,
            timer: 2000,
        });
        return;
    }

    // Check file size (in bytes)
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
    if (fileInput.files[0].size > maxSizeInBytes) {
        Swal.fire({
            position: "top-end",
            toast: true,
            icon: "error",
            title: "Limited to a maximum of 5MB.",
            showConfirmButton: false,
            timer: 2000,
        });
        return;
    }

    website_logo.value = fileInput.files[0];
};

const uploadwebsiteBanner = ref("");
const uploadBanner = () => {
    const fileInput = uploadwebsiteBanner.value;

    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!allowedTypes.includes(fileInput.files[0].type)) {
        Swal.fire({
            position: "top-end",
            toast: true,
            icon: "error",
            title: "Upload only images.",
            showConfirmButton: false,
            timer: 2000,
        });
        return;
    }

    // Check file size (in bytes)
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
    if (fileInput.files[0].size > maxSizeInBytes) {
        Swal.fire({
            position: "top-end",
            toast: true,
            icon: "error",
            title: "Limited to a maximum of 5MB.",
            showConfirmButton: false,
            timer: 2000,
        });
        return;
    }

    website_banner.value = fileInput.files[0];
};
const saveSite = () => {
    let formData = new FormData();
    formData.append("user_group_id", props.market.id);
    formData.append("header", header_section.value);
    formData.append("sub_header", sub_header.value);
    formData.append("about", about_section.value);
    formData.append("theme_color", "");
    formData.append("domain", "");
    formData.append("_method", "PUT");
    formData.append("is_custom_domain", "");
    if (website_logo.value) {
        formData.append("logo", website_logo.value);
    }
    if (website_banner.value) {
        formData.append("banner", website_banner.value);
    }
    formData.append("social_links[facebook]", facebook.value);
    formData.append("social_links[instagram]", instagram.value);
    formData.append("social_links[twitter]", twitter.value);
    axios
        .post(`/apis/v4/saas/market-websites/${websiteData.value.id}`, formData)
        .then((resp) => {
            editWebsiteInfo.value = false;
            getDetails();
        });
};
const showUpgrades = ref("");

const updateupgrade = (e) => {
    axios.post("/api/markaspaid", e).then(() => {
        location.href = "/four/my-profile?website=true";
    });
};
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;

</script>

<template>
    <div v-if="showUpgrade">
        <Subscription
            @done="updateupgrade"
            :currencysign="currency"
            :market_id="props.market.id"
            :email="`info@coamana.com`"
            :uniqueid="`1243`"
        ></Subscription>
    </div>
    <div v-else>
        <div class="card bg-blue-100 mb-3">
            <div class="card-body">
                <div>
                    <div
                        class="lg:tw-flex lg:tw-items-center lg:tw-justify-between"
                    >
                        <div class="tw-min-w-0 tw-flex-1">
                            <h2
                                class="tw-text-2xl tw-font-bold tw-leading-7 tw-text-gray-900 sm:tw-truncate sm:tw-text-3xl sm:tw-tracking-tight"
                            >
                                Website Information
                            </h2>
                            <div
                                class="tw-mt-1 tw-flex tw-flex-col sm:tw-mt-0 sm:tw-flex-row sm:tw-flex-wrap sm:tw-space-x-6"
                            ></div>
                        </div>
                        <div class="tw-mt-5 tw-flex lg:tw-ml-4 lg:tw-mt-0">
                            <span class="tw-hidden sm:tw-block">
                                <a
                                    @click.prevent="editSite"
                                    v-if="!editWebsiteInfo"
                                    class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50"
                                >
                                    <svg
                                        class="tw--ml-0.5 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            d="M2.695 14.763l-1.262 3.154a.5.5 0 00.65.65l3.155-1.262a4 4 0 001.343-.885L17.5 5.5a2.121 2.121 0 00-3-3L3.58 13.42a4 4 0 00-.885 1.343z"
                                        />
                                    </svg>
                                    Edit
                                </a>
                                <a
                                    @click.prevent="saveSite"
                                    v-else
                                    class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="tw--ml-0.5 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12"
                                        />
                                    </svg>

                                    Save
                                </a>
                            </span>

                            <span class="tw-ml-3 tw-hidden sm:tw-block">
                                <a
                                    :href="`https://${websiteData?.domain}`"
                                    target="_blank"
                                    class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50"
                                >
                                    <svg
                                        class="tw--ml-0.5 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            d="M12.232 4.232a2.5 2.5 0 013.536 3.536l-1.225 1.224a.75.75 0 001.061 1.06l1.224-1.224a4 4 0 00-5.656-5.656l-3 3a4 4 0 00.225 5.865.75.75 0 00.977-1.138 2.5 2.5 0 01-.142-3.667l3-3z"
                                        />
                                        <path
                                            d="M11.603 7.963a.75.75 0 00-.977 1.138 2.5 2.5 0 01.142 3.667l-3 3a2.5 2.5 0 01-3.536-3.536l1.225-1.224a.75.75 0 00-1.061-1.06l-1.224 1.224a4 4 0 105.656 5.656l3-3a4 4 0 00-.225-5.865z"
                                        />
                                    </svg>
                                    View
                                </a>
                            </span>

                            <!-- Dropdown -->
                            <div class="tw-relative tw-ml-3 sm:tw-hidden">
                                <button
                                    type="button"
                                    class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-ring-gray-400"
                                    id="mobile-menu-button"
                                    aria-expanded="false"
                                    aria-haspopup="true"
                                >
                                    More
                                    <svg
                                        class="tw--mr-1 tw-ml-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </button>

                                <!--
                                  Dropdown menu, show/hide based on menu state.

                                  Entering: "tw-transition tw-ease-out tw-duration-200"
                                    From: "tw-transform tw-opacity-0 tw-scale-95"
                                    To: "tw-transform tw-opacity-100 tw-scale-100"
                                  Leaving: "tw-transition tw-ease-in tw-duration-75"
                                    From: "tw-transform tw-opacity-100 tw-scale-100"
                                    To: "tw-transform tw-opacity-0 tw-scale-95"
                                -->
                                <div
                                    class="tw-absolute tw-right-0 tw-z-10 tw--mr-1 tw-mt-2 tw-w-48 tw-origin-top-right tw-rounded-md tw-bg-white tw-py-1 tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none"
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="mobile-menu-button"
                                    tabindex="-1"
                                >
                                    <!-- Active: "tw-bg-gray-100", Not Active: "" -->
                                    <a
                                        href="#"
                                        class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700"
                                        role="menuitem"
                                        tabindex="-1"
                                        id="mobile-menu-item-0"
                                        >Edit</a
                                    >
                                    <a
                                        href="#"
                                        class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700"
                                        role="menuitem"
                                        tabindex="-1"
                                        id="mobile-menu-item-1"
                                        >View</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <template v-if="editWebsiteInfo">
                    <div class="row">
                        <div class="row tw-py-12">
                            <div class="col-md-4">
                                <h6>Header Section</h6>
                                <p class="text-muted">
                                    The header section information will be
                                    displayed at the topmost part of your
                                    website.
                                </p>
                            </div>
                            <div class="col-md-8">
                                <!--Label: Header Section, Attributes:header_section -->
                                <div class="form-floating">
                                    <textarea
                                        type="text"
                                        class="form-control"
                                        id="header_section"
                                        v-model="header_section"
                                        aria-describedby="header_section-help"
                                        :class="[
                                            errors.header_section
                                                ? 'is-invalid'
                                                : '',
                                            !errors.header_section &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                        style="height: 100px"
                                    >
                                    </textarea>
                                    <label for="header_section"
                                        >Header Section</label
                                    >
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.header_section"
                                    >
                                        {{ errors.header_section.toString() }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row tw-py-12">
                            <div class="col-md-4">
                                <h6>Sub Header Section</h6>
                                <p class="text-muted">
                                    The sub header section information will be
                                    displayed just below the header section of
                                    your website.
                                </p>
                            </div>
                            <div class="col-md-8">
                                <!--Label: Sub Header, Attributes:sub_header -->
                                <div class="form-floating">
                                    <textarea
                                        type="text"
                                        class="form-control"
                                        id="sub_header"
                                        v-model="sub_header"
                                        aria-describedby="sub_header-help"
                                        :class="[
                                            errors.sub_header
                                                ? 'is-invalid'
                                                : '',
                                            !errors.sub_header &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                        style="height: 100px"
                                    >
                                    </textarea>
                                    <label for="sub_header">Sub Header</label>

                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.sub_header"
                                    >
                                        {{ errors.sub_header.toString() }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row tw-py-12">
                            <div class="col-md-4">
                                <h6>About Section</h6>
                                <p class="text-muted">
                                    The site will include a specific "About"
                                    section, offering visitors clear details
                                    about the platform's purpose and relevant
                                    information.
                                </p>
                            </div>
                            <div class="col-md-8">
                                <!--Label: About Section, Attributes:about_section -->
                                <div class="form-floating">
                                    <textarea
                                        class="form-control"
                                        id="about_section"
                                        v-model="about_section"
                                        aria-describedby="about_section-help"
                                        :class="[
                                            errors.about_section
                                                ? 'is-invalid'
                                                : '',
                                            !errors.about_section &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                        style="height: 100px"
                                    >
                                    </textarea>
                                    <label for="about_section"
                                        >About Section</label
                                    >

                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.about_section"
                                    >
                                        {{ errors.about_section.toString() }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row tw-py-12">
                            <div class="col-md-4">
                                <h6>Website Logo</h6>
                                <p class="text-muted">
                                    Utilize this as your logo for the market
                                    website, serving as a distinctive visual
                                    representation of your brand identity.
                                </p>
                            </div>
                            <div class="col-md-8">
                                <!--Label: Website Logo, Attributes:website_logo -->
                                <div class="form-group">
                                    <label for="website_logo"
                                        >Website Logo</label
                                    >
                                    <input
                                        type="file"
                                        class="form-control"
                                        id="website_logo"
                                        @change="uploadLogo"
                                        ref="uploadwebsiteLogo"
                                        accept="image/*"
                                        aria-describedby="website_logo-help"
                                        :class="[
                                            errors.website_logo
                                                ? 'is-invalid'
                                                : '',
                                            !errors.website_logo &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                        placeholder="Website Logo"
                                        required
                                    />
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.website_logo"
                                    >
                                        {{ errors.website_logo.toString() }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row tw-py-12">
                            <div class="col-md-4">
                                <h6>Website Image/Banner</h6>
                                <p class="text-muted">
                                    Craft a compelling market website banner
                                    with vibrant visuals and concise text,
                                    captivating your audience and communicating
                                    your unique value.
                                </p>
                            </div>
                            <div class="col-md-8">
                                <!--Label: Website Banner, Attributes:website_banner -->
                                <div class="form-group">
                                    <label for="website_banner"
                                        >Website Banner</label
                                    >
                                    <input
                                        type="file"
                                        class="form-control"
                                        id="website_banner"
                                        @change="uploadBanner"
                                        ref="uploadwebsiteBanner"
                                        accept="image/*"
                                        aria-describedby="website_banner-help"
                                        :class="[
                                            errors.website_banner
                                                ? 'is-invalid'
                                                : '',
                                            !errors.website_banner &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                        placeholder="Website Banner"
                                        required
                                    />
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.website_banner"
                                    >
                                        {{ errors.website_banner.toString() }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row tw-py-12">
                            <div class="col-md-4">
                                <h6>Social Media Links</h6>
                                <p class="text-muted">
                                    Add your social links that will be used on
                                    your site.
                                </p>
                            </div>
                            <div class="col-md-8">
                                <div class="col-12">
                                    <!--Label: Facebook, Attributes:facebook -->
                                    <div class="form-group">
                                        <!--                                        <label for="facebook">Facebook</label>-->
                                        <div class="input-group mb-3">
                                            <span
                                                class="input-group-text"
                                                id="basic-addon1"
                                            >
                                                <i
                                                    class="fa-brands fa-facebook"
                                                ></i>
                                            </span>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="facebook"
                                                v-model="facebook"
                                                aria-describedby="facebook-help"
                                                :class="[
                                                    errors.facebook
                                                        ? 'is-invalid'
                                                        : '',
                                                    !errors.facebook &&
                                                    Object.keys(errors).length >
                                                        1
                                                        ? 'is-valid'
                                                        : '',
                                                ]"
                                                placeholder="Facebook"
                                                required
                                            />
                                        </div>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.facebook"
                                        >
                                            {{ errors.facebook.toString() }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <!--Label: Instagram, Attributes:instagram -->
                                    <div class="form-group">
                                        <div class="input-group mb-3">
                                            <span
                                                class="input-group-text"
                                                id="basic-addon1"
                                            >
                                                <i
                                                    class="fa-brands fa-instagram"
                                                ></i>
                                            </span>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="instagram"
                                                v-model="instagram"
                                                aria-describedby="instagram-help"
                                                :class="[
                                                    errors.instagram
                                                        ? 'is-invalid'
                                                        : '',
                                                    !errors.instagram &&
                                                    Object.keys(errors).length >
                                                        1
                                                        ? 'is-valid'
                                                        : '',
                                                ]"
                                                placeholder="Instagram"
                                                required
                                            />
                                        </div>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.instagram"
                                        >
                                            {{ errors.instagram.toString() }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <!--Label: Twitter, Attributes:twitter -->
                                    <div class="form-group">
                                        <div class="input-group mb-3">
                                            <span
                                                class="input-group-text"
                                                id="basic-addon1"
                                            >
                                                <i
                                                    class="fa-brands fa-twitter"
                                                ></i>
                                            </span>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="twitter"
                                                v-model="twitter"
                                                aria-describedby="twitter-help"
                                                :class="[
                                                    errors.twitter
                                                        ? 'is-invalid'
                                                        : '',
                                                    !errors.twitter &&
                                                    Object.keys(errors).length >
                                                        1
                                                        ? 'is-valid'
                                                        : '',
                                                ]"
                                                placeholder="Twitter"
                                                required
                                            />
                                        </div>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.twitter"
                                        >
                                            {{ errors.twitter.toString() }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="row">
                        <!--                  <div class="tw-px-4 sm:tw-px-0">-->
                        <!--                      <h3 class="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">Website Information</h3>-->
                        <!--                      <p class="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-6 tw-text-gray-500">Details about your market-->
                        <!--                          website.</p>-->
                        <!--                  </div>-->
                        <div class="tw-mt-6 tw-border-t tw-border-gray-100">
                            <dl class="tw-divide-y tw-divide-gray-100">
                                <div
                                    class="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0"
                                >
                                    <dt
                                        class="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                                    >
                                        Header Section
                                    </dt>
                                    <dd
                                        class="tw-mt-1 tw-flex tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0"
                                    >
                                        <span class="tw-flex-grow">{{
                                            websiteData?.header
                                        }}</span>
                                        <span class="tw-ml-4 tw-flex-shrink-0">
                                        </span>
                                    </dd>
                                </div>
                                <div
                                    class="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0"
                                >
                                    <dt
                                        class="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                                    >
                                        Sub Header
                                    </dt>
                                    <dd
                                        class="tw-mt-1 tw-flex tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0"
                                    >
                                        <span class="tw-flex-grow">{{
                                            websiteData?.sub_header
                                        }}</span>
                                        <span class="tw-ml-4 tw-flex-shrink-0">
                                        </span>
                                    </dd>
                                </div>
                                <div
                                    class="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0"
                                >
                                    <dt
                                        class="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                                    >
                                        About Section
                                    </dt>
                                    <dd
                                        class="tw-mt-1 tw-flex tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0"
                                    >
                                        <span class="tw-flex-grow">
                                            {{ websiteData?.about }}
                                        </span>
                                        <span class="tw-ml-4 tw-flex-shrink-0">
                                        </span>
                                    </dd>
                                </div>
                                <div
                                    class="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0"
                                >
                                    <dt
                                        class="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                                    >
                                        Website Logo
                                    </dt>
                                    <dd
                                        class="tw-mt-1 tw-flex tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0"
                                    >
                                        <span class="tw-flex-grow">
                                            <img
                                                class="tw-object-contain tw-h-24"
                                                :src="`https://amanamarkettemp.s3.amazonaws.com/${websiteData?.logo}`"
                                                alt=""
                                            />
                                        </span>
                                        <span class="tw-ml-4 tw-flex-shrink-0">
                                        </span>
                                    </dd>
                                </div>
                                <div
                                    class="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0"
                                >
                                    <dt
                                        class="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                                    >
                                        Website Image
                                    </dt>
                                    <dd
                                        class="tw-mt-1 tw-flex tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0"
                                    >
                                        <span class="tw-flex-grow">
                                            <img
                                                class="tw-object-contain tw-h-24"
                                                :src="`https://amanamarkettemp.s3.amazonaws.com/${websiteData?.banner}`"
                                                alt=""
                                            />
                                        </span>
                                        <span class="tw-ml-4 tw-flex-shrink-0">
                                        </span>
                                    </dd>
                                </div>
                                <div
                                    class="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0"
                                >
                                    <dt
                                        class="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                                    >
                                        Social Links
                                    </dt>
                                    <dd
                                        class="tw-mt-1 tw-flex tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0"
                                    >
                                        <span class="tw-flex-grow">
                                            <strong>Facebook:</strong>
                                            {{
                                                websiteData?.social_links
                                                    ?.facebook
                                            }}
                                            <br />
                                            <strong> Instagram:</strong>
                                            {{
                                                websiteData?.social_links
                                                    ?.instagram
                                            }}
                                            <br />
                                            <strong>Twitter:</strong>
                                            {{
                                                websiteData?.social_links
                                                    ?.twitter
                                            }}
                                        </span>
                                        <span class="tw-ml-4 tw-flex-shrink-0">
                                        </span>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
