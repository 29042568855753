<template>
    <div
        style="
          position: fixed;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: grid;
          place-items: center;
          padding: 1rem;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 50;
        "
    >
        <div
            class="card mb-3"
            style="
                position: relative;
                border-radius: 0.25rem;
                border: 0.2px solid #fff;
                box-shadow: none;
                max-width: 500px;
                width: 95%;
                padding: 1rem;
            "
        >
            <svg
                style="position: absolute; top: 0.5rem; right: 0.5rem; cursor: pointer"
                @click="$emit('close')"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_8_6557)">
                    <path
                        d="M13.89 8.7L12 10.59L10.11 8.7C9.72 8.31 9.09 8.31 8.7 8.7C8.31 9.09 8.31 9.72 8.7 10.11L10.59 12L8.7 13.89C8.31 14.28 8.31 14.91 8.7 15.3C9.09 15.69 9.72 15.69 10.11 15.3L12 13.41L13.89 15.3C14.28 15.69 14.91 15.69 15.3 15.3C15.69 14.91 15.69 14.28 15.3 13.89L13.41 12L15.3 10.11C15.69 9.72 15.69 9.09 15.3 8.7C14.91 8.32 14.27 8.32 13.89 8.7ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                        fill="#1D242E"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_8_6557">
                        <rect width="24" height="24" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

            <div class="card-body">
                <div class="row">
                    <div class="d-flex justify-content-between">
                        <div v-if="!confirming">
                            <h4 class="text-success">{{ props.member.name }}</h4>
                        </div>
                        <div v-if="confirming">
                            <h4 class="text-success">Pay for user verification?</h4>
                        </div>
                    </div>
                </div>

                <div
                    v-if="!confirming"
                    style="
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 0.2rem;
                        width: 100%;
                    "
                >
                    <form @submit.prevent="submitForm">
                        <div class="form-group mb-3">
                            <label for="memberName">Member Name</label>
                            <input
                                type="text"
                                class="form-control"
                                id="memberName"
                                :value="props.member.name"
                                disabled
                            />
                        </div>
                        <div class="form-group mb-3">
                            <label for="nin">Member NIN</label>
                            <input
                                type="text"
                                class="form-control"
                                id="nin"
                                v-model="nin"
                                :class="[errors.nin ? 'is-invalid': '',!errors.nin && Object.keys(errors).length > 1 ? 'is-valid': '']"
                            />
                            <div class="invalid-feedback" v-if="errors.nin">{{ errors.nin.toString() }}</div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="bvn">Member BVN</label>
                            <input
                                type="text"
                                class="form-control"
                                id="bvn"
                                v-model="bvn"
                                :class="[errors.bvn ? 'is-invalid': '',!errors.bvn && Object.keys(errors).length > 1 ? 'is-valid': '']"
                            />
                            <div class="invalid-feedback" v-if="errors.bvn">{{ errors.bvn.toString() }}</div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="memberPhoto">Member Photo</label>
                            <input
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                class="form-control"
                                id="memberPhoto"
                                @change="handleFileUpload"
                                :class="[errors.photo ? 'is-invalid': '',!errors.photo && Object.keys(errors).length > 1 ? 'is-valid': '']"
                            />
                            <div class="invalid-feedback" v-if="errors.photo">{{ errors.photo.toString() }}</div>
                        </div>
                        <div class="row mt-3">
                            <div class="d-flex justify-content-right">
                                <button type="submit" class="btn btn-sm btn-success me-2">
                                    <i class="fa-regular fa-floppy-disk fa-sm"></i>
                                    Verify Member
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <div v-else>
                    <p> If you Pay for user verification the sum {{ currency }} {{ verificationFee }} will be deducted
                        from your Wallet</p>
                    <div class="row mt-3">
                        <div class="">
                            <button @click="confirmVerification" class="btn btn-sm btn-success me-2">
                                <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                                Pay for verification
                            </button>
                            <button @click="cancelConfirmation" class="btn btn-sm btn-warning">
                                Proceed without verification
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import Swal from "sweetalert2";

const props = defineProps({
    member: {
        type: Object,
        required: true
    },
    market: {
        type: Object,
        required: true
    }
});

// Events
const emit = defineEmits(['close'])

// Declaring variables
const nin = ref('');
const bvn = ref('');
const memberPhoto = ref(null);
const confirming = ref(false);
const submitting = ref(false);
const saving = ref(false);
const bvnVerification = ref(null);
const ninVerification = ref(null);
const verificationFee = import.meta.env.VITE_MARKET_USER_VERIFICATION_FEE ?? '500';
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const errors = ref({});

//Add Data from db if the values are there.
onMounted(() => {
    if (props.member.national_id) {
        nin.value = props.member.national_id;
    }
    if (props.member.bvn) {
        bvn.value = props.member.bvn;
    }
});

const handleFileUpload = (event) => {
    memberPhoto.value = event.target.files[0];
};

const submitForm = () => {
    // Validate form data
    if (!nin.value) {
        errors.value = {nin: "NIN is required."};
        return;
    }
    if (nin.value.length !== 11) {
        errors.value = {nin: "NIN is invalid."};
        return;
    }

    if (!bvn.value) {
        errors.value = {bvn: "BVN is required."};
        return;
    }
    if (bvn.value.length !== 11) {
        errors.value = {bvn: "BVN is invalid."};
        return;
    }

    if (!memberPhoto.value) {
        errors.value = {photo: "Member photo is required."};
        return;
    }

    // Show confirmation step
    confirming.value = true;
};

const confirmVerification = () => {
    // Proceed with verification
    savePersonalData();
    qoreIdVerifications();
};

const cancelConfirmation = () => {
    // Cancel confirmation step
    confirming.value = false;
};

const savePersonalData = () => {
    saving.value = true;
    const personalDataForm = new FormData();

    personalDataForm.append("user_id", props.member.id);
    personalDataForm.append("national_id", nin.value);
    personalDataForm.append("bvn", bvn.value);

    axios
        .post("/apis/v4/verifications/market-member/savedetails", personalDataForm)
        .then((response) => {
            //console.log("Personal data saved successfully:", response.data);
            // You can also show a success message or handle the response as needed
        })
        .catch((error) => {
            saving.value = false;
            Swal.fire(
                `${error.response?.data?.message ?? "There was an issue saving personal data"}`,
                "",
                "error"
            );
        });
};

const qoreIdVerifications = () => {
    submitting.value = true;
    saving.value = true;

    const form = new FormData();

    form.append("verification_type", "user");
    form.append("market_id", props.market?.id);
    form.append("user_id", props.member?.id);

    // bvn-verification-form-append
    if (!bvnVerification.value || bvnVerification.value === "failed") {
        form.append("types[]", "bvn");
        form.append("bvn", bvn.value);
    }

    // nin-verification-form-append
    if (!ninVerification.value || ninVerification.value === "failed") {
        form.append("types[]", "nin");
        form.append("nin", nin.value);
    }

    // face-match-verification-form-append
    if (memberPhoto.value) {
        form.append("types[]", "face_match");
        form.append("face_match[photo]", memberPhoto.value ? memberPhoto.value : null);

        form.append("face_match[id_number]", bvn.value);
        form.append("face_match[id_type]", "bvn");
        form.append("face_match[id_number]", nin.value);
        form.append("face_match[id_type]", "nin");
    }

    axios
        .post("/apis/v4/saas/admin-verifications", form)
        .then((resp) => {
            submitting.value = false;

            // Display success message and reload the page
            Swal.fire("Verification in progress!", "", "success").then(() => {
                emit('close');
                window.location.reload();
            });
        })
        .catch((err) => {
            Swal.fire(
                `${err.response?.data?.message ?? "There were issues with your verification"}`,
                "",
                "error"
            );
        })
        .finally(() => {
            saving.value = false;
        });
};
</script>
