<script setup>
import {onMounted, ref} from "vue";
import Swal from "sweetalert2";
import axios from "axios";
import iziToast from "izitoast"
import 'izitoast/dist/css/iziToast.min.css'

const props = defineProps(["market"]);
const errors = ref({});
const banks = ref(null);

const name = ref('');
const type = ref('');
const percentage = ref('');
const bank_code = ref('');
const account_number = ref('');
const account_name = ref('');
const saving = ref(false);

function createCharge() {
    saving.value = true;
    axios.post('/charge/create', {
        'name': name.value,
        'type': type.value,
        'percentage': percentage.value,
        'bank_code': bank_code.value,
        'account_number': account_number.value,
        'account_name': account_name.value,
    }).then(resp => {
        name.value = '';
        type.value = '';
        percentage.value = '';
        bank_code.value = '';
        account_number.value = '';
        account_name.value = '';
        errors.value = {};
        saving.value = false;
        console.log(resp);
        iziToast.success({
            title: 'Charge Added',
            position: 'topRight',
            timeout: 1000,
            icon: 'fa fa-solid fa-check',
            balloon: true,
            message: `Charge <strong>${resp.data.name}</strong> has been saved. The charge will be applied to all upcoming orders.`,
            onClosing: function(instance, toast, closedBy){
                location.href = "/four/market/charges-rent-fees-and-taxes" + '#charge';
                location.reload();
            }
        });


    }).catch(err => {
        saving.value = false;
        errors.value = err.response.data.errors;
    });
}

const getBanks = () => {
    axios.get('/apis/v4/saas/vfd/banks').then((response) => [
        banks.value = response.data.data
    ]);
}

onMounted(() => {
    getBanks();
})
</script>

<template>
    <form action="">
        <div class="row g-3">
            <div class="col-md-6">
                <!--Label: Charge Name, Attributes:name -->
                <div class="form-floating">
                    <input type="text" class="form-control" id="name" v-model="name"
                           aria-describedby="name-help"
                           :class="[errors.name ? 'is-invalid': '',!errors.name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                    >
                    <label class="form-label" for="name">Charge Name</label>

                    <div class="invalid-feedback" v-if="errors.name">
                        {{ errors.name.toString() }}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <!--Label: Charge Type, Attributes:type -->
                <div class="form-floating">
                    <select class="form-control" id="type" v-model="type"
                            aria-describedby="type-help"
                            :class="[errors.type ? 'is-invalid': '',!errors.type && Object.keys(errors).length > 1 ? 'is-valid': '']"
                    >
                        <option value="" disabled>Select Type</option>
                        <option value="Tax">Tax</option>
                        <option value="Transaction fee">Transaction fee</option>
                        <option value="Market Insurance Contribution">Market Insurance Contribution</option>
                    </select>
                    <label class="form-label" for="type">Select Type</label>

                    <div class="invalid-feedback" v-if="errors.type">
                        {{ errors.type.toString() }}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <!--Label: Percentage, Attributes:percentage -->
                <div class="form-floating">
                    <input type="number" class="form-control" id="percentage" v-model="percentage"
                           aria-describedby="percentage-help"
                           :class="[errors.percentage ? 'is-invalid': '',!errors.percentage && Object.keys(errors).length > 1 ? 'is-valid': '']"
                    >
                    <label class="form-label" for="percentage">Percentage on Sales/Payment Made</label>

                    <div class="invalid-feedback" v-if="errors.percentage">
                        {{ errors.percentage.toString() }}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <!--Label: Select Bank, Attributes:bank_code -->
                <div class="form-floating">
                    <select
                        class="form-select"
                        id="bank_code"
                        v-model="bank_code"
                        aria-describedby="bank_code-help"
                        :class="[errors.bank_code ? 'is-invalid' : '',!errors.bank_code &&Object.keys(errors).length > 1? 'is-valid': '']"
                    >
                        <option value=""></option>
                        <option v-for="bank in banks" :value="bank.code">{{ bank.name }}</option>
                    </select>
                    <label class="form-label" for="bank_code">Cashout Bank</label>

                    <div class="invalid-feedback" v-if="errors.bank_code">
                        {{ errors.bank_code.toString() }}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <!--Label: Account Name, Attributes:account_name -->
                <div class="form-floating">
                    <input
                        type="text"
                        class="form-control"
                        id="account_name"
                        v-model="account_name"
                        aria-describedby="account_name-help"
                        :class="[errors.account_name ? 'is-invalid' : '',!errors.account_name &&Object.keys(errors).length > 1? 'is-valid': '']"
                    />
                    <label class="form-label" for="account_name">Cashout Account Name</label>
                    <div class="invalid-feedback" v-if="errors.account_name">
                        {{ errors.account_name.toString() }}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <!--Label: Account Number, Attributes:account_number -->
                <div class="form-floating">
                    <input
                        type="text"
                        class="form-control"
                        id="account_number"
                        v-model="account_number"
                        aria-describedby="account_number-help"
                        :class="[errors.account_number ? 'is-invalid' : '',!errors.account_number &&Object.keys(errors).length > 1? 'is-valid': '']"
                    />
                    <label class="form-label" for="account_number">Cashout Account Number</label>
                    <div class="invalid-feedback" v-if="errors.account_number">
                        {{ errors.account_number.toString() }}
                    </div>
                </div>
            </div>
            <div class="col-md-12 d-flex justify-content-end">
                <button class="btn btn-success"
                        :disabled="saving"
                        id="createChargeButton"
                        @click.prevent="createCharge">
                    Create Charge
                    <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                    <i class="fa fa-plus" v-else></i>
                </button>
            </div>
        </div>

    </form>
</template>

<style scoped>

</style>
