<script setup>
import {ref} from "vue";
import Swal from 'sweetalert2'

const errors = ref('');
const current_password = ref('');
const password = ref('');
const saving = ref(false);
const confirm_password = ref('');

function updatePassword() {
    saving.value = true;
    axios.post('/apis/v4/update-password', {
        current_password: current_password.value,
        password: password.value,
        password_confirmation: confirm_password.value,
    }).then(response => {
        current_password.value = '';
        password.value = '';
        confirm_password.value = '';
        errors.value = '';
        saving.value = false;
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Password updated successfully',
        });
    }).catch(error => {
        errors.value = error.response.data.errors;
        saving.value = false;
    });
}
</script>

<template>
    <div>
        <form action="">
            <div class="row g-3">
                <div class="col-12">
                    <!--Label: Current Password, Attributes:current_password -->
                    <div class="form-floating">
                        <input type="password" class="form-control" id="current_password" v-model="current_password"
                               aria-describedby="current_password-help"
                               :class="[errors.current_password ? 'is-invalid': '',!errors.current_password && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               required>
                        <label for="current_password">Current Password</label>
                        <div class="invalid-feedback" v-if="errors.current_password">
                            {{ errors.current_password.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <!--Label: Password, Attributes:password -->
                    <div class="form-floating">
                        <input type="password" class="form-control" id="password" v-model="password"
                               aria-describedby="password-help"
                               :class="[errors.password ? 'is-invalid': '',!errors.password && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               required>
                        <label for="password">Password</label>

                        <div class="invalid-feedback" v-if="errors.password">
                            {{ errors.password.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <!--Label: Confirm Password, Attributes:confirm_password -->
                    <div class="form-floating">
                        <input type="password" class="form-control" id="confirm_password" v-model="confirm_password"
                               aria-describedby="confirm_password-help"
                               :class="[errors.confirm_password ? 'is-invalid': '',!errors.confirm_password && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               required>
                        <label for="confirm_password">Confirm Password</label>
                        <div class="invalid-feedback" v-if="errors.confirm_password">
                            {{ errors.confirm_password.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-secondary" @click.prevent="updatePassword">
                            Update Password
                            <i class="fa fa-save fa-thin" v-if="!saving"></i>
                            <i class="fa fa-spin fa-spinner" v-else></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<style scoped>

</style>
