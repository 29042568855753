import {defineStore} from 'pinia'
import {ref} from "vue";

export const useOrderStore = defineStore('orderstore', () => {


    //region Order - Seller Information
    const seller = ref({});
    const setSeller = (seller) => {
        seller.value = seller;
    };
    //endregion

    //region Order - Buyer Information
    const buyer = ref({});
    const setBuyer = (user) => {
        buyer.value = user
    };
    //endregion

    const amount = ref(0);

    const quantity = ref(0);

    const selectedProduct = ref({});
    const products = ref({});
    const orderConfirmed = ref({});

    const resetState = () => {
        products.value = {};
        seller.value = {};
        selectedProduct.value = {};
    }
    return {
        seller,
        setSeller,
        buyer,
        setBuyer,
        amount,
        quantity,
        selectedProduct,
        products,
        resetState,
        orderConfirmed
    }
})
