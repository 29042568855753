<script setup>

import {onMounted, ref} from "vue";
import moment from "moment/moment";
import {Bootstrap5Pagination} from "laravel-vue-pagination";
import Multiselect from "vue-multiselect";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const props = defineProps(['fee', 'total_overdue', 'current_user_id', 'members']);
const feePayments = ref({});

const paymentUser = ref('');
const paymentMarket = ref('');
const paymentAmount = ref(0);
const paymentStatus = ref('');
const paymentRef = ref('');
const paymentDue = ref('');
const paymentSettled = ref('');
const paymentCreated = ref('');

/* Edit Fee*/
const errors = ref([]);
const fee_amount = ref(props.fee.amount);
const phoneNumber = ref();
const phone_number = ref();
const saving = ref(false);

const getMembers = () => {
    let options = [];

    props.members.forEach(member => {
        let memberName = member.name + ' (' + member.phone.substring(0, 6) + 'xxx)';
        options.push({name: memberName, phone: member.phone});
    });

    return options;
}

const addMembersToFee = () => {
    saving.value = true;
    axios.post('/apis/v4/fees/addmembers', {
        'fee_id': props.fee.id,
        'amount': fee_amount.value,
        'phones': phone_number.value,
    }).then(() => {
        saving.value = false;
        window.location.reload();
    }).catch(() => {
        saving.value = false;
    }).finally(() => {
        saving.value = false;
    });
}

/* Edit Fee*/

function formatDate(value) {
    return moment(value).format('DD/MM/YYYY HH:mm:ss');
}

function populateFeePayment(payment) {
    if (payment) {
        paymentUser.value = payment.user?.name;
        paymentMarket.value = payment.market?.name;
        paymentAmount.value = payment.amount;
        paymentStatus.value = payment.status;
        paymentRef.value = payment.transaction_ref;
        paymentSettled.value = formatDate(payment.settled_on);
        paymentDue.value = formatDate(payment.upcoming_at);
        paymentCreated.value = formatDate(payment.created_at);
    }
}

const getFeePayments = async (page = 1) => {
    await axios.get(`/four/market/fees/${props.fee.id}/payments?page=${page}`).then(resp => {
        feePayments.value = resp.data.data;
    }).catch(err => {
        errors.value = err.response.data.errors;
    });
}

function onClickCashoutHistory() {
    bootstrap.Modal.getOrCreateInstance(document.querySelector("#cashoutList")).show();
}

function onClickOverdueHistory() {
    bootstrap.Modal.getOrCreateInstance(document.querySelector("#overdueList")).show();
}

function onClickTransactionHistory() {
    bootstrap.Modal.getOrCreateInstance(document.querySelector("#transactionList")).show();
}

onMounted(() => {
    getFeePayments();
})

</script>

<template>
    <div>
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
            <div class="col">
                <div class="transaction-history-summary-card radius-10"
                     style="border-radius: 0.25rem;border: 0.2px solid rgb(29, 36, 46);">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-2 text-secondary">Amount</p>
                                <h4 class="my-1" style="padding-bottom: 8px">
                                    {{ currency }}{{ fee.amount.toLocaleString() }}
                                </h4>
                            </div>

                        </div>
                        <a class="text-secondary">Collected {{ fee.frequency }}</a>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="transaction-history-summary-card radius-10"
                     style="border-radius: 0.25rem;border: 0.2px solid rgb(29, 36, 46);">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-2 text-secondary">Total Overdue</p>
                                <h4 class="my-1" style="padding-bottom: 8px">
                                    {{ currency }}{{ total_overdue.toLocaleString() }}
                                </h4>
                            </div>

                        </div>
                        <a class="text-secondary" href="#" @click="onClickOverdueHistory">See all overdue</a>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="transaction-history-summary-card radius-10"
                     style="border-radius: 0.25rem;border: 0.2px solid rgb(29, 36, 46);">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-2 text-secondary">Amount in Wallet</p>
                                <h4 class="my-1" style="padding-bottom: 8px">
                                    {{ currency }}{{ fee.total_balance.toLocaleString() }}
                                </h4>
                            </div>
                        </div>
                        <a class="text-secondary" href="#" @click="onClickTransactionHistory">See transaction
                            history</a>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="transaction-history-summary-card radius-10"
                     style="border-radius: 0.25rem;border: 0.2px solid rgb(29, 36, 46);">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">Cashout Bank</p>
                                <h4 class="my-1" style="font-size: 12px; color: black">
                                    <strong>
                                        {{ fee.bank?.name ?? 'N/A' }} <br/>
                                        {{ fee.cashout_account_number }} <br/>
                                        {{ fee.cashout_account_name }}
                                    </strong>
                                </h4>
                            </div>
                        </div>
                        <!--<button class="btn active-button-border success-text">See cashout history</button>-->
                        <a class="text-secondary" href="#" @click="onClickCashoutHistory">See cashout history</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4" v-if="current_user_id === fee.creator_id">
            <div class="col-12">
                <button
                    class="btn btn-success"
                    id="cashoutWalletBtn"
                    data-bs-toggle="modal"
                    data-bs-target="#cashoutWallet"
                >
                    <i class="fa-light fa-sm fa-money-simple-from-bracket"></i>
                    Cashout Wallet
                </button>
                &nbsp;
                <delete-charge-fee
                    :deleteType="'fee'"
                    :deleteUrl="`/four/market/charges-rent-fees-and-taxes/${fee.id}`"
                >
                </delete-charge-fee>

                <button class="btn btn-outline-secondary tw-ml-3" type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                    <i class="fa fa-pencil"></i>
                    Edit
                </button>

                <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight"
                     aria-labelledby="offcanvasRightLabel">
                    <div class="offcanvas-header">
                        <h5 id="offcanvasRightLabel">Edit Fee</h5>
                        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                                aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                        <p style="font-weight: bold">Update fee amount and/or add members to collect fee from.</p>
                        <form>
                            <div class="row g-4">
                                <div class="col-12">
                                    <!--Label: Fee Amount, Attributes:fee_amount -->
                                    <div class="form-floating">
                                        <input type="text" class="form-control" id="fee_amount" v-model="fee_amount"
                                               aria-describedby="fee_amount-help"
                                               :class="[errors.fee_amount ? 'is-invalid': '',!errors.fee_amount && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                        >
                                        <label for="fee_amount">Fee Amount</label>
                                        <div class="invalid-feedback" v-if="errors.fee_amount">
                                            {{ errors.fee_amount.toString() }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <!--Label: Phone Number, Attributes:phone_number -->
                                    <div class="form-floating">
                                        <multiselect
                                            v-model="phone_number"
                                            :options="getMembers()"
                                            :multiple="true"
                                            :close-on-select="false"
                                            select-label="select"
                                            deselect-label="remove"
                                            placeholder="Select Member"
                                            label="name"
                                            track-by="name"
                                            :class="[
                                    errors.phoneNumber ? 'is-invalid' : '',
                                    !errors.phoneNumber && Object.keys(errors).length > 1 ? 'is-valid' : '',
                                ]"
                                        >
                                            <template #noResult>
                                                <span class="text-danger">Oops! Member not found.</span>
                                            </template>
                                        </multiselect>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.phoneNumber"
                                        >
                                            {{ errors.phoneNumber.toString() }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="d-flex justify-content-end">
                                        <button class="btn btn-secondary" @click.prevent="addMembersToFee">
                                            Save
                                            <i class="fa fa-spinner fa-spin" v-if="saving"></i>
                                            <i class="fa fa-save" v-else></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead v-if="feePayments && feePayments.data && feePayments.data.length > 0">
                                <tr>
                                    <th>#</th>
                                    <th>Market Member</th>
                                    <th>Total Due</th>
                                    <th>Due Date</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody v-if="feePayments && feePayments.data && feePayments.data.length > 0">
                                <tr v-for="(feePayment,index) in feePayments.data" :key="feePayment.id">
                                    <td scope="row">{{ index + 1 }}</td>
                                    <td>{{ feePayment.user.name }}</td>
                                    <td>{{ currency }}{{ feePayment.amount.toLocaleString() }}</td>
                                    <td>{{ formatDate(feePayment.upcoming_at) }}</td>
                                    <td>
                                        <span v-if="feePayment.status === 'DUE'" class="text-warning">Due</span>
                                        <span v-else-if="feePayment.status === 'OverDue'"
                                              class="text-danger">OverDue</span>
                                        <span v-else-if="feePayment.status === 'Paid'" class="text-success">Paid</span>
                                        <span v-else>{{ feePayment.status }}</span>
                                    </td>
                                    <td>
                                        <button class="btn btn-sm btn-default"
                                                @click="populateFeePayment(feePayment)"
                                                data-bs-toggle="offcanvas" href="#viewPayment" role="button"
                                                aria-controls="viewPayment"
                                        >
                                            See More <i class="fa fa-angle-double-right fa-thin"></i>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                                <tbody v-else>
                                <tr>
                                    <td align="center" colspan="7">No record found.</td>
                                </tr>
                                </tbody>
                            </table>
                            <bootstrap5-pagination :data="feePayments" @pagination-change-page="getFeePayments"
                                                   align="center"/>
                        </div>
                        <div class="offcanvas offcanvas-end" tabindex="-1" id="viewPayment"
                             aria-labelledby="viewPaymentLabel">
                            <div class="offcanvas-header">
                                <h5 class="offcanvas-title" id="offcanvasExampleLabel">Fee Payment</h5>
                                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                                        aria-label="Close"/>
                            </div>
                            <div class="offcanvas-body">
                                <div class="table-responsive">
                                    <table class="table table-borderless">
                                        <tbody>
                                        <tr>
                                            <td>Market Member:</td>
                                            <td><b>{{ paymentUser }}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Market:</td>
                                            <td><b>{{ paymentMarket }}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Amount:</td>
                                            <td><b>{{ currency }}{{ paymentAmount.toLocaleString() }}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Status:</td>
                                            <td>
                                                <b>
                                                    <span v-if="paymentStatus === 'DUE'" class="text-warning">Due</span>
                                                    <span v-else-if="paymentStatus === 'OverDue'" class="text-danger">OverDue</span>
                                                    <span v-else-if="paymentStatus === 'Paid'"
                                                          class="text-success">Paid</span>
                                                    <span v-else>{{ paymentStatus }}</span>
                                                </b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Transaction Ref:</td>
                                            <td><b>{{ paymentRef }}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Payment Due:</td>
                                            <td><b>{{ paymentDue }}</b></td>
                                        </tr>
                                        <tr v-if="paymentStatus === 'Paid'">
                                            <td>Paid On:</td>
                                            <td><b>{{ paymentSettled }}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Payment Created:</td>
                                            <td><b>{{ paymentCreated }}</b></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="modal fade"
        id="cashoutWallet"
        tabindex="-1"
        role="dialog"
        aria-labelledby="cashoutWallet"
        aria-hidden="true"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close" style="float: right" data-bs-dismiss="modal"
                            aria-label="Close"/>
                    <div class="col-12 mt-3" style="padding: 10px">
                        <h4 class="text-success">Input OTP to cashout wallet</h4>
                        <p class="mt-2">
                            {{ fee.bank?.name ?? 'N/A' }} <br/>
                            {{ fee.cashout_account_number }} <br/>
                            {{ fee.cashout_account_name }}
                        </p>
                        <fee-charge-cashout
                            :type="'fee'"
                            :type_id="fee.id"
                            :market="fee.market"
                            :typeObject="fee"
                            :total_amount="fee.total_balance"
                            :current_user_id="current_user_id"
                        ></fee-charge-cashout>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div
        class="modal fade"
        id="cashoutList"
        tabindex="-1"
        role="dialog"
        aria-labelledby="cashoutList"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-scrollable" role="document" style="height: 60%">
            <div class="modal-content" style="height: 100%">
                <div class="modal-header">
                    <h4 class="modal-title">Cashout History</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="col-12 mt-0">
                        <div class="table-responsive">
                            <table class="table">
                                <thead v-if="fee.cashouts && fee.cashouts.length > 0">
                                <tr>
                                    <th>Initiated By</th>
                                    <th>Cashout amount</th>
                                    <th>Cashout Date</th>
                                </tr>
                                </thead>
                                <tbody v-if="fee.cashouts && fee.cashouts.length > 0">
                                <tr v-for="(cashout) in fee.cashouts" :key="cashout.id">
                                    <td>{{ cashout.user.name }}</td>
                                    <td>{{ currency }}{{ cashout.amount.toLocaleString() }}</td>
                                    <td>{{ formatDate(cashout.created_at) }}</td>
                                </tr>
                                </tbody>
                                <tbody v-else>
                                <tr>
                                    <td align="center" colspan="7">No history found.</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="modal fade"
        id="overdueList"
        tabindex="-1"
        role="dialog"
        aria-labelledby="overdueList"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-scrollable" role="document" style="height: 60%">
            <div class="modal-content" style="height: 100%">
                <div class="modal-header">
                    <h4 class="modal-title">All Overdue</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="col-12 mt-0">
                        <div class="table-responsive">
                            <table class="table">
                                <thead v-if="fee.overdues && fee.overdues.length > 0">
                                <tr>
                                    <th>Market Member</th>
                                    <th>Total Due</th>
                                    <th>Due Date</th>
                                </tr>
                                </thead>
                                <tbody v-if="fee.overdues && fee.overdues.length > 0">
                                <tr v-for="(overdue) in fee.overdues" :key="overdue.id">
                                    <td>{{ overdue.user.name }}</td>
                                    <td>{{ currency }}{{ overdue.amount.toLocaleString() }}</td>
                                    <td class="text-danger">{{ formatDate(overdue.upcoming_at) }}</td>
                                </tr>
                                </tbody>
                                <tbody v-else>
                                <tr>
                                    <td align="center" colspan="7">No records found.</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="modal fade"
        id="transactionList"
        tabindex="-1"
        role="dialog"
        aria-labelledby="transactionList"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-scrollable" role="document" style="height: 60%">
            <div class="modal-content" style="height: 100%">
                <div class="modal-header">
                    <h4 class="modal-title">Transaction History</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="col-12 mt-0">
                        <div class="table-responsive">
                            <table class="table">
                                <thead v-if="fee.transactions && fee.transactions.length > 0">
                                <tr>
                                    <th>Market Member</th>
                                    <th>Total Paid</th>
                                    <th>Paid Date</th>
                                </tr>
                                </thead>
                                <tbody v-if="fee.transactions && fee.transactions.length > 0">
                                <tr v-for="(transaction) in fee.transactions" :key="transaction.id">
                                    <td>{{ transaction.user.name }}</td>
                                    <td>{{ currency }}{{ transaction.amount.toLocaleString() }}</td>
                                    <td class="text-success">{{ formatDate(transaction.settled_on) }}</td>
                                </tr>
                                </tbody>
                                <tbody v-else>
                                <tr>
                                    <td align="center" colspan="7">No history found.</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
