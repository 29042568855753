<script setup>

import {onMounted, ref} from "vue";
import NoProduct from "../market/compliance/NoProduct.vue";
import UserIcons from "../Verification/UserIcons.vue";
import DeleteButton from "../../DeleteButton.vue";
import axios from "axios";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const props = defineProps(['markets', 'states', 'lgas', 'user'])

const currentTab = ref('search_products');
const initialState = ref(true);
const initialMyState = ref(true);
const loading = ref(false);
const loadingMy = ref(false);
const loadingProducts = ref(false);
const loadingMyProducts = ref(false);
const searchEnabled = ref(false);
const searchMyEnabled = ref(false);
const updatePriceLoading = ref(false);

const errors = ref({});
const products = ref({});
const myProducts = ref({});
const page = ref(1);
const searchTxt = ref('');
const searchMyTxt = ref('');
const state_id = ref('');
const lga_id = ref('');
const market_id = ref('');
const productGroupStockId = ref('');
const newProductPrice = ref('');

function searchMyProducts() {

    initialMyState.value = false;
    loadingMy.value = loadingMyProducts.value = searchMyEnabled.value = true;

    axios
        .get(`/apis/v4/saas/products/search?search=${searchMyTxt.value}&all_products=${0}`)
        .then((response) => {
            myProducts.value = response?.data?.data?.products;
        })
        .catch((error) => {
            console.log(error);
        }).finally(()=>{
        loadingMy.value = loadingMyProducts.value = false;
    });
}

function searchForProduct() {
    market_id.value = state_id.value = lga_id.value = '';

    // if (loadmore != null) {
    //     this.page++;
    // }
    if (!searchTxt.value) {
        return;
    }

    initialState.value = false;
    loading.value = loadingProducts.value = searchEnabled.value = true;

    let uri = `/apis/v4/saas/products/search?`;
    uri += `search=${searchTxt.value}&market=${market_id.value}&state=${state_id.value}&lga=${lga_id.value}&`;

    axios
        .get(uri + `&all_products=${1}&page=${page.value}`)
        .then((response) => {

            products.value = response?.data?.data?.products;

            if(products.value.length > 0){
                showSearchFilters();
            }

            reInitSelect2();
        })
        .catch((error) => {
            console.log(error);
        }).finally(()=>{
            loading.value = loadingProducts.value = false;
        });
}

function filterProducts() {
    initialState.value = false;
    loading.value = loadingProducts.value = searchEnabled.value = true;

    let uri = `/apis/v4/saas/products/search?`;
    uri += `search=${searchTxt.value}&market=${market_id.value}&state=${state_id.value}&lga=${lga_id.value}&`;

    axios
        .get(uri + `&all_products=${1}&page=${page.value}`)
        .then((response) => {
            products.value = response?.data?.data?.products;
        })
        .catch((error) => {
            console.log(error);
        }).finally(()=>{
            loading.value = loadingProducts.value = false;
        });
}

function onClickUpdatePrice(product) {
    productGroupStockId.value = product.id;
    bootstrap.Modal.getOrCreateInstance(document.querySelector("#updatePrice")).show();
}

function updateProductPrice(){

    if(!newProductPrice.value){
        errors.value = {price: "Price is required"};
        return;
    }

    updatePriceLoading.value = true;
    let updateFor = (location.pathname === "/four/market/products") ? 'markets' : 'self';

    axios
        .post("/apis/v4/price-update", {
            updateFor: updateFor,
            price: newProductPrice.value,
            stockId: productGroupStockId.value,
        })
        .then(() => {
            location.href = `/four/market/products#my_products`;
            location.reload();
        })
        .catch((err) => {
            updatePriceLoading.value = false;
            errors.value = err.response.data.errors;
        });
}

function showSearchFilters() {
    // $('#menu li a.current').removeClass('current');
    $('.search_filters').removeClass('hide');
    $('.search_filters').addClass('current');
}
function initSelect2() {
    $(".js-example-basic-single").select2({
        theme: "bootstrap-5",
    });
}

function reInitSelect2() {
    $(".js-example-basic-single").select2("destroy");
    $(".js-example-basic-single")
        .select2({theme: "bootstrap-5"})
        .on("change", function (e) {
            switch (e.target.id) {
                case "state_id":
                    state_id.value = $(this).val();
                    break;
                case "lga_id":
                    lga_id.value = $(this).val();
                    break;
                case "market_id":
                    market_id.value = $(this).val();
                    break;
            }

            filterProducts();
        });
}

onMounted(() => {
    let hash = window.location.hash;
    currentTab.value = hash ? hash.slice(1) : 'search_products';

    initSelect2();
    searchMyProducts();
})

</script>

<template>
    <div>
        <div class="mt-4">
            <div class="nav nav-pills" id="myTab" role="tablist">
                <div class="nav-item" role="presentation">
                    <button
                        class="btn btn-outline-success"
                        :class="{ active: currentTab === 'search_products'}"
                        id="search_products-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#search_products"
                        type="button"
                        role="tab"
                        aria-controls="search_products"
                        aria-selected="true"
                    >
                        Search for Products
                    </button>
                </div>
                &nbsp;
                <div class="nav-item" role="presentation">
                    <button
                        class="btn btn-outline-success"
                        :class="{ active: currentTab === 'my_products'}"
                        id="my_products-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#my_products"
                        type="button"
                        role="tab"
                        aria-controls="my_products"
                        aria-selected="false"
                    >
                        My Market Products
                    </button>
                </div>
            </div>
        </div>
        <div>
            <p style="font-size: 16px" class="mt-3">Scan Prices</p>
        </div>
        <div class="tab-content mt-4" id="myTabContent">
            <div
                v-if="currentTab"
                class="tab-pane fade"
                :class="{ show: currentTab === 'search_products', active: currentTab === 'search_products'}"
                id="search_products"
                role="tabpanel"
                aria-labelledby="search_products-tab"
            >
                <div @keyup.enter="searchForProduct">
                    <div class="col-12 mb-4">
                        <div class="mb-2">
                            <input
                                type="text"
                                class="form-control me-2 text-center"
                                v-model="searchTxt"
                                placeholder="Search for products or seller phone number. Eg. Eggs or 0791..."
                                style="
                                    border: 0.2px solid #1d242e;
                                    padding: 0.5rem 1rem;
                                    background: #e3ebf3;
                                "
                            />
                            <div id="search-box"></div>
                        </div>
                        <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                            <button
                                type="button"
                                class="btn add-button-coamana success-text white-text"
                                @click="searchForProduct"
                                @keydown.enter="searchForProduct"
                                style="
                                    border-radius: 0.25rem;
                                    padding: 0.5rem 2rem;
                                    background: #4b9d30;
                                "
                            >
                                Search
                                <i class="fa fa-spin fa-spinner" v-if="loading"></i>
                            </button>
                        </div>
                    </div>
                    <div class="ms-auto mb-3 search_filters hide">
                        <div class="col-12">
                            <form action="">
                                <div class="row">
                                    <div class="col-md-4">
                                        <!--Label: State, Attributes:state_id -->
                                        <div class="form-group">
                                            <select
                                                class="form-control js-example-basic-single"
                                                id="state_id"
                                                v-model="state_id"
                                                aria-describedby="state-id-help"
                                            >
                                                <option value="" disabled>-- Select State --</option>
                                                <option :value="state.id" :key="state.id" v-for="state in states">
                                                    {{ state?.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <!--Label: Lga, Attributes:lga_id -->
                                        <div class="form-group">
                                            <select
                                                class="form-control js-example-basic-single"
                                                id="lga_id"
                                                v-model="lga_id"
                                                aria-describedby="lga-id-help"
                                            >
                                                <option value="" disabled>-- Select LGA --</option>
                                                <option :value="lga.id" :key="lga.id" v-for="lga in lgas">
                                                    {{ lga?.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <!--Label: Market, Attributes:market_id -->
                                        <div class="form-group">
                                            <select
                                                class="form-control js-example-basic-single"
                                                id="market_id"
                                                v-model="market_id"
                                                aria-describedby="market-id-help"
                                            >
                                                <option value="" disabled>-- Select Market --</option>
                                                <option :value="market.id" :key="market.id" v-for="market in markets">
                                                    {{ market?.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div v-if="!initialState && !loadingProducts" class="row">
                        <div v-if="products.length > 0">
                            <div
                                class="d-flex flex-column flex-md-row products-services-card my-0 mb-3"
                                v-for="product in products"
                                :key="product?.product?.id"
                                style="
                                    border-radius: 0.25rem;
                                    border: 1px solid rgba(29, 36, 46, 0.3);
                                    background: #fff;
                                    padding: 0;
                                    /* max-height: 160px; */
                                    overflow: hidden;
                                "
                            >
                                <div class="col-2 d-flex align-items-center">
                                    <img style="height: 100%; width: 100%; object-fit: cover" :src="product?.product_image_paths"/>
                                </div>

                                <div class="col">
                                    <div style="
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            gap: 0.5rem;
                                            flex-wrap: wrap;
                                            padding: 0.5rem 2.25rem 0.5rem 0.5rem;
                                            border-bottom: 1px solid rgba(29, 36, 46, 0.3);
                                        "
                                    >
                                        <div class="">
                                            <div style="margin: 0; font-weight: 700">
                                                <div>
                                                    {{ product?.product?.name }}
                                                    <span style="font-weight: 400">
                                                        {{ product?.measurement_unit?.name }}
                                                        Listed by
                                                    </span>
                                                    <div class="tw-flex md:tw-flex-none">
                                                        {{ product?.member?.name ?? "a member" }}&nbsp;
                                                        <UserIcons :user="product?.member"></UserIcons>
                                                    </div>
                                                    <span class="text-muted">at {{ product?.created_at }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="font-weight: 600">
                                            {{ currency }}{{ product?.amount?.toLocaleString() }}
                                        </div>
                                    </div>
                                    <div style="
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            gap: 0.5rem;
                                            flex-wrap: wrap;
                                            padding: 0.5rem 2.25rem 0.5rem 0.5rem;
                                        "
                                    >
                                        <div style="
                                                display: flex;
                                                justify-content: flex-start;
                                                align-items: flex-start;
                                                gap: 0.1rem;
                                                flex-direction: column;
                                            "
                                        >
                                            <div>
                                                <strong>Market</strong>:
                                                {{ product.market?.name }} |
                                                {{ product.market?.address ??  ' ' }},
                                                {{ product.market?.l_g_a?.name}} ,
                                                {{ product.market?.geo_state?.name}}
                                            </div>
                                            <div v-if="product?.last_sold_at">
                                                <strong>Last Sold at {{ product.market?.name }}</strong>:
                                                N{{ Number(product?.last_sold_at)?.toLocaleString() }}
                                                |
                                                {{ product.measurement_unit.name }}
                                                |
                                                {{ product.last_sold_at_date }}
                                            </div>
                                            <div v-else>
                                                <strong>Last Sold at {{ product.market?.name }}</strong>: Unavailable
                                            </div>
                                            <div>
                                                <strong>{{ product.market?.name }} Market Recommended Price</strong>:
                                                {{ currency }}{{ Number(product?.market_price)?.toLocaleString() }}
                                                |
                                                {{ product?.measurement_unit?.name }}
                                                |
                                                {{ product?.market_price_updated_at }}
                                            </div>
                                        </div>
                                        <div class="success-text">
                                            <template v-if="searchEnabled">
                                                <a v-if="user.id !== product?.member_id"
                                                    style="
                                                        margin: 0;
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                        gap: 0.1rem;
                                                        cursor: pointer;
                                                    "
                                                    class="text-success"
                                                    :href="`/four/make-payment?productstock=${product.id}`"
                                                >
                                                    Buy
                                                    <i class="fa-solid fa-angles-right"></i>
                                                </a>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <NoProduct v-else/>
                    </div>
                    <div v-else-if="loadingProducts" style="font-weight: 600;margin: 2rem auto;width: 100%;text-align: center;">
                        Loading Products...
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                :class="{ show: currentTab === 'my_products', active: currentTab === 'my_products'}"
                id="my_products"
                role="tabpanel"
                aria-labelledby="my_products-tab"
            >
                <div @keyup.enter="searchMyProducts">
                    <div class="col-12 mb-4">
                        <div class="mb-2">
                            <input
                                type="text"
                                class="form-control me-2 text-center"
                                v-model="searchMyTxt"
                                placeholder="Search for products Eg. Eggs or Maize..."
                                style="
                                    border: 0.2px solid #1d242e;
                                    padding: 0.5rem 1rem;
                                    background: #e3ebf3;
                                "
                            />
                            <div id="search-box"></div>
                        </div>
                        <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                            <button
                                type="button"
                                class="btn add-button-coamana success-text white-text"
                                @click="searchMyProducts"
                                @keydown.enter="searchMyProducts"
                                style="
                                    border-radius: 0.25rem;
                                    padding: 0.5rem 2rem;
                                    background: #4b9d30;
                                "
                            >
                                Search
                                <i class="fa fa-spin fa-spinner" v-if="loadingMy"></i>
                            </button>
                        </div>
                    </div>
                    <div class="mb-1">
                        <a
                            href="/four/market/products/create"
                            id="add-product-and-services"
                            style="
                                text-decoration: none !important;
                                color: #ee8509;
                                border-radius: 0.25rem;
                                border: 0.4px solid #ee8509;
                                background: #fff;
                            "
                            type="button"
                            class="primary-button-coamana"
                        >
                            <i class="fa fa-light fa-plus"></i>

                            Add New Product
                        </a>
                    </div>
                    <div v-if="!initialMyState && !loadingMyProducts" class="row">
                        <div v-if="myProducts.length > 0">
                            <div
                                class="d-flex flex-column flex-md-row products-services-card my-0 mb-3"
                                v-for="product in myProducts"
                                :key="product?.product?.id"
                                style="
                                    border-radius: 0.25rem;
                                    border: 1px solid rgba(29, 36, 46, 0.3);
                                    background: #fff;
                                    padding: 0;
                                    /* max-height: 160px; */
                                    overflow: hidden;
                                "
                            >
                                <div class="col-2 d-flex align-items-center">
                                    <img style="height: 100%; width: 100%; object-fit: cover" :src="product?.product_image_paths"/>
                                </div>

                                <div class="col">
                                    <div style="
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            gap: 0.5rem;
                                            flex-wrap: wrap;
                                            padding: 0.5rem 2.25rem 0.5rem 0.5rem;
                                            border-bottom: 1px solid rgba(29, 36, 46, 0.3);
                                        "
                                    >
                                        <div class="">
                                            <div style="margin: 0; font-weight: 700">
                                                <div>
                                                    {{ product?.product?.name }}
                                                    <span style="font-weight: 400">
                                                        {{ product?.measurement_unit?.name }}
                                                        Listed by
                                                    </span>
                                                    <div class="tw-flex md:tw-flex-none">
                                                        {{ product?.member?.name ?? "a member" }}&nbsp;
                                                        <UserIcons :user="product?.member"></UserIcons>
                                                    </div>
                                                    <span class="text-muted">at {{ product?.created_at }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="font-weight: 600">
                                            {{ currency }}{{ product?.amount?.toLocaleString() }}
                                        </div>
                                    </div>
                                    <div style="
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            gap: 0.5rem;
                                            flex-wrap: wrap;
                                            padding: 0.5rem 2.25rem 0.5rem 0.5rem;
                                        "
                                    >
                                        <div style="
                                                display: flex;
                                                justify-content: flex-start;
                                                align-items: flex-start;
                                                gap: 0.1rem;
                                                flex-direction: column;
                                            "
                                        >
                                            <div>
                                                <strong>Market</strong>:
                                                {{ product.market?.name }} |
                                                {{ product.market?.address ??  ' ' }},
                                                {{ product.market?.l_g_a?.name}} ,
                                                {{ product.market?.geo_state?.name}}
                                            </div>
                                            <div v-if="product?.last_sold_at">
                                                <strong>Last Sold at {{ product.market?.name }}</strong>:
                                                N{{ Number(product?.last_sold_at)?.toLocaleString() }}
                                                |
                                                {{ product.measurement_unit.name }}
                                                |
                                                {{ product.last_sold_at_date }}
                                            </div>
                                            <div v-else>
                                                <strong>Last Sold at {{ product.market?.name }}</strong>: Unavailable
                                            </div>
                                            <div>
                                                <strong>{{ product.market?.name }} Market Recommended Price</strong>:
                                                {{ currency }}{{ Number(product?.market_price)?.toLocaleString() }}
                                                |
                                                {{ product?.measurement_unit?.name }}
                                                |
                                                {{ product?.market_price_updated_at }}
                                            </div>
                                        </div>
                                        <div class="success-text">
                                            <template v-if="searchMyEnabled">
                                                <p v-if="user.id === product?.member_id"
                                                    style="
                                                        margin: 0;
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                        gap: 0.1rem;
                                                        cursor: pointer;
                                                    "
                                                    @click="onClickUpdatePrice(product)"
                                                >
                                                    Update price
                                                    <i class="fa-solid fa-angles-right"></i>
                                                </p>

                                                <DeleteButton v-if="user.id === product?.member_id" delete-type="product" :delete-url="`/four/products/${product.id}`"/>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <NoProduct v-else/>
                    </div>
                    <div v-else-if="loadingMyProducts" style="font-weight: 600;margin: 2rem auto;width: 100%;text-align: center;">
                        Loading Products...
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="updatePrice" tabindex="-1" role="dialog" aria-labelledby="updatePriceId" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title text-success">Update Your Product Price</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-12" style="text-align: center">
                            <p class="mb-3">
                                What Price is the product selling for today.
                            </p>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="newProductPrice"
                                        v-model="newProductPrice"
                                        aria-describedby="newProductPrice-help"
                                        placeholder="New Price"
                                        :class="[errors.price ? 'is-invalid': '',!errors.price && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                    />
                                    <div class="invalid-feedback" v-if="errors.price">
                                        {{ errors.price.toString() }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mt-3">
                                <div class="form-group">
                                    <button class="btn btn-success" @click.prevent="updateProductPrice" style="width: 100%">
                                        Update Price
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import "select2/dist/css/select2.css";

.hide {
    display: none;
}
.show {
    display: block;
}
</style>
