<script setup>

import {onBeforeUnmount, onMounted, ref} from "vue";

const props = defineProps(['user']);

onMounted(function () {
  getItems();
});

const charges = ref('');
const charge = ref('');
const loading = ref(true);
const amount = ref('');
const quantity = ref('');
const errors = ref({});
const showExtraInformation = ref(false);
const currencySign = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN

function getItems() {
  console.log("Cads");
  axios.post(`/api/getFees/group_id`, {
    user_id: props.user.id
  }).then(res => {
    charges.value = res.data;
  });
}

function selectCharge(productData) {
  charge.value = productData;
  showExtraInformation.value = true;
}

</script>

<template>
  <div>
    <div class="jumbotron jumbotron-fluid">
      <div class="container-fluid">
        <template v-if="showExtraInformation">
          <div class="card">
            <div class="card-body">
              <div class="row">
              <div class="col-md-12">
                <table class="table table-sm table-bordered table-card">
                  <tbody>
                  <tr>
                    <th>Charge:</th>
                    <td>{{ charge.name }}</td>
                  </tr>
                  <tr>
                    <th>Charge Type:</th>
                    <td>{{ charge.type }}</td>
                  </tr>
                  <tr>
                    <th>Percentage</th>
                    <td>{{ charge.percentage }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div></div>
          </div>
        </template>
        <template v-else>
          <table class="table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Percentage</th>
              <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(charge,index) in charges">
              <td scope="row">{{ charge.name }}</td>
              <td>
                {{ charge.percentage }}
              </td>
              <td>
                {{ charge.type }}
              </td>
              <td>
                <button class="btn btn-primary" @click.prevent="selectCharge(charge)">
                  Select Fee
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>