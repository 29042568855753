<script setup>
import {computed, onMounted, ref} from "vue";
import moment from "moment";

import DateButton from "./components/DateButton.vue";
import SummaryCard from "./components/SummaryCard.vue";
import {DateRangeType} from "./utils";


const props = defineProps({
    stats: Object,
});


const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const dataRange = ref();
const selectedButton = ref(props.stats?.dateType || DateRangeType.Day);
const isSelectedDay = computed(() => selectedButton.value === DateRangeType.Day);
const isSelectedWeek = computed(() => selectedButton.value === DateRangeType.Week);
const isSelectedMonth = computed(() => selectedButton.value === DateRangeType.Month);
const isSelectedRange = computed(() => selectedButton.value === DateRangeType.Range);

const today = moment().format('MMMM DD, YYYY');
const week = `${moment().startOf('week').format('DD/MM/YYYY')} - ${moment().endOf('week').format('DD/MM/YYYY')}`;
const month = moment().format('MMMM YYYY')


const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}
const format = (date) => {
    const [startDate, endDate] = date;

    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
}

function onClick(type) {
    selectedButton.value = type
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('type', type);
    if (type === DateRangeType.Range) {
        urlParams.set('range-value', format(dataRange.value));
    } else {
        urlParams.delete('range-value');
    }
    window.location.href = window.location.origin + window.location.pathname + '?' + urlParams.toString();
}

const selectFromCalender = () => {
    onClick(DateRangeType.Range);
}

const getSelectedDates = () => {
    const dateRange = props.stats?.rangeValue || '';
    if (!dateRange) return [null, null]
    return  dateRange.split(' - ');
}

onMounted(() => {
    const [selectedStartDate, selectedEndDate] = getSelectedDates();
    if (selectedStartDate && selectedEndDate) {
        dataRange.value = [
            moment(selectedStartDate, 'DD/MM/YYYY').toDate(),
            moment(selectedEndDate, 'DD/MM/YYYY').toDate()]
        ;
        return
    }
    const startDate = new Date();
    const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
    dataRange.value = [startDate, endDate];
})
</script>

<template>
    <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
        <SummaryCard
            :value="props.stats?.availableFunds.toLocaleString()"
            :title="'Total Funds'"
            :tooltipText="'Total funds available for trade financing'"
        />
        <SummaryCard
            :value="props.stats?.totalInDefault.toLocaleString()"
            :title="'Total in default'"
            :tooltipText="'Total funds available for trade financing'"
        />
    </div>
    <div class="flex items-center mb-xl-3" style="height: 38px">
        <div style="display: inline-block" class="bg-white p-2 rounded-2">
            <form id="my-form" method="GET">
                <input v-if="isSelectedRange" type="hidden" name="range-value" :value="dataRange">
            </form>
            <DateButton
                @click="onClick(DateRangeType.Day)"
                :is-selected="isSelectedDay"
                :name="'Day'"
            />
            <DateButton
                @click="onClick(DateRangeType.Week)"
                :is-selected="isSelectedWeek"
                :name="'Week'"
            />
            <DateButton
                @click="onClick(DateRangeType.Month)"
                :is-selected="isSelectedMonth"
                :name="'Month'"
            />
            <DateButton
                @click="onClick(DateRangeType.Range)"
                :is-selected="isSelectedRange"
                :name="'Date range'"
            />
        </div>
        <div
            style="display: inline-block; min-width: 20%; height: 100%; margin-left: 20px"
        >
            <div v-if="!isSelectedRange">
                <div v-if="isSelectedDay">Today: <strong>{{ today }}</strong></div>
                <div v-if="isSelectedWeek"><strong>{{ week }}</strong></div>
                <div v-if="isSelectedMonth"><strong>{{ month }}</strong></div>
            </div>
            <div v-if="isSelectedRange" class="bg-white rounded-2">
                <VueDatePicker
                    :clearable="false"
                    :enable-time-picker="false"
                    auto-apply
                    multi-calendars
                    range
                    v-model="dataRange"
                    :format="format"
                    @closed="selectFromCalender"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>
.dp__theme_light {
    --dp-background-color: #fdfeff;
    --dp-text-color: #312e2e;
    --dp-hover-color: #f3f3f3;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #1976d2;
    --dp-primary-disabled-color: #6bacea;
    --dp-primary-text-color: #f8f5f5;
    --dp-secondary-color: #c0c4cc;
    --dp-border-color: none;
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: #aaaeb7;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #76d275;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #ff6f60;
    --dp-marker-color: #ff6f60;
    --dp-tooltip-color: #fafafa;
    --dp-disabled-color-text: #8e8e8e;
    --dp-highlight-color: rgb(25 118 210 / 10%);
    --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
    --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
    --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
}
</style>
