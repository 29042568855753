<script setup>
import { onMounted, ref } from "vue";
import Swal from "sweetalert2";
import _ from "lodash";

const storageLocation = import.meta.env.VITE_APP_URL;

const props = defineProps([
    "market",
    "auth_id",
    "market_leader",
    "call_center_verified",
]);

function saveMarketCompliance() {
    saving.value = true;
    axios
        .post("/four/admin/finance/market-compliance", {
            market_id: props.market.id,
            market_manually_verified: market_exists.value,
            auth_id: props.auth_id,
            completed_call_center_verification:
                completed_call_center_verification.value,
            confirm_market_leader_bvn: confirm_market_leader_bvn.value,
            confirm_market_leader_nin: confirm_market_leader_nin.value,
            Confirm_business_address: Confirm_business_address.value,
            market_location_verified_by_coamana_staff:
                market_location_verified_by_coamana_staff.value,
            bank_statement_matches_market_account:
                bank_statement_matches_market_account.value,
        })
        .then((response) => {
            saving.value = false;
            Swal.fire({
                title: "Success!",
                text: "Successfully saved market compliance Information",
                icon: "success",
                confirmButtonText: "Ok",
            }).then(() => {
                window.location.reload();
            });
        })
        .catch((error) => {
            saving.value = true;
            Swal.fire({
                title: "Error!",
                text: error.message,
                icon: "error",
                confirmButtonText: "Ok",
            });
        });
}

const verifications = ref("");
const MarketVerifications = ref("");
const market_exists = ref("");

onMounted(function () {
    let documents = JSON.parse(props.market.verifications);

    verifications.value = _.pickBy(documents, function (o, l) {
        if (l == "Photo of Occupancy") {
            return o;
        }
        if (l == "Financial Statement") {
            return o;
        }
        if (l == "Photo of Market Office") {
            return o;
        }
    });

    completed_call_center_verification.value =
        props.call_center_verified == true ? "Correct" : "Incorrect";

    MarketVerifications.value =
        props.market?.market_verifications?.verifications;
    confirm_market_leader_bvn.value =
        MarketVerifications.value?.confirm_market_leader_bvn ?? "";
    confirm_market_leader_nin.value =
        MarketVerifications.value?.confirm_market_leader_nin ?? "";
    Confirm_business_address.value =
        MarketVerifications.value?.Confirm_business_address ?? "";
    market_location_verified_by_coamana_staff.value =
        MarketVerifications.value?.market_location_verified_by_coamana_staff ??
        "";
    bank_statement_matches_market_account.value =
        MarketVerifications.value?.bank_statement_matches_market_account ?? "";
});

const errors = ref({});
const saving = ref(false);
const completed_call_center_verification = ref("");
const confirm_market_leader_bvn = ref("");
const confirm_market_leader_nin = ref("");
const Confirm_business_address = ref("");
const market_location_verified_by_coamana_staff = ref("");
const bank_statement_matches_market_account = ref("");
</script>

<template>
    <div>
        <div class="row">
            <div class="card border-success mb-3">
                <div class="card-header bg-transparent border-secondary">
                    <h3>Uploaded Documents</h3>
                </div>
                <div class="card-body">
                    <div
                        :key="verification?.id"
                        v-for="(verification, key) in verifications"
                    >
                        <div class="border border-3 p-2 m-3">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <i class="fa fa-file"></i>
                                    {{ key }}
                                </div>
                                <div>
                                    <a
                                        :href="`${storageLocation}/storage/${verification}`"
                                        download="true"
                                    >
                                        View Document
                                        <i class="fa fa-eye"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-transparent border-secondary"></div>
            </div>
        </div>

        <div class="row">
            <div class="card mb-3">
                <div class="card-header">Compliance did not pass</div>
                <div class="card-body">
                    <table>
                        <tbody>
                            <tr
                                :key="failures?.id"
                                v-for="(failures, key) in market
                                    .market_verifications?.metadata?.failures"
                            >
                                <th>{{ key }}</th>
                                <td style="width: 2rem"></td>
                                <td>
                                    <i
                                        class="fa fa-shield-xmark text-danger"
                                    ></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="card border-success mb-3">
                <div class="card-header bg-transparent border-secondary">
                    <h3>Market Compliance Questions</h3>
                </div>
                <div class="card-body">
                    <form action="">
                        <div class="row g-3">
                            <div class="col-md-12">
                                <!--Label: I have confirmed that the market exists, Attributes:market_exists -->
                                <div class="form-floating">
                                    <select type="text" class="form-select" id="market_exists" v-model="market_exists"
                                           aria-describedby="market_exists-help"
                                           :class="[errors.market_exists ? 'is-invalid': '',!errors.market_exists && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                           >
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    <label for="market_exists">I have confirmed that the market exists</label>

                                    <div class="invalid-feedback" v-if="errors.market_exists">
                                        {{ errors.market_exists.toString() }}
                                    </div>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div class="row g-3">
                            <div class="col-md-6">
                                <!--Label: Has market leader completed call centre verification, Attributes:completed_call_center_verification -->
                                <div class="form-floating">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="completed_call_center_verification"
                                        disabled
                                        v-model="
                                            completed_call_center_verification
                                        "
                                        aria-describedby="completed_call_center_verification-help"
                                        :class="[
                                            errors.completed_call_center_verification
                                                ? 'is-invalid'
                                                : '',
                                            !errors.completed_call_center_verification &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                    />
                                    <label
                                        for="completed_call_center_verification"
                                        >Has market leader completed call centre
                                        verification</label
                                    >
                                    <div
                                        class="invalid-feedback"
                                        v-if="
                                            errors.completed_call_center_verification
                                        "
                                    >
                                        {{
                                            errors.completed_call_center_verification.toString()
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <!--Label: Confirm Market leader BVN, Attributes:confirm_market_leader_bvn -->
                                <div class="form-floating">
                                    <select
                                        type="text"
                                        class="form-select"
                                        id="confirm_market_leader_bvn"
                                        v-model="confirm_market_leader_bvn"
                                        aria-describedby="confirm_market_leader_bvn-help"
                                        :class="[
                                            errors.confirm_market_leader_bvn
                                                ? 'is-invalid'
                                                : '',
                                            !errors.confirm_market_leader_bvn &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                    >
                                        <option value="" disabled>
                                            Select Options
                                        </option>
                                        <option value="Incorrect">
                                            Incorrect
                                        </option>
                                        <option value="Correct">Correct</option>
                                    </select>
                                    <label for="confirm_market_leader_bvn"
                                        >Confirm Market leader BVN</label
                                    >
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.confirm_market_leader_bvn"
                                    >
                                        {{
                                            errors.confirm_market_leader_bvn.toString()
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <!--Label: Confirm Market Leader NIN, Attributes:confirm_market_leader_nin -->
                                <div class="form-floating">
                                    <select
                                        type="text"
                                        class="form-select"
                                        id="confirm_market_leader_nin"
                                        v-model="confirm_market_leader_nin"
                                        aria-describedby="confirm_market_leader_nin-help"
                                        :class="[
                                            errors.confirm_market_leader_nin
                                                ? 'is-invalid'
                                                : '',
                                            !errors.confirm_market_leader_nin &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                    >
                                        <option value="" disabled>
                                            Select Options
                                        </option>
                                        <option value="Incorrect">
                                            Incorrect
                                        </option>
                                        <option value="Correct">Correct</option>
                                    </select>
                                    <label for="confirm_market_leader_nin"
                                        >Confirm Market Leader NIN</label
                                    >
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.confirm_market_leader_nin"
                                    >
                                        {{
                                            errors.confirm_market_leader_nin.toString()
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <!--Label: Confirm Business Address, Attributes:Confirm_business_address -->
                                <div class="form-floating">
                                    <select
                                        type="text"
                                        class="form-select"
                                        id="Confirm_business_address"
                                        v-model="Confirm_business_address"
                                        aria-describedby="Confirm_business_address-help"
                                        :class="[
                                            errors.Confirm_business_address
                                                ? 'is-invalid'
                                                : '',
                                            !errors.Confirm_business_address &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                    >
                                        <option value="" disabled>
                                            Select Options
                                        </option>
                                        <option value="Incorrect">
                                            Incorrect
                                        </option>
                                        <option value="Correct">Correct</option>
                                    </select>
                                    <label for="Confirm_business_address"
                                        >Confirm Business Address</label
                                    >
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.Confirm_business_address"
                                    >
                                        {{
                                            errors.Confirm_business_address.toString()
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <!--Label: Market location verified by CoAmana Staff, Attributes:market_location_verified_by_coamana_staff -->
                                <div class="form-floating">
                                    <select
                                        type="text"
                                        class="form-select"
                                        id="market_location_verified_by_coamana_staff"
                                        v-model="
                                            market_location_verified_by_coamana_staff
                                        "
                                        aria-describedby="market_location_verified_by_coamana_staff-help"
                                        :class="[
                                            errors.market_location_verified_by_coamana_staff
                                                ? 'is-invalid'
                                                : '',
                                            !errors.market_location_verified_by_coamana_staff &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                    >
                                        <option value="" disabled>
                                            Select Options
                                        </option>
                                        <option value="Correct">
                                            Confirmed
                                        </option>
                                        <option value="Incorrect">
                                            Not Confirmed
                                        </option>
                                    </select>
                                    <label
                                        for="market_location_verified_by_coamana_staff"
                                        >Market location verified by CoAmana
                                        Staff</label
                                    >
                                    <div
                                        class="invalid-feedback"
                                        v-if="
                                            errors.market_location_verified_by_coamana_staff
                                        "
                                    >
                                        {{
                                            errors.market_location_verified_by_coamana_staff.toString()
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <!--Label: Bank statement matches market account and confirmed, Attributes:bank_statement_matches_market_account -->
                                <div class="form-floating">
                                    <select
                                        type="text"
                                        class="form-control"
                                        id="bank_statement_matches_market_account"
                                        v-model="
                                            bank_statement_matches_market_account
                                        "
                                        aria-describedby="bank_statement_matches_market_account-help"
                                        :class="[
                                            errors.bank_statement_matches_market_account
                                                ? 'is-invalid'
                                                : '',
                                            !errors.bank_statement_matches_market_account &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                    >
                                        <option value="" disabled>
                                            Select Options
                                        </option>
                                        <option value="Correct">Yes</option>
                                        <option value="Incorrect">No</option>
                                    </select>
                                    <label
                                        for="bank_statement_matches_market_account"
                                        >Bank statement matches market account
                                        and confirmed</label
                                    >
                                    <div
                                        class="invalid-feedback"
                                        v-if="
                                            errors.bank_statement_matches_market_account
                                        "
                                    >
                                        {{
                                            errors.bank_statement_matches_market_account.toString()
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer bg-transparent border-secondary">
                    <div class="d-flex justify-content-end">
                        <button
                            type="button"
                            @click="saveMarketCompliance()"
                            :disabled="saving"
                            class="btn btn-outline-secondary"
                        >
                            Save
                            <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                            <i class="fa fa-save fa-thin" v-else></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>

