<template>
  <div>
    <div class="row">
      <div class="col-12">
        <!--Label: Search Farmer, Attributes:searchfarmer -->
        <div class="form-group">
          <div class="input-group flex-nowrap">
            <!--            <input type="text" class="form-control" id="searchfarmer" v-model="searchfarmer"-->
            <!--                   aria-describedby="searchfarmer-help"-->
            <!--                   @focusout="searchForFarmer"-->
            <!--                   @keyup.enter="searchForFarmer"-->
            <!--                   autocomplete="off"-->
            <!--                   :class="[errors.searchfarmer ? 'is-invalid': '',!errors.searchfarmer && Object.keys(errors).length > 1 ? 'is-valid': '']"-->
            <!--                   placeholder="Search Farmer">-->
            <!--            <button class="btn btn-primary" type="button" id="button-addon1" @click.prevent="searchfarmer">-->
            <!--              <i class="fa fa-search"></i>-->
            <!--            </button>-->

          </div>
          <div class="invalid-feedback" v-if="errors.searchfarmer">
            {{ errors.searchfarmer.toString() }}
          </div>
        </div>
      </div>
    </div>

    <table class="table table-condensed table-shaded">
      <thead>
      <tr>
        <th>Group Member</th>
        <th class="text-center">Qty to
          supply<br><small>({{ measurementunit }})</small>
        </th>
        <th class="text-center" v-show="1===2">
          Your Fee<br/><small>(in {{ currencysign }})</small>
        </th>
        <th class="text-center">
          Additional Fees
          <br/><small>(in {{ currencysign }})</small>
        </th>
        <th class="text-center">
          Farmer to Receive
          <br/><small>(in {{ currencysign }})</small>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(farmer,index) in farmerSuppliers" :key="farmer.farmer_id">
        <td>
          <span
              :class="[errors[`farmerSuppliers.${index}.quantity_to_supply`] ? 'text-danger': '',!errors[`farmerSuppliers.${index}.quantity_to_supply`] && Object.keys(errors).length > 1 ? '': '']">
                      {{ farmer.farmer_name }}
          </span>
        </td>
        <td>
          <input type="text" class="form-control text-center" id="quantity_to_supply"
                 v-model.number="farmerSuppliers[index].quantity_to_supply"
                 @keyup="validateQuantity(index)"
                 aria-describedby="quantity_to_supply-help"
                 autocomplete="false"
                 :class="[errors[`farmerSuppliers.${index}.quantity_to_supply`] ? 'is-invalid': '',!errors[`farmerSuppliers.${index}.quantity_to_supply`] && Object.keys(errors).length > 1 ? 'is-validf': '']"
                 placeholder="Quantity To Supply" required>

                    <div class="invalid-feedback" v-if="errors[`farmerSuppliers.${index}.quantity_to_supply`]">
                      {{ errors[`farmerSuppliers.${index}.quantity_to_supply`].toString() }}
                    </div>
        </td>
        <td v-show="1===2">
          <input type="text" class="form-control text-center" id="fee_to_farmer"
                 v-model.number="farmerSuppliers[index].fee_to_farmer"
                 @keyup="validateQuantity(index)"
                 aria-describedby="fee_to_farmer-help"
                 :class="[errors.fee_to_farmer ? 'is-invalid': '',!errors.fee_to_farmer && Object.keys(errors).length >
                  1 ?
                 'is-valid': '']"
                 placeholder="Quantity To Supply" required>

          <div class="invalid-feedback" v-if="errors[`farmerSuppliers.0.fee_to_farmer`]">
            {{ errors[`farmerSuppliers.${index}.fee_to_farmer`].toString() }}

          </div>
        </td>
        <td>
          <input type="text" class="form-control" id="fee_to_buyer"
                 v-model.number="farmerSuppliers[index].fee_to_buyer"
                 @keyup="validateQuantity(index)"
                 aria-describedby="fee_to_buyer-help"
                 :class="[errors.fee_to_buyer ? 'is-invalid': '',!errors.fee_to_buyer && Object.keys(errors).length >
                  1 ?
                 'is-valid': '']"
                 placeholder="Quantity To Supply" required>

          <div class="invalid-feedback" v-if="errors[`farmerSuppliers.0.fee_to_buyer`]">
            {{ errors[`farmerSuppliers.${index}.fee_to_buyer`].toString() }}

          </div>
        </td>
        <td>
          {{ currencysign }} {{ farmerSuppliers[index].quantity_to_supply * unitprice | formatCurrency }}
        </td>
      </tr>
      </tbody>
    </table>
    <div v-if="canassignquantites">
      <button class="btn btn-primary"
              ref="savebutton"
              :disabled="saving"
              @click.prevent="assignfarmers">
        <template v-if="!saving">
          Save
        </template>
        <template v-else>
          <i class="fa fa-spin fa-spinner"></i> saving...
        </template>
      </button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import fuzzysort from 'fuzzysort';
import numbro from 'numbro';
import toastr from 'toastr'
import 'toastr/build/toastr.css'
export default {
  name: "AssignOrder",
  props: {
    farmers: {
      type: Object | Array,
      default: []
    },
    quantity: {
      type: Number,
      default: 0
    },
    measurementunit: {
      type: String,
      default: ''
    },
    currencysign: {
      type: String,
      default: ''
    },
    farmerlimit: {
      type: Number,
      default: 0
    },
    unitprice: {
      type: [Number, String],
      default: 0
    },
    orderid: {
      type: Number,
      required: true
    },
    orderitemid: {
      type: Number,
      required: true
    },
    usergroupid: {
      type: Number,
      required: true
    },
    orderassignmentid: {
      type: Number,
      required: true
    },


  },
  data() {
    return {
      errors: [],
      searchfarmer: '',
      canassignquantites: false,
      saving: false,
      searchResults: [],
      farmerSuppliers: [
        {
          farmer_name: '',
          farmer_id: '',
          quantity_to_supply: '',
          fee_to_farmer: '',
          fee_to_buyer: ''
        }
      ]
    }
  },
  mounted() {
    this.formatFarmers();
  },
  filters: {
    formatCurrency: function (value) {
      return numbro(value).format({thousandSeparated: true, mantissa: 2});
    }
  },
  methods: {
    assignfarmers() {

      this.saving = true;
      axios.post('/agx/orders/agent_assign', {
        'order_id': this.orderid,
        'order_item_id': this.orderitemid,
        'user_group+id': this.usergroupid,
        'order_assignment_id': this.orderassignmentid,
        'farmerSuppliers': this.farmerSuppliers,
        'assign_working_capital': true
      }).then(resp => {
        window.location.reload();
      }).catch(error => {
        if (error.response) {
          toastr.error(
              error.response.data.message
          );

        } else if (error.request) {
          toastr.error(
              error.request
          );
          console.log(error.request);
        } else {
          toastr.error(
              error.message
          );
        }
      }).finally(() => {
        this.saving = false;
      });
    },
    formatFarmers() {

      let orderid = this.orderid;
      let orderitem = this.orderitemid;
      let usergroupid = this.usergroupid;
      let orderassignmentid = this.orderassignmentid;

      this.farmerSuppliers = _.map(this.farmers, function (farmer) {
        return {
          farmer_name: farmer.name,
          farmer_id: farmer.id,
          quantity_to_supply: 0,
          fee_to_farmer: 0,
          fee_to_buyer: 0,
          order_id: orderid,
          order_item_id: orderitem,
          user_group_id: usergroupid,
          order_assignment_id: orderassignmentid,

        };
      });

    },
    searchForFarmer() {

      this.searchResults = _.map(fuzzysort.go(this.searchfarmer, this.farmerSuppliers, {key: 'farmer_name'}), function
          (farmer) {
        return farmer.obj;
      });

      return;

      if (this.searchfarmer === '') {
        this.formatFarmers();
      }

      this.farmerSuppliers = _.map(fuzzysort.go(this.searchfarmer, this.farmers, {key: 'name'}), function (farmer) {
        return {
          farmer_name: farmer.obj.name,
          farmer_id: farmer.obj.id,
          quantity_to_supply: 0,
          fee_to_farmer: 0,
          fee_to_buyer: 0
        };
      });

    },
    validateQuantity(index) {

      let quantityToSupply = this.farmerSuppliers[index].quantity_to_supply;
      let totalPriceOfSupply = parseInt(this.unitprice) * parseInt(quantityToSupply);
      let quantityNeeded = this.quantity;
      let quantityAssigned = _.sumBy(this.farmerSuppliers, 'quantity_to_supply')

      let Self = this;

      if (totalPriceOfSupply > parseInt(this.farmerlimit)) {

        this.$set(this.errors, `farmerSuppliers.${index}.quantity_to_supply`, "You have exceed the allocated amount set for this user");
        Swal.fire({
          'text': "You have exceed the allocated amount set for this user",
          'position': 'top-end',
          showConfirmButton: false,
          showCloseButton: false,
          timer: 3000
        })
        return;

      } else {
        this.errors.splice(index, 1)
        this.$delete(Self.errors, index);
      }

      if (quantityToSupply > quantityNeeded) {
        this.$set(this.errors, `farmerSuppliers.${index}.quantity_to_supply`, "You have exceed the quantity needed");
      }

      if (quantityAssigned > quantityNeeded) {

        this.$set(this.errors, `farmerSuppliers.${index}.quantity_to_supply`,
            "You have reached the maximum number of items you can assign");
        if (this.$refs.savebutton) {
          this.$refs.savebutton.disabled = true;
        }
        Swal.fire({
          'text': "You have surpassed the maximum number of items you can assign for this order.",
          'position': 'top-end',
          showConfirmButton: false,
          showCloseButton: false,
          icon: 'error',
          timer: 3000
        });

        return;
      } else {
        if (this.$refs.savebutton) {
          this.$refs.savebutton.disabled = false;
        }

        this.errors = [];
      }

      this.canassignquantites = quantityAssigned === quantityNeeded;

    }
  },
}
</script>

<style scoped>

</style>
