<script setup>
import {ref} from "vue";

let props = defineProps([
    'currencysign',
    'market_id',
    'email',
    'uniqueid',
]);
const subscriptionFee = import.meta.env.VITE_MARKET_MONTHLY_SUBSCRIPTION_FEE;
const emits = defineEmits(['done']);
const showSuccessMessage = ref('');

Echo.channel(`paymentmade`)
    .listen('PaymentMadeEvent', (e) => {
        closeOpenedWindow();
        // showSuccessMessage.value = true;
        emits('done',{message:true,data:e});
    });

let openedWindow;

const choosingPlan = ref(false)
const awaitingPayment = ref(false)
const url = import.meta.env.VITE_APP_URL

const locale = import.meta.env.VITE_LOCALIZATION_COUNTRY_ABBR;

const errors = ref([]);
const amount = ref(subscriptionFee);
const phone_number = ref('');
const choosePlanForKenya = (plan) =>{

    amount.value = subscriptionFee;
    choosingPlan.value = true;
    axios.post('/apis/v4/topup/wallet/mpesa', {
        "phone": phone_number.value,
        "amount": amount.value,
        "reason": "Subscription",
        "market_id": "Subscription"
    }).then((resp)=>{
        choosingPlan.value = false;
        awaitingPayment.value = true;
        function pollFunction() {
          // axios.post(`/apis/v4/query/transaction/${resp.data[0].CheckoutRequestID}`).then(resp=>{
          //     console.log(resp);
          //     if (resp.data.status === 'paid') {
          //         location.reload();
          //     }
          //     if (resp.data.status === 'failed') {
          //         message.value = 'The payment could not be confirmed. Refresh and make subscription again.';
          //
          //     }
          // })
        }
        // setInterval(pollFunction, 5000);
        // location.href = '/four';
    })
}
const message = ref(false);
const choosePlan = (plan) => {

    if (locale === 'ke') {
        var myModalEl = document.querySelector("#makePayment");
        var modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
        modal.show();
        return;
    }

    choosingPlan.value = true;
    axios.post('/apis/v4/saas/ps-subscriptions', {
        'callback_url': `${url}four/paymentmade`,
        'email': props.email,
        'market_id': props.market_id,
        'plan': 'paid',
        'plan_code': 'PLN_tk28ld5cwshqhj6',
    }).then((resp)=>{
        choosingPlan.value = false;
        openedWindow = window.open(resp.data.data.data.authorization_url, "_blank","top=0,left=0,width=500,height=500");
    }).finally(()=>{
        choosingPlan.value = false;
    });
}
function closeOpenedWindow() {
    openedWindow.close();
}
</script>

<template>
    <div v-if="showSuccessMessage">
        <div class="alert alert-success" role="alert">
            <strong>You have successfully upgraded your subscription.</strong><a href="#" class="alert-link"></a>
        </div>
    </div>
    <div v-else
         class="isolate tw-mx-auto tw-mt-10 tw-grid tw-max-w-md tw-grid-cols-1 tw-gap-8 lg:tw-mx-0 lg:tw-max-w-none lg:tw-grid-cols-3">
        <div class="tw-rounded-3xl tw-p-8 tw-ring-1 xl:tw-p-10 tw-ring-gray-200">
            <h3 id="tier-freelancer" class="tw-text-lg tw-font-semibold tw-leading-8 tw-text-gray-900">Starter</h3>
            <p class="tw-mt-4 tw-text-sm tw-leading-6 tw-text-gray-600">The essentials to provide your best work for
                clients.</p>
            <p class="tw-mt-6 tw-flex tw-items-baseline tw-gap-x-1">
                <!-- Price, update based on frequency toggle state -->
                <span class="tw-text-4xl tw-font-bold tw-tracking-tight tw-text-gray-900">{{ currencysign }}0</span>
                <!-- Payment frequency, update based on frequency toggle state -->
                <span class="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-600">/market</span>
            </p>
            <a @click.prevent.once="choosePlan('free')" aria-describedby="tier-freelancer"
               class="tw-cursor-default tw-mt-6 tw-block tw-rounded-md tw-py-2 tw-px-3 tw-text-center tw-text-sm tw-font-semibold tw-leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 tw-bg-indigo-600 tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:outline-indigo-600">
                Choose Plan
                <i class="fa fa-spin fa-spinner" v-if="choosingPlan"></i>
            </a>
            <ul role="list" class="tw-mt-8 tw-space-y-3 tw-text-sm tw-leading-6 xl:tw-mt-10 tw-text-gray-600">
                <li class="tw-flex tw-gap-x-3">
                    <svg class="tw-h-6 tw-w-5 tw-flex-none tw-text-indigo-600" viewBox="0 0 20 20" fill="currentColor"
                         aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clip-rule="evenodd"/>
                    </svg>
                    One Market
                </li>
                <li class="tw-flex tw-gap-x-3">
                    <svg class="tw-h-6 tw-w-5 tw-flex-none tw-text-indigo-600" viewBox="0 0 20 20" fill="currentColor"
                         aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clip-rule="evenodd"/>
                    </svg>
                    Manage Market
                </li>
                <li class="tw-flex tw-gap-x-3">
                    <svg class="tw-h-6 tw-w-5 tw-flex-none tw-text-indigo-600" viewBox="0 0 20 20" fill="currentColor"
                         aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clip-rule="evenodd"/>
                    </svg>
                    Collect Fees
                </li>
                <li class="tw-flex tw-gap-x-3">
                    <svg class="tw-h-6 tw-w-5 tw-flex-none tw-text-indigo-600" viewBox="0 0 20 20" fill="currentColor"
                         aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clip-rule="evenodd"/>
                    </svg>
                    .amanamarket.ng domain
                </li>
            </ul>
        </div>
        <div class="tw-rounded-3xl tw-p-8 tw-ring-1 xl:tw-p-10 tw-ring-gray-200">
            <h3 id="tier-startup" class="tw-text-lg tw-font-semibold tw-leading-8 tw-text-gray-900">Value Builder</h3>
            <p class="tw-mt-4 tw-text-sm tw-leading-6 tw-text-gray-600">A plan that scales with your rapidly growing
                business.</p>
            <p class="tw-mt-6 tw-flex tw-items-baseline tw-gap-x-1">
                <!-- Price, update based on frequency toggle state -->
                <span
                    class="tw-text-4xl tw-font-bold tw-tracking-tight tw-text-gray-900">{{ currencysign }}{{ subscriptionFee }}</span>
                <!-- Payment frequency, update based on frequency toggle state -->
                <span class="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-600">/market</span>
            </p>
            <a @click.prevent.once="choosePlan('PLN_tk28ld5cwshqhj6')" aria-describedby="tier-startup"
               class="tw-cursor-default tw-mt-6 tw-block tw-rounded-md tw-py-2 tw-px-3 tw-text-center tw-text-sm tw-font-semibold tw-leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 tw-bg-indigo-600 tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:outline-indigo-600">
                Choose Plan
                <i class="fa fa-spin fa-spinner" v-if="choosingPlan"></i>
            </a>
            <ul role="list" class="tw-mt-8 tw-space-y-3 tw-text-sm tw-leading-6 xl:tw-mt-10 tw-text-gray-600">
                <li class="tw-flex tw-gap-x-3">
                    <svg class="tw-h-6 tw-w-5 tw-flex-none tw-text-indigo-600" viewBox="0 0 20 20" fill="currentColor"
                         aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clip-rule="evenodd"/>
                    </svg>
                    Manage Market
                </li>
                <li class="tw-flex tw-gap-x-3">
                    <svg class="tw-h-6 tw-w-5 tw-flex-none tw-text-indigo-600" viewBox="0 0 20 20" fill="currentColor"
                         aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clip-rule="evenodd"/>
                    </svg>
                    Manage Members
                </li>
                <li class="tw-flex tw-gap-x-3">
                    <svg class="tw-h-6 tw-w-5 tw-flex-none tw-text-indigo-600" viewBox="0 0 20 20" fill="currentColor"
                         aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clip-rule="evenodd"/>
                    </svg>
                    Collect Fees
                </li>
                <li class="tw-flex tw-gap-x-3">
                    <svg class="tw-h-6 tw-w-5 tw-flex-none tw-text-indigo-600" viewBox="0 0 20 20" fill="currentColor"
                         aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clip-rule="evenodd"/>
                    </svg>
                    Enable Trusted Vendors
                </li>
                <li class="tw-flex tw-gap-x-3">
                    <svg class="tw-h-6 tw-w-5 tw-flex-none tw-text-indigo-600" viewBox="0 0 20 20" fill="currentColor"
                         aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clip-rule="evenodd"/>
                    </svg>
                    Embedded Finance
                </li>
                <li class="tw-flex tw-gap-x-3">
                    <svg class="tw-h-6 tw-w-5 tw-flex-none tw-text-indigo-600" viewBox="0 0 20 20" fill="currentColor"
                         aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clip-rule="evenodd"/>
                    </svg>
                    Embedded Insurance
                </li>
            </ul>
        </div>
        <div class="tw-rounded-3xl tw-p-8 tw-ring-1 xl:tw-p-10 tw-bg-gray-900 tw-ring-gray-900">
            <h3 id="tier-enterprise" class="tw-text-lg tw-font-semibold tw-leading-8 tw-text-white">Value Builder
                Annual</h3>
            <p class="tw-mt-4 tw-text-sm tw-leading-6 tw-text-gray-300">Dedicated support and infrastructure for your
                company.</p>
            <p class="tw-mt-6 tw-flex tw-items-baseline tw-gap-x-1">
                <!-- Price, update based on frequency toggle state -->
                <span class="tw-text-4xl tw-font-bold tw-tracking-tight tw-text-white">{{ currencysign }}25,000</span>
                <!-- Payment frequency, update based on frequency toggle state -->
                <span class="tw-text-sm tw-font-semibold tw-leading-6 tw-text-white">/market</span>
            </p>
            <a @click.prevent.once="choosePlan('PLN_tk28ld5cwshqhj6')" aria-describedby="tier-enterprise"
               class="tw-cursor-default tw-mt-6 tw-block tw-rounded-md tw-py-2 tw-px-3 tw-text-center tw-text-sm tw-font-semibold tw-leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white/10 tw-text-white hover:bg-white/20 focus-visible:tw-outline-white">
                Choose Plan
                <i class="fa fa-spin fa-spinner" v-if="choosingPlan"></i>
            </a>
            <ul role="list" class="tw-mt-8 tw-space-y-3 tw-text-sm tw-leading-6 xl:tw-mt-10 tw-text-gray-300">
                <li class="tw-flex tw-gap-x-3">
                    <svg class="tw-h-6 tw-w-5 tw-flex-none tw-text-white" viewBox="0 0 20 20" fill="currentColor"
                         aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clip-rule="evenodd"/>
                    </svg>
                    Manage Market
                </li>
                <li class="tw-flex tw-gap-x-3">
                    <svg class="tw-h-6 tw-w-5 tw-flex-none tw-text-white" viewBox="0 0 20 20" fill="currentColor"
                         aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clip-rule="evenodd"/>
                    </svg>
                    Manage Members
                </li>
                <li class="tw-flex tw-gap-x-3">
                    <svg class="tw-h-6 tw-w-5 tw-flex-none tw-text-white" viewBox="0 0 20 20" fill="currentColor"
                         aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clip-rule="evenodd"/>
                    </svg>
                    Collect Fees
                </li>
                <li class="tw-flex tw-gap-x-3">
                    <svg class="tw-h-6 tw-w-5 tw-flex-none tw-text-white" viewBox="0 0 20 20" fill="currentColor"
                         aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clip-rule="evenodd"/>
                    </svg>
                    Enable Trusted Vendors
                </li>
                <li class="tw-flex tw-gap-x-3">
                    <svg class="tw-h-6 tw-w-5 tw-flex-none tw-text-white" viewBox="0 0 20 20" fill="currentColor"
                         aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clip-rule="evenodd"/>
                    </svg>
                    Embedded Finance
                </li>
                <li class="tw-flex tw-gap-x-3">
                    <svg class="tw-h-6 tw-w-5 tw-flex-none tw-text-white" viewBox="0 0 20 20" fill="currentColor"
                         aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clip-rule="evenodd"/>
                    </svg>
                    Embedded Insurance
                </li>
            </ul>
        </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="makePayment" tabindex="-1" role="dialog" aria-labelledby="makePaymentId"
         aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="makePaymentId">Make Payment</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <div class="modal-body">
                    <form action="">
                        <div class="row" v-if="!awaitingPayment">
                            <div class="col-12">
                                <!--Label: Amount, Attributes:amount -->
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="amount" v-model="amount"
                                           disabled
                                           aria-describedby="amount-help"
                                           :class="[errors.amount ? 'is-invalid': '',!errors.amount && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                           >
                                    <label for="amount">Amount</label>

                                    <div class="invalid-feedback" v-if="errors.amount">
                                        {{ errors.amount.toString() }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-4">
                                <!--Label: Phone Number, Attributes:phone_number -->
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="phone_number" v-model="phone_number"
                                           aria-describedby="phone_number-help"
                                           :class="[errors.phone_number ? 'is-invalid': '',!errors.phone_number && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                           >
                                    <label for="phone_number">Phone Number</label>

                                    <div class="invalid-feedback" v-if="errors.phone_number">
                                        {{ errors.phone_number.toString() }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="alert alert-info" role="alert">
                                <template v-if="message">
                                    {{message}}
                                </template>
                              <template v-else>
                                  Please input your PIN on the pop up on your phone. <br>
                                  Once, payment is confirmed, you will automatically be redirected.
                                  Don't close this page.
                              </template>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary"
                    @click="choosePlanForKenya"
                    >
                        Pay
                        <i class="fa fa-spin fa-spinner" v-if="choosingPlan"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
