<script setup>
import {useFetch} from "@vueuse/core/index";
import {ref} from "vue";

const props = defineProps(['marketid']);
const url = ref(`/apis/v4/admin/onboarding/${props.marketid}?include=Members`)
const {isFetching, data, error} = useFetch(url, {
    refetch: true
}).json();


</script>

<template>
    <div v-if="error">
        We encountered an error while fetching members
    </div>
    <div v-else-if="isFetching">
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Phone Number</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(member,index) in data?.members">
                    <td scope="row">{{ index + 1 }}</td>
                    <td>
                        {{ member.name }}
                        <span class="text-muted">
                            {{ member.created_at }}
                        </span>
                    </td>
                    <td>{{ member.phone }}</td>
                    <td>
                        <div class="btn-group">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="moreactions"
                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                Show
                            </button>
                            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="moreactions">
                                <a class="dropdown-item" href="resetpasswird">Reset Password </a>
                                <a class="dropdown-item disabled" href="editinfo">Edit Information </a>
                                <h6 class="dropdown-header">Section header</h6>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style scoped>

</style>
