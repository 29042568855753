<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import ResendOTP from "../../ResendOTP.vue";
import sendOTPForPayment from "../payments/sendOTPForPayment.vue";


const props = defineProps({
  auth_user: {
    type: Object,
    required: true,
  },
});

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const openModal = ref(false);
const phoneNumber = ref("");
const errors = ref({});
const currentStep = ref(1);
const selectedUser = ref(null);
const amount = ref('');
const sentOtpToUser = ref(false);
const otp = ref('');
const saving = ref(false);
const childRef = ref();


const handleOpenModal = () => {
    openModal.value = true;
    currentStep.value = 1;
};

const closeModal = () => {
    openModal.value = false;
    location.reload();
};

const goToStep = (step) => {
  currentStep.value = step;
};

onMounted(() => {
    window.addEventListener("openModal", handleOpenModal);
});

onUnmounted(() => {
    window.removeEventListener("openModal", handleOpenModal);
});

const getUser = async () => {
    axios.get('/apis/v4/users/get-user',{
        params: {
            phone: phoneNumber.value
        }
    }).then((response) => {
        selectedUser.value = response.data;
        if(selectedUser.value.id === props.auth_user.id){
            errors.value = { phone: "Can't transfer money to yourself." };
            return false;
        }
        goToStep(2);
    }).catch((error) => {
        errors.value = { phone: error?.response?.data?.message ?? ''  };
    });
};

const checkWalletBalance = () => {
  if (amount.value <= 0) {
    errors.value = { amount: "Amount can't be zero or less" };
    return false;
  }

  if (props.auth_user.wallet.current_balance < amount.value) {
    errors.value = { amount: 'Insufficient funds in your wallet'  };
    return false;
  }

  goToStep(3);
};

const setChildRef = (el) => {
    childRef.value = el;
};

function sendOTP() {
    sentOtpToUser.value = true;

    axios.post('/apis/v4/otp/send', {
        'phone': props.auth_user.phone,
        'user_id': props.auth_user.id,
        'message':'Your one-time password to complete payment on Amana Market is {otp} and is valid for the next 2 minutes.\n',
        'key': 'user_wallet_transfer',
    }).then(function () {
        sentOtpToUser.value = true;
        childRef.value.startTimer();
    }).catch((error) => {
        sentOtpToUser.value = false;
        errors.value = { otpError: "Error sending OTP please Resend Otp"  };
    });

    goToStep(4);
}

function validateOTP() {
    saving.value = true;

    axios.post('/apis/v4/otp/validate', {
        otp: otp.value,
        user_id: props.auth_user.id,
        key: 'user_wallet_transfer',
    }).then(() => {
        completeWalletTransfer();
    }).catch((error) => {
        saving.value = false;
        errors.value = error?.response?.data?.errors ?? '';
    });
}

function completeWalletTransfer() {
    saving.value = true;

    axios.post('/apis/v4/wallets/transfer', {
        'from_user_id': props.auth_user.id,
        'to_user_id': selectedUser.value.id,
        'amount': amount.value,
    }).then(() => {
        goToStep(5);
    }).catch((error) => {
        saving.value = false;
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: error?.response?.data?.error ?? 'Error transferring money',
            timer: 9000
        }).then(() => {
            location.reload();
        });
    });
}

</script>

<template>
    <div v-if="openModal" class="modal-overlay">
        <div class="card mb-3 modal-content">
            <svg
                class="modal-close-icon"
                @click="closeModal"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_8_6557)">
                    <path
                        d="M13.89 8.7L12 10.59L10.11 8.7C9.72 8.31 9.09 8.31 8.7 8.7C8.31 9.09 8.31 9.72 8.7 10.11L10.59 12L8.7 13.89C8.31 14.28 8.31 14.91 8.7 15.3C9.09 15.69 9.72 15.69 10.11 15.3L12 13.41L13.89 15.3C14.28 15.69 14.91 15.69 15.3 15.3C15.69 14.91 15.69 14.28 15.3 13.89L13.41 12L15.3 10.11C15.69 9.72 15.69 9.09 15.3 8.7C14.91 8.32 14.27 8.32 13.89 8.7ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                        fill="#1D242E" />
                </g>
                <defs>
                    <clipPath id="clip0_8_6557">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>

            <div class="card-body">
                <div class="col-12">
                    <div v-if="currentStep === 1">
                        <h5 class="text-success mb-3">
                            <strong>Input the number of the member</strong>
                        </h5>
                        <form @submit.prevent="getUser()">
                            <div class="form-group mb-3">
                                <input
                                    type="text"
                                    id="phoneNumber"
                                    v-model="phoneNumber"
                                    class="form-control mt-1"
                                    placeholder="Input Number"
                                    required
                                    :class="[
                                        errors.phone ? 'is-invalid' : '',
                                        !errors.phone && Object.keys(errors).length > 1 ? 'is-valid': ''
                                    ]"
                                />
                                <div class="invalid-feedback" v-if="errors.phone">{{ errors.phone.toString() }}</div>
                            </div>
                            <button type="submit" class="btn btn-success button-spacing" style="width: 100%">Pay</button>
                        </form>
                    </div>

                    <div v-if="currentStep === 2">
                        <h5 class="text-success mb-3">
                            <strong>How much are you topping up {{ selectedUser.first_name }}'s wallet</strong>
                        </h5>
                        <form @submit.prevent="checkWalletBalance()">
                            <div class="form-group mb-3">
                                <input
                                    type="number"
                                    id="amount"
                                    v-model="amount"
                                    class="form-control"
                                    placeholder="Input Amount"
                                    required
                                    :class="[
                                        errors.amount ? 'is-invalid' : '',
                                        !errors.amount && Object.keys(errors).length > 1 ? 'is-valid': ''
                                    ]"
                                />
                                <div class="invalid-feedback" v-if="errors.amount">{{ errors.amount.toString() }}</div>
                            </div>
                            <button type="button" @click="goToStep(1)"  class="btn btn-secondary button-spacing" style="width: 40%">Back</button>
                            <button type="submit" class="btn btn-success button-spacing" style="width: 40%">Next</button>
                        </form>
                    </div>

                    <div v-if="currentStep === 3">
                        <h5 class="text-success mb-3"><strong>Make Payment</strong></h5>
                        <div>
                            <p class="mb-3" style="font-weight: 600">
                                The sum of {{ currency }}{{ amount }}
                                will be deducted from your wallet to pay for the transaction
                            </p>
                            <button type="button" @click="sendOTP()" class="btn btn-success button-spacing">Yes, Make Payment</button>
                            <button type="button" @click="closeModal" class="btn btn-outline-warning button-spacing mt-3">No Cancel Payment</button>
                        </div>
                    </div>

                    <div v-if="currentStep === 4">
                        <h5 class="text-success"><strong>Confirm Payment with OTP</strong></h5>
                        <div class="mt-3">
                            <send-o-t-p-for-payment
                                :errors="errors"
                                @updatedotp="args => otp = args"
                            ></send-o-t-p-for-payment>
                        </div>
                        <div class="mt-2 mb-3">
                            <ResendOTP v-if="sentOtpToUser" :send-otp="sendOTP" :ref="setChildRef"/>
                        </div>
                        <button class="btn btn-success" style="width: 100%" @click.prevent="validateOTP()">
                            Pay
                            <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                        </button>
                    </div>

                    <div v-if="currentStep === 5">
                        <h5 class="text-success mb-3"><strong>Transfer Completed</strong></h5>
                        <div>
                            <p class="mb-5" style="font-weight: 600">
                                The sum of {{ currency }}{{ amount }}
                                has been transferred from your wallet to
                                {{ selectedUser.first_name }}'s wallet
                            </p>

                            <button type="button" @click="closeModal" class="btn btn-success" style="width: 100%">
                                Okay
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 50;
}
.modal-content {
  position: relative;
  border-radius: 0.25rem;
  border: 0.2px solid #fff;
  box-shadow: none;
  max-width: 500px;
  width: 95%;
  padding: 1rem;
  background-color: white;
}
.modal-close-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
}
.modal-body {
  padding: 1rem;
}

.modal-body {
    padding: 1rem;
}

.button-spacing {
  margin-top: 1rem;
  margin-right: 1rem;
}
</style>
