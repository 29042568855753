<script setup>

import {onMounted, ref} from "vue";
import {usePermissions} from "../../../../store/permissions";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const props = defineProps(['fees', 'charges', 'market', 'market_members'])
const currentTab = ref('fee');
const permissions = usePermissions();

function onClickViewFee(fee) {
    location.href = `/four/market/fees/${fee.id}`;
}

function onClickViewCharge(charge) {
    location.href = `/four/market/charges/${charge.id}`;
}

const feeIsCreated = () => {
    bootstrap.Modal.getOrCreateInstance(document.querySelector("#createFee")).hide();
    bootstrap.Modal.getOrCreateInstance(document.querySelector("#feeCreatedModal")).show();
};

function onClickDialogBtns(type) {
    if(type === 'continue'){
        location.href = "/four/market/profile";
    }else if(type === 'create'){
        $("#createFeeForm")[0].reset();
        bootstrap.Modal.getOrCreateInstance(document.querySelector("#feeCreatedModal")).hide();
        bootstrap.Modal.getOrCreateInstance(document.querySelector("#createFee")).show();
    }else{
        location.href = "/four/market/charges-rent-fees-and-taxes";
    }
}

onMounted(() => {
    let hash = window.location.hash;
    currentTab.value = hash ? hash.slice(1) : 'fee';
})

</script>

<template>
    <div>
        <div class="mt-4">
            <div class="nav nav-pills" id="myTab" role="tablist">
                <div class="nav-item" role="presentation">
                    <button
                        class="btn btn-outline-success"
                        :class="{ active: currentTab === 'fee'}"
                        id="fee-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#fee"
                        type="button"
                        role="tab"
                        aria-controls="fee"
                        aria-selected="true"
                    >
                        Fees Management
                    </button>
                </div>
                &nbsp;
                <div class="nav-item" role="presentation">
                    <button
                        class="btn btn-outline-success"
                        :class="{ active: currentTab === 'charge'}"
                        id="charge-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#charge"
                        type="button"
                        role="tab"
                        aria-controls="charge"
                        aria-selected="false"
                    >
                        Charges Management
                    </button>
                </div>
            </div>
        </div>
        <div class="tab-content mt-4" id="myTabContent">
            <div
                v-if="currentTab"
                class="tab-pane fade"
                :class="{ show: currentTab === 'fee', active: currentTab === 'fee'}"
                id="fee"
                role="tabpanel"
                aria-labelledby="fee-tab"
            >
                <div class="row">
                    <div class="col-12 mb-4">
                        <p style="font-weight: 500">
                            As a Market admin, you can add and track fees for members of your market.
                            Easily collect fees automatically, track payments by members,
                            send automatic reminders and cashout to any wallet of your choice.
                        </p>
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn add-button-coamana" data-bs-toggle="modal"
                                    v-if="permissions.$can('Create Fees')"
                                    data-bs-target="#createFee" style="color: white;">
                                <i class="fa fa-light fa-plus fa-2xs"></i>
                                Add New Fee
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card" v-for="fee in fees">
                    <div class="card-body">
                        <div class="row">
                            <div class="col mt-3">
                                <div class="col-12 mb-2">
                                    <div class="col-12 d-flex flex-row">
                                        <div class="d-flex flex-column col-10"><strong>{{ fee.name }}</strong></div>
                                        <div class="d-flex flex-column col-2"><strong> {{ currency }}{{ fee.amount.toLocaleString() }}</strong></div>
                                    </div>
                                    <hr class="hr"/>
                                    <div
                                        class="d-flex flex-row transaction-history-success-table py-2 px-4 my-2"
                                        style="border: none;"
                                    >
                                        <div class="d-flex flex-column col-10">
                                            <div>
                                                <strong>Created By: </strong>
                                                <span>{{ fee.creator?.name }}</span>
                                            </div>
                                            <div>
                                                <strong>Billed To: </strong>
                                                <span v-if="fee.member_selection === 'phone'">{{fee.phoneNumber}}</span>
                                                <span v-else>All Market Members</span>
                                            </div>
                                            <div>
                                                <strong>Total Balance: </strong>
                                                <span>{{ currency }}{{ fee.total_balance.toLocaleString() }}</span>
                                            </div>
                                            <div>
                                                <a class="text-success" href="#">Cash Wallet</a>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column col-2">
                                            <a
                                                href="#"
                                                class="text-success"
                                                @click="onClickViewFee(fee)"
                                            >
                                                View Fee
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                :class="{ show: currentTab === 'charge', active: currentTab === 'charge'}"
                id="charge"
                role="tabpanel"
                aria-labelledby="charge-tab"
            >
                <div class="row">
                    <div class="col-12 mb-4">
                        <p style="font-weight: 500">
                            As a Market admin, you can create charges on transactions and get paid for supporting
                            your members with their transactions by earning a percentage of the transactions.
                        </p>
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn add-button-coamana" data-bs-toggle="modal"
                                    v-if="permissions.$can('Create Charges')"
                                    data-bs-target="#createCharge" style="color: white;">
                                <i class="fa fa-light fa-plus fa-2xs"></i>
                                Add New Charge
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card" v-for="charge in charges">
                    <div class="card-body">
                        <div class="row">
                            <div class="col mt-3">
                                <div class="col-12 mb-2">
                                    <div class="col-12 d-flex flex-row">
                                        <div class="d-flex flex-column col-10"><strong>{{ charge.name }}</strong></div>
                                        <div class="d-flex flex-column col-2"><strong> {{ charge.percentage }}%</strong></div>
                                    </div>
                                    <hr class="hr"/>
                                    <div
                                        class="d-flex flex-row transaction-history-success-table py-2 px-4 my-2"
                                        style="border: none;"
                                    >
                                        <div class="d-flex flex-column col-10">
                                            <div>
                                                <strong>Created By: </strong>
                                                <span>{{ charge.creator?.name }}</span>
                                            </div>
                                            <div>
                                                <strong>Billed To: </strong>
                                                <span>All Members</span>
                                            </div>
                                            <div>
                                                <strong>Total Balance: </strong>
                                                <span>{{ currency }}{{ charge.total_balance.toLocaleString() }}</span>
                                            </div>
                                            <div>
                                                <a class="text-success" href="#">Cash Wallet</a>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column col-2">
                                            <a
                                                href="#"
                                                class="text-success"
                                                @click="onClickViewCharge(charge)"
                                            >
                                                View Charge
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="createCharge" tabindex="-1" role="dialog" aria-labelledby="createChargeId" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Add New Charge</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <create-charge :market="market"></create-charge>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="createFee" tabindex="-1" role="dialog" aria-labelledby="createFeeId" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Add New Fee</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <create-fees
                            :members="market_members"
                            :market="market"
                            @feeCreated="feeIsCreated"
                        ></create-fees>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="modal fade"
            id="feeCreatedModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="feeCreatedModal"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="col-12 mt-3" style="padding: 10px">
                            <h4 class="text-success">Fee Created</h4>
                            <p class="mt-3 mb-4">
                                You can create and track fees for members of your market.
                                Easily collect fees automatically, track payments by members,
                                send automatic reminders and cashout to any wallet of your choice.
                            </p>
                            <button class="btn btn-success" @click.prevent="onClickDialogBtns('create')">
                                Create Another Fee
                            </button>&nbsp;
                            <button type="button" class="btn btn-outline-warning" @click.prevent="onClickDialogBtns('continue')">
                                No, Continue Setup
                            </button>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" @click.prevent="onClickDialogBtns('close')">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
