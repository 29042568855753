<script setup>
import {ref} from "vue";
import Swal from "sweetalert2";


const props = defineProps(['markets', 'refferalcode'])

const errors = ref({});
const first_name = ref('');
const last_name = ref('');
const phone = ref('');
const bvn = ref('');
const nin = ref('');
const age = ref('');
const email = ref('');
const select_market = ref('');
const gender = ref('');
const saving = ref(false);
const createUserInMarket = () => {
    saving.value = true;
    axios.post('/apis/v4/saas/user-market-signup', {
        market_id: select_market.value,
        user: {
            country_id: 1,
            email: email.value,
            first_name: first_name.value,
            last_name: last_name.value,
            gender: gender.value,
            geo_state_id: 1,
            lga_id: 1,
            password: password.value,
            phone: phone.value,
            referral_code: props.refferalcode.code,
        }
    })
        .then(resp => {
            Swal.fire({
                'text': "User added to market successully",
                'position': 'top-end',
                showConfirmButton: false,
                showCloseButton: false,
                icon: 'success',
                timer: 5000
            })
            saving.value = false;
            errors.value = {};
            first_name.value = '';
            last_name.value = '';
            phone.value = '';
            bvn.value = '';
            nin.value = '';
            age.value = '';
            email.value = '';
            select_market.value = '';
            gender.value = '';
        }).catch(err => {
        saving.value = false;

        const myJSON = JSON.stringify(err);

        console.log();
        Swal.fire({
            'text': `${err.response.data.message}`,
            'position': 'top-end',
            showConfirmButton: false,
            showCloseButton: false,
            icon: 'error',
            timer: 5000
        })
    });
}
</script>

<template>
    <div>
        <form action="">
            <div class="row g-3">
                <div class="col-12">
                    <!--Label: Select Market, Attributes:select_market -->
                    <div class="form-floating">
                        <select type="text" class="form-select" id="select_market" v-model="select_market"
                                aria-describedby="select_market-help"
                                :class="[errors.select_market ? 'is-invalid': '',!errors.select_market && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                            <option :value="market.id" v-for="market in markets">
                                {{ market.name }}
                            </option>
                        </select>
                        <label for="select_market">Select Market</label>

                        <div class="invalid-feedback" v-if="errors.select_market">
                            {{ errors.select_market.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <!--Label: Email, Attributes:email -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="email" v-model="email"
                               aria-describedby="email-help"
                               :class="[errors.email ? 'is-invalid': '',!errors.email && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="email">Email</label>

                        <div class="invalid-feedback" v-if="errors.email">
                            {{ errors.email.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <!--Label: Gender, Attributes:gender -->
                    <div class="form-floating">
                        <label for="gender">Gender</label>
                        <select type="text" class="form-select" id="gender" v-model="gender"
                                aria-describedby="gender-help"
                                :class="[errors.gender ? 'is-invalid': '',!errors.gender && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                        <div class="invalid-feedback" v-if="errors.gender">
                            {{ errors.gender.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <!--Label: First Name, Attributes:first_name -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="first_name" v-model="first_name"
                               aria-describedby="first_name-help"
                               :class="[errors.first_name ? 'is-invalid': '',!errors.first_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="first_name">First Name</label>

                        <div class="invalid-feedback" v-if="errors.first_name">
                            {{ errors.first_name.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <!--Label: Last Name, Attributes:last_name -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="last_name" v-model="last_name"
                               aria-describedby="last_name-help"
                               :class="[errors.last_name ? 'is-invalid': '',!errors.last_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="last_name">Last Name</label>

                        <div class="invalid-feedback" v-if="errors.last_name">
                            {{ errors.last_name.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <!--Label: Phone, Attributes:phone -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="phone" v-model="phone"
                               aria-describedby="phone-help"
                               :class="[errors.phone ? 'is-invalid': '',!errors.phone && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="phone">Phone</label>

                        <div class="invalid-feedback" v-if="errors.phone">
                            {{ errors.phone.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <!--Label: BVN, Attributes:bvn -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="bvn" v-model="bvn"
                               aria-describedby="bvn-help"
                               :class="[errors.bvn ? 'is-invalid': '',!errors.bvn && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="bvn">BVN</label>

                        <div class="invalid-feedback" v-if="errors.bvn">
                            {{ errors.bvn.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <!--Label: NIN, Attributes:nin -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="nin" v-model="nin"
                               aria-describedby="nin-help"
                               :class="[errors.nin ? 'is-invalid': '',!errors.nin && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="nin">NIN</label>

                        <div class="invalid-feedback" v-if="errors.nin">
                            {{ errors.nin.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <!--Label: Age, Attributes:age -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="age" v-model="age"
                               aria-describedby="age-help"
                               :class="[errors.age ? 'is-invalid': '',!errors.age && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="age">Age</label>

                        <div class="invalid-feedback" v-if="errors.age">
                            {{ errors.age.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <!--Label: Password, Attributes:password -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="password" v-model="password"
                               aria-describedby="password-help"
                               :class="[errors.password ? 'is-invalid': '',!errors.password && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="password">Password</label>

                        <div class="invalid-feedback" v-if="errors.password">
                            {{ errors.password.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <button class="btn btn-primary" @click="createUserInMarket" :disabled="saving">
                        Create User
                        <i class="fa fa-plus" v-if="!saving"></i>
                        <i class="fa fa-spin fa-spinner" v-else></i>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<style scoped>

</style>
