<script setup>
import { ref } from "vue";
import moment from 'moment';

const phoneNumber = ref('');
const upcomingPayments = ref(null);
const searching = ref(false);
const errors = ref({});

const handleSubmit = () => {
  searching.value = true;
  // Reset if searching again
  errors.value = {};
  upcomingPayments.value = null;

  // Validate input
  if (!phoneNumber.value) {
    errors.value = {phoneNumber: "Please enter a valid phone number"};
    return;
  }

  // Make API call to fetch due or overdue fees
  fetchFees(phoneNumber.value);
};

const fetchFees = async (phoneNumber) => {
  try {
    const response = await axios.get("/apis/v4/upcoming-payments/fees", {
    params: {
        phone: phoneNumber
    }
    });
    upcomingPayments.value = response.data.upcomingPayments;

  } catch (error) {
    errors.value = {apiError: error.response.data.message};
  }
};

const formattedDate = (date) => {
    return moment(date).format("DD-MMMM-YYYY")
}
</script>

<template>
    <div class="card">
    <div class="card-body">
        <form @submit.prevent="handleSubmit">
            <h5 class="mb-1">Member Phone Number</h5>
            <p class="mb-4">Please enter the phone number of the user you want to pay fees for.</p>
            <div class="row g-3">
            <div class="border-3 border p-4 rounded">
                <div class="input-group mb-3">
                    <input
                    type="text"
                    class="form-control"
                    :class="[
                        errors.phoneNumber || errors.apiError ? 'is-invalid' : '',
                        !errors.phoneNumber && Object.keys(errors).length > 1
                            ? 'is-valid'
                            : '',
                    ]"
                    v-model="phoneNumber"
                    placeholder="Phone Number"
                    aria-label="Phone Number"
                    />
                    <button
                        class="btn btn-primary"
                        type="submit"
                        id="button-addon2"
                        style="color: white; background-color: #00ab4d;border-color: #00ab4d;"
                    >
                        Proceed
                    </button>
                </div>
                <div v-if="errors.phoneNumber || errors.apiError" class="col-12 alert alert-danger mt-2" role="alert">
                    <div v-if="errors.phoneNumber"> <strong>{{ errors.phoneNumber }}</strong></div>
                    <div v-if="errors.apiError"><strong>{{ errors.apiError }}</strong></div>
                </div>
            </div>
            </div>
        </form>
    </div>
    </div>
    <div v-if="upcomingPayments">
        <div
            v-for="payment in upcomingPayments"
            :key="payment?.id"
            style="
                border-radius: 0.25rem;
                border: 1px solid rgba(29, 36, 46, 0.3);
                background: #fff;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                position: relative;
                margin-bottom: 1rem;">
            <div style="
                    width: 4px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: rgba(29, 36, 46, 0.3);"/>
            <div style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    border-bottom: 1px solid rgba(29, 36, 46, 0.3);
                    padding: 0.8rem 1.3rem;
                    flex-wrap: wrap;
                    gap: 1rem;">
                <div style="
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 0.3rem;">
                    <div class="dot-success" style="width: 0.75rem; height: 0.75rem"/>
                    <div style="
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            font-weight: 600;
                            flex-wrap: wrap;
                            gap: 0.2rem;">
                        <span v-if="payment.type === 'Purchase'" style="font-weight: 400">
                            <span style="font-weight: 600">{{ payment.type }}</span>
                            from
                        </span>
                        <span v-else style="font-weight: 400">
                            <span style="font-weight: 600">{{ payment.type }}</span>
                            to
                        </span>
                        {{ payment?.payable?.seller?.name ?? payment?.market?.name }}
                    </div>
                </div>
                <p style="margin: 0; font-weight: 600">{{ currency }}{{ payment?.amount?.toLocaleString() ?? "" }}</p>
            </div>
            <div style="
                    padding: 0.8rem 1.3rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    flex-wrap: wrap;
                    gap: 1rem;">
                <div style="display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;">
                    <div style="display: flex; justify-content: flex-start; align-items: center; font-weight: 600; gap: 0.3rem;">
                        <template v-if="payment?.market?.name === 'Amana'">
                            Amana Market (CoAmana)
                        </template>
                        <template v-else>
                            Market:
                            <span style="font-weight: 400">{{ payment?.market?.name }}</span>
                        </template>
                    </div>
                    <div style="display: flex; justify-content: flex-start; align-items: center; font-weight: 600; gap: 0.3rem;">
                        Date: <span style="font-weight: 400">{{ formattedDate(payment?.upcoming_at) }}</span>
                    </div>

                    <div style="display: flex; justify-content: flex-start; align-items: center; font-weight: 600; gap: 0.3rem;">
                        Status:
                        <span
                            v-if="payment?.status === 'Paid'"
                            class="text-success"
                            style="font-weight: 400">{{ payment?.status }}</span>
                        <span
                            v-else-if="payment?.status === 'Overdue' || payment?.status === 'Rejected'"
                            class="text-danger"
                            style="font-weight: 400">{{ payment?.status }}</span>
                        <span
                            v-else-if="payment?.status === 'Pending'"
                            class="text-warning"
                            style="font-weight: 400">{{ payment?.status }}</span>
                        <span
                            v-else-if="payment?.status === 'Accepted'"
                            class="text-success"
                            style="font-weight: 400">{{ payment?.status }}</span>
                        <span
                            v-else
                            class="text-info"
                            style="font-weight: 400">{{ payment?.status }}</span>
                    </div>
                </div>

                <a :href="'/four/pay-fees/' + payment?.id"
                    type="button"
                    style="color: #4b9d30; text-align: right; font-size: 0.75rem; text-decoration: none;"
                >
                    <div style="color: #4b9d30; text-align: right; font-size: 0.75rem;">
                        Pay <i class="fa fa-angle-double-right"/>
                    </div>
                </a>
            </div>
        </div>
    </div>
    <div v-if="searching === true && Array.isArray(upcomingPayments) && upcomingPayments.length === 0" class="tw-border-2 tw-border-dashed tw-border-gray-300 tw-p-12 tw-text-center hover:tw-border-gray-400">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            width="64"
            height="64"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
        >
            <g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <circle cx="11" cy="11" r="8"/>
                <path d="M21 21l-4.35-4.35M9.1 9.1l3.9 3.9M9.1 13l3.9-3.9"/>
            </g>
        </svg>
        <h3 class="tw-mt-2 tw-text-sm tw-font-semibold tw-text-gray-900">
           No Fees Found For This User.
        </h3>
    </div>
</template>







