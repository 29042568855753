<script setup>

import {ref} from "vue";

const props = defineProps({
  'geostate': Object | Array,
  'country': String,
  'marketleader': String,
  'marketadmin': String,
  'user_id': String|Number,
})

let errors = ref({});
let market_name = ref('');
let lga_id = ref('');
let address = ref('');
let geo_state = ref('');
let geoStates = JSON.parse(props.geostate)
let lgas = ref({});
let market = ref({});
let nextPage = ref(1);
let saving = ref(false);

let first_name = ref('');
let last_name = ref('');
let phone = ref('');
let email = ref('');
let gender = ref('');
let age = ref('');



function getLGA() {
  axios.get(`/ajx/get_localities_in_state/${geo_state.value}?ajax=true`, {
    headers: {
      'content-type': 'application/json'
    }
  }).then(resp => {
    lgas.value = resp.data;
  });
}

function createMarket() {
  saving.value = true;
  axios.post(`/apis/v4/markets`, {
    'market_name': market_name.value,
    'address': address.value,
    'country_id': 1,
    'geo_state': geo_state.value,
    'lga_id': lga_id.value,
    'user_id': props.user_id
  }).then(resp => {
    saving.value = false;
    // window.location.href = `/admin/markets/${resp.data.id}`
    market.value = resp.data;
    nextPage.value = 2;
  }).catch((err) => {
    saving.value = false;
    errors.value = err.response.data.errors;
  });
}

function skip() {
  location.reload();
}
function addMarketLeader() {
  saving.value = true;
  axios.post(`/call-center-registration`, {
    'first_name': first_name.value,
    'last_name': last_name.value,
    'phone': phone.value,
    'email': email.value,
    'gender': gender.value,
    'age': age.value,
    'country_id': market.value.country_id,
    'geo_state': market.value.geo_state_id,
    'lga_id': market.value.lga_id,
    'user_type': props.marketleader,
    'market_name': market.value.id,
    'user_id': props.user_id
  }).then(resp => {
    // saving.value = false;
    errors.value = {};
    nextPage.value = 3;
    first_name.value = '';
    last_name.value = '';
    phone.value = '';
    email.value = '';
    gender.value = '';
    age.value = '';
    saving.value = false;
  }).catch((err) => {
    saving.value = false;
    errors.value = err.response.data.errors;
  });

}

function addMarketAdmin() {
  saving.value = true;
  axios.post(`/call-center-registration`, {
    'first_name': first_name.value,
    'last_name': last_name.value,
    'phone': phone.value,
    'email': email.value,
    'gender': gender.value,
    'age': age.value,
    'country_id': market.value.country_id,
    'geo_state': market.value.geo_state_id,
    'lga_id': market.value.lga_id,
    'user_type': props.marketadmin,
    'market_name': market.value.id,
  }).then(resp => {
    saving.value = false;
    nextPage.value = true;
    window.location.href = `/four/admin/markets`

  }).catch((err) => {
    saving.value = false;
    errors.value = err.response.data.errors;
  });

}
</script>

<template>
  <div>
    <div v-if="nextPage === 1">
      <form>
        <div class="row g-3">
          <div class="col-md-12">
            <!--Label: Market Name, Attributes:name -->
            <div class="form-floating">
              <input type="text" class="form-control" id="market_name" v-model="market_name"
                     aria-describedby="name-help"
                     :class="[errors.market_name ? 'is-invalid': '',!errors.market_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                     >
              <label for="market_name">Market Name</label>

              <div class="invalid-feedback" v-if="errors.market_name">
                {{ errors.market_name.toString() }}
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <!--Label: Market Address, Attributes:address -->
            <div class="form-floating">
              <input type="text" class="form-control" id="address" v-model="address"
                     aria-describedby="address-help"
                     :class="[errors.address ? 'is-invalid': '',!errors.address && Object.keys(errors).length > 1 ? 'is-valid': '']"
                     >
              <label for="address">Market Address</label>

              <div class="invalid-feedback" v-if="errors.address">
                {{ errors.address.toString() }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!--Label: County, Attributes:county -->
            <div class="form-floating">

              <select type="text" class="form-select" id="geo_state" v-model="geo_state"
                      aria-describedby="geo_state-help"
                      @change="getLGA"
                      :class="[errors.geo_state ? 'is-invalid': '',!errors.geo_state && Object.keys(errors).length > 1 ? 'is-valid': '']"
              >
                <option v-for="state in geoStates" :value="state.id" :key="state.id">
                  {{ state.name }}
                </option>
              </select>
              <label for="geo_state">
                <template v-if="country === 'ng'"> State</template>
                <template v-else>County</template>
              </label>
              <div class="invalid-feedback" v-if="errors.geo_state">
                {{ errors.geo_state.toString() }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!--Label: LGA, Attributes:lga -->
            <div class="form-floating">

              <select type="text" class="form-select" id="lga_id" v-model="lga_id"
                      aria-describedby="lga_id-help"
                      :class="[errors.lga_id ? 'is-invalid': '',!errors.lga && Object.keys(errors).length > 1 ? 'is-valid': '']"
              >
                <option :value="lga.id" v-for="lga in lgas" :key="lga.id">
                  {{ lga.name }}
                </option>
              </select>
              <label for="lga_id">
                <template v-if="country === 'ng'"> LGA</template>
                <template v-else>Sub County</template>
              </label>
              <div class="invalid-feedback" v-if="errors.lga_id">
                {{ errors.lga_id.toString() }}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary"
                      :disabled="saving"
                      @click.prevent="createMarket">
                Create Market
                <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                <i class="fa fa-light fa-floppy-disk-circle-arrow-right" v-else></i>
              </button>
            </div>
          </div>
        </div>

      </form>
    </div>
    <div v-if="nextPage === 2">
      <div class="jumbotron jumbotron-fluid">
        <div class="container">
          <h5 class="mb-4">Add Market Leader</h5>
          <form action="">
            <div class="row g-3">
              <div class="col-md-6">
                <div class="form-floating">
                  <input type="text" class="form-control" id="first_name" v-model="first_name"
                         aria-describedby="first_name-help"
                         :class="[errors.first_name ? 'is-invalid': '',!errors.first_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                         >
                  <label for="first_name">First Name</label>
                  <div class="invalid-feedback" v-if="errors.first_name">
                    {{ errors.first_name.toString() }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!--Label: Last Name, Attributes:last_name -->
                <div class="form-floating">
                  <input type="text" class="form-control" id="last_name" v-model="last_name"
                         aria-describedby="last_name-help"
                         :class="[errors.last_name ? 'is-invalid': '',!errors.last_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                         >
                  <label for="last_name">Last Name</label>

                  <div class="invalid-feedback" v-if="errors.last_name">
                    {{ errors.last_name.toString() }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!--Label: Phone, Attributes:phone -->
                <div class="form-floating">
                  <input type="text" class="form-control" id="phone" v-model="phone"
                         aria-describedby="phone-help"
                         :class="[errors.phone ? 'is-invalid': '',!errors.phone && Object.keys(errors).length > 1 ? 'is-valid': '']"
                         >
                  <label for="phone">Phone</label>

                  <div class="invalid-feedback" v-if="errors.phone">
                    {{ errors.phone.toString() }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!--Label: Email, Attributes:email -->
                <div class="form-floating">
                  <input type="text" class="form-control" id="email" v-model="email"
                         aria-describedby="email-help"
                         :class="[errors.email ? 'is-invalid': '',!errors.email && Object.keys(errors).length > 1 ? 'is-valid': '']"
                         >
                  <label for="email">Email (optional)</label>

                  <div class="invalid-feedback" v-if="errors.email">
                    {{ errors.email.toString() }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!--Label: Gender, Attributes:gender -->
                <div class="form-floating">
                  <select type="text" class="form-select" id="gender" v-model="gender"
                          aria-describedby="gender-help"
                          :class="[errors.gender ? 'is-invalid': '',!errors.gender && Object.keys(errors).length > 1 ? 'is-valid': '']"
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  <label for="gender">Gender</label>

                  <div class="invalid-feedback" v-if="errors.gender">
                    {{ errors.gender.toString() }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!--Label: Age, Attributes:age -->
                <div class="form-floating">
                  <input type="number" class="form-control" id="age" v-model="age"
                         aria-describedby="age-help"
                         :class="[errors.age ? 'is-invalid': '',!errors.age && Object.keys(errors).length > 1 ? 'is-valid': '']"
                         >
                  <label for="age">Age</label>

                  <div class="invalid-feedback" v-if="errors.age">
                    {{ errors.age.toString() }}
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="d-flex justify-content-end">
                  <button class="btn btn-primary"
                          :disabled="saving"
                          @click.prevent="addMarketLeader">
                    Add Market Leader
                    <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                    <i class="fa-light fa-floppy-disk-circle-arrow-right" v-else></i>
                  </button>
                </div>
              </div>
            </div>
            <!--Label: First Name, Attributes:first_name -->

          </form>
        </div>
      </div>

    </div>
    <div v-if="nextPage === 3">
      <div class="jumbotron jumbotron-fluid">
        <div class="container">
          <h5 class="mb-4">Add Market Admin</h5>

          <form action="">
            <div class="row">
              <div class="alert alert-warning" role="alert">
                <strong>Please note that the market lead admin/lead agent does sensitive actions on the platform on
                  behalf of the market leader.</strong>
                <p>By creating an admin, you certify that this person is trusted by the market leader and can carry
                  out all selected actions in an honest manner. This includes approving members and withdrawals from
                  market accounts/wallets</p>
              </div>
            </div>
            <div class="row g-3">
              <div class="col-md-6">
                <div class="form-floating">
                  <input type="text" class="form-control" id="first_name" v-model="first_name"
                         aria-describedby="first_name-help"
                         :class="[errors.first_name ? 'is-invalid': '',!errors.first_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                         >
                  <label for="first_name">First Name</label>

                  <div class="invalid-feedback" v-if="errors.first_name">
                    {{ errors.first_name.toString() }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!--Label: Last Name, Attributes:last_name -->
                <div class="form-floating">
                  <input type="text" class="form-control" id="last_name" v-model="last_name"
                         aria-describedby="last_name-help"
                         :class="[errors.last_name ? 'is-invalid': '',!errors.last_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                         >
                  <label for="last_name">Last Name</label>

                  <div class="invalid-feedback" v-if="errors.last_name">
                    {{ errors.last_name.toString() }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!--Label: Phone, Attributes:phone -->
                <div class="form-floating">
                  <input type="text" class="form-control" id="phone" v-model="phone"
                         aria-describedby="phone-help"
                         :class="[errors.phone ? 'is-invalid': '',!errors.phone && Object.keys(errors).length > 1 ? 'is-valid': '']"
                         >
                  <label for="phone">Phone</label>

                  <div class="invalid-feedback" v-if="errors.phone">
                    {{ errors.phone.toString() }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!--Label: Email, Attributes:email -->
                <div class="form-floating">
                  <input type="text" class="form-control" id="email" v-model="email"
                         aria-describedby="email-help"
                         :class="[errors.email ? 'is-invalid': '',!errors.email && Object.keys(errors).length > 1 ? 'is-valid': '']"
                         >
                  <label for="email">Email (optional)</label>

                  <div class="invalid-feedback" v-if="errors.email">
                    {{ errors.email.toString() }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!--Label: Gender, Attributes:gender -->
                <div class="form-floating">
                  <select type="text" class="form-select" id="gender" v-model="gender"
                          aria-describedby="gender-help"
                          :class="[errors.gender ? 'is-invalid': '',!errors.gender && Object.keys(errors).length > 1 ? 'is-valid': '']"
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  <label for="gender">Gender</label>

                  <div class="invalid-feedback" v-if="errors.gender">
                    {{ errors.gender.toString() }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!--Label: Age, Attributes:age -->
                <div class="form-floating">
                  <input type="number" class="form-control" id="age" v-model="age"
                         aria-describedby="age-help"
                         :class="[errors.age ? 'is-invalid': '',!errors.age && Object.keys(errors).length > 1 ? 'is-valid': '']"
                         >
                  <label for="age">Age</label>

                  <div class="invalid-feedback" v-if="errors.age">
                    {{ errors.age.toString() }}
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="d-flex justify-content-between">
                  <div>
                    <button class="btn btn-outline-dark" @click="skip">
                      Skip
                    </button>
                  </div>
                  <div class="d-flex justify-content-end">
                    <button class="btn btn-primary"
                            :disabled="saving"
                            @click.prevent="addMarketAdmin">
                      Add Market Admin
                      <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                      <i class="fa-light fa-floppy-disk-circle-arrow-right" v-else></i>
                    </button>
                  </div>
                </div>

              </div>
            </div>
            <!--Label: First Name, Attributes:first_name -->

          </form>
        </div>
      </div>

    </div>

  </div>
</template>

<style scoped>

</style>
