<script setup>
import Swal from "sweetalert2";
import {onMounted, ref} from "vue";

const props = defineProps(['notifications', 'user']);

// Echo.channel(`user-${props.user.id}`)
//     .listen('OrderCreatedEvent', (e) => {
//         console.log(e);
//     });

Echo.channel('RecentUsers')
    .listen('.App\\Events\\Events\\ActiveUsersEvent', (e) => {
        Swal.fire({
            position: "center-right",
            toast: true,
            icon: "success",
            title: `A user,<strong>${e.user.name}</strong>, has just logged in. Go to their profile and start trade now.`,
            showConfirmButton: true,
            // timer: 3500
        });

    });

Echo.private('App.Models.User.' + props.user.id)
    .notification((notification) => {
        console.log(notification.type);
        getNotifications();
    });
onMounted(() => {
    // getNotifications();
});
const notifications = ref('');
const notificationsCount = ref(0);
const getNotifications = () => {
    // axios.get('/apis/v4/notifications').then(resp => {
    //     notifications.value = resp.data.notifications;
    //     notificationsCount.value = resp.data.notification_count;
    // });
};
const markAllRead = () => {
    axios.get('/apis/v4/notifications/readall').then(resp => {
        getNotifications();
    });
};

</script>

<template>
    <li class="nav-item dropdown dropdown-large">
        <a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#"
           role="button" data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count">
            {{ notificationsCount }}
        </span>
            <i class='bx bx-bell'></i>
        </a>
        <div class="dropdown-menu dropdown-menu-end">
            <a href="javascript:;">
                <div class="msg-header" @click="markAllRead">
                    <p class="msg-header-title">Messages</p>
                    <p class="msg-header-clear ms-auto">Marks all as read</p>
                </div>
            </a>
            <div class="header-message-list">
                <a class="dropdown-item" href="javascript:;" v-for="notification in notifications">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <h6 class="msg-name">{{ notification.type }}
                                <span class="msg-time float-end">
                                   {{ notification.created_at }}
                                </span>
                            </h6>
                            <p class="msg-info" v-if="notification.type === 'withdrawal'">
                                Check your withdrawal
                            </p>
                            <p class="msg-info" v-else></p>
                        </div>
                    </div>
                </a>
            </div>
            <a href="javascript:;">
                <div class="text-center msg-footer">View All Messages</div>
            </a>
        </div>
    </li>
</template>

<style scoped>

</style>
