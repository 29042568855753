<script setup>
import {onMounted, ref} from "vue";

const props = defineProps([
  'user_id'
]);

const audits = ref({});

function getAudit() {
  axios.get(`/apis/v4/audits/${props.user_id}`).then(resp => {
    audits.value = resp.data.audits;
  });
}

onMounted(function () {
  getAudit();
});
</script>

<template>
  <div>
    <div class="table-responsive">
      <table class="table table-bordered table-inverse">
        <thead class="thead-inverse">
        <tr>
          <th>#</th>
          <th>Accessed On</th>
          <th>URL</th>
          <th>IP Address</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(audit,index) in audits">
          <td>{{ index + 1}}</td>
          <td>{{ audit.created_at}}</td>
          <td scope="row">{{ audit.url}}</td>
          <td>{{ audit.ip_address}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>

<style scoped>

</style>