<script setup>

import {onMounted, ref} from "vue";
import {Bootstrap5Pagination} from 'laravel-vue-pagination';
import moment from "moment/moment";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;

const memberCreditQueues = ref({});
const errors = ref({});

const marketName = ref('');
const memberName = ref('');
const requestType = ref('');
const status = ref('');
const allocationDate = ref('');
const currentAllocation = ref(0);
const newAllocation = ref(0);
const allocationDiff = ref(0);

const marketAllocation = ref(0);
const totalAllocated = ref(0);
const totalUnallocated = ref(0);

function populateMemberCreditQueue(memberCreditQueue) {
    if (memberCreditQueue) {
        marketName.value = memberCreditQueue.market.name;
        memberName.value = memberCreditQueue.market_member.first_name + ' ' + memberCreditQueue.market_member.last_name;
        requestType.value = memberCreditQueue.allocation_type;
        status.value = memberCreditQueue.status.charAt(0).toUpperCase() + memberCreditQueue.status.slice(1);
        allocationDate.value = formatDate(memberCreditQueue.updated_at);
        currentAllocation.value = memberCreditQueue.market_member?.agent_capital?.allocation;
        currentAllocation.value = currentAllocation.value ? parseFloat(currentAllocation.value) : 0;
        newAllocation.value = memberCreditQueue.amount + currentAllocation.value;
        allocationDiff.value = Math.abs(currentAllocation.value - newAllocation.value);
    }
}

const getMemberCreditQueues = async (page = 1) => {
    await axios.get(`/apis/v4/saas/member-credit-queues?status=pending&&page=${page}`).then(resp => {
        memberCreditQueues.value = resp.data.data;
    }).catch(err => {
        errors.value = err.response.data.errors;
    });
}

const getMemberCreditQueueReport = async () => {
    await axios.get(`/apis/v4/saas/reports/credit-queues/member`).then(resp => {
        let data = resp.data.data;
        marketAllocation.value = data.limit;
        totalAllocated.value = data.total_allocated;
        totalUnallocated.value = data.total_unallocated;
    }).catch(err => {
        errors.value = err.response.data.errors;
    });
}

function formatDate(value) {
    return moment(value).format('DD/MM/YYYY HH:mm:ss');
}

onMounted(function () {
    getMemberCreditQueues();
    getMemberCreditQueueReport();
});

</script>

<template>
    <div>
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3">
            <div class="col">
                <div class="card radius-10">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">{{ 'Market Allocation' }}</p>
                                <h6 class="my-1">
                                    {{ currency }}{{ marketAllocation.toLocaleString() }}
                                </h6>
                                <p>{{ 'Amount available for credit' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card radius-10">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">{{ 'Total Allocated' }}</p>
                                <h6 class="my-1">
                                    {{ currency }}{{ totalAllocated.toLocaleString() }}
                                </h6>
                                <p>{{ 'Total value of credit allocation' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card radius-10">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">{{ 'Total Unallocated' }}</p>
                                <h6 class="my-1">
                                    {{ currency }}{{ totalUnallocated.toLocaleString() }}
                                </h6>
                                <p>{{ 'Total amount not allocated' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table">
                            <thead
                                v-if="memberCreditQueues && memberCreditQueues.data && memberCreditQueues.data.length > 0">
                            <tr>
                                <th>#</th>
                                <th>Member Name</th>
                                <th>Market Name</th>
                                <th>Allocation Type</th>
                                <th>Amount</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody
                                v-if="memberCreditQueues && memberCreditQueues.data && memberCreditQueues.data.length > 0">
                            <tr v-for="(memberCreditQueue,index) in memberCreditQueues.data"
                                :key="memberCreditQueue.id">
                                <td scope="row">{{ index + 1 }}</td>
                                <td>{{
                                        memberCreditQueue.market_member.first_name + ' ' + memberCreditQueue.market_member.last_name
                                    }}
                                </td>
                                <td>{{ memberCreditQueue.market.name }}</td>
                                <td>{{ memberCreditQueue.allocation_type }}</td>
                                <td>{{ currency }}{{ memberCreditQueue.amount.toLocaleString() }}</td>
                                <td>
                                    <button class="btn btn-sm btn-default"
                                            @click="populateMemberCreditQueue(memberCreditQueue)"
                                            data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
                                            aria-controls="offcanvasExample"
                                    >
                                        See More <i class="fa fa-angle-double-right fa-thin"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td align="center" colspan="7">No record found.</td>
                            </tr>
                            </tbody>
                        </table>
                        <bootstrap5-pagination :data="memberCreditQueues"
                                               @pagination-change-page="getMemberCreditQueues" align="center"/>
                    </div>
                    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample"
                         aria-labelledby="offcanvasExampleLabel">
                        <div class="offcanvas-header">
                            <h5 class="offcanvas-title" id="offcanvasExampleLabel">Credit Queue</h5>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                                    aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <tbody>
                                    <tr>
                                        <td>Market Name:</td>
                                        <td><b>{{ marketName }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Member Name:</td>
                                        <td><b>{{ memberName }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Request Type:</td>
                                        <td><b>{{ requestType }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Current Allocation:</td>
                                        <td><b>{{ currency }}{{ currentAllocation.toLocaleString() }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>New Allocation:</td>
                                        <td><b>{{ currency }}{{ newAllocation.toLocaleString() }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Allocation Difference:</td>
                                        <td><b>{{ currency }}{{ allocationDiff.toLocaleString() }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Allocation Date:</td>
                                        <td><b>{{ allocationDate }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Allocation Status:</td>
                                        <td>
                                            <div class="d-flex align-items-center text-danger"
                                                 v-if="status === 'Pending'">
                                                <i class="bx bx-radio-circle-marked bx-burst bx-rotate-90 align-middle font-18 me-1"/>
                                                <span>{{ status }}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <button class="btn btn-outline-danger text-reset"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Back"
                                    style="border-color: #00ab4d"
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.page-link {
    color: #00ab4d;
}

.page-link:hover {
    color: #00ab4d;
}

.active > .page-link, .page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: #00ab4d;
    border-color: #00ab4d;
}
</style>
